import React from "react";
import PropTypes from "prop-types";

import Modal from "react-bootstrap/Modal";
import ClientDealerInformation from "./client-dealer-information/client-dealer-information";
import TradeInformation from "./trade-infromation/trade-information";
import NotesAndDocuments from "./notes-and-documents/notes-and-documents";
import FormFooter from "./form-footer/form-footer";
import "./table-form.css";

import TradeContext from "../../../../store/tradeState/tradeContext";
import { useContext } from "react";
import { createTrade } from "../../../../api/trades.api";

const TableForm = (props) => {
  const { isShown, close } = props;
  const ctx = useContext(TradeContext);

  const bookDeal = async (e) => {
    e.preventDefault();
    try {
      let response = await createTrade(createTradeObject());
      console.log("Post Repsonse", response);
    } catch (error) {
      console.log(error);
    }
  };

  const createTradeObject = () => {
    const obj = {
      dealer_id: ctx.trade.dealer_id,
      entity_id: ctx.trade.entity,
      entity_account_id: ctx.trade.account_number,
      entity_beneificary: ctx.trade.beneficiary,
      txn_type: "Outward",
      trade_amount: ctx.trade.trade_amount,
      trade_currency: ctx.trade.trade_currency,
      exchange_rate: ctx.trade.exchange_rate,
      fx_amount: ctx.trade.fx_amount,
      fx_currency: ctx.trade.fx_currency,
      bop_code: ctx.trade.bop_code,
      trade_rate: ctx.trade.trade_rate,
    };
    return obj;
  };

  return (
    <Modal
      className="trade-modal"
      show={isShown}
      onHide={() => {
        close();
      }}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create New Trade</Modal.Title>
      </Modal.Header>
      <form onSubmit={(e) => bookDeal(e)}>
        <Modal.Body className="inputBody">
          <ClientDealerInformation />
          <TradeInformation />
          <NotesAndDocuments />
        </Modal.Body>
        <Modal.Footer>
          <FormFooter />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

TableForm.propTypes = {
  isShown: PropTypes.bool,
  close: PropTypes.func,
};

export default TableForm;
