import React from "react";
import PropTypes from "prop-types";

function ClientInfoBody() {
  return (
    <div className="row">
      <div className="col-12 text-center">{this.props.children}</div>
    </div>
  );
}

ClientInfoBody.propTypes = {
  children: PropTypes.object,
};

export default ClientInfoBody;
