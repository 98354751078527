import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import PropTypes from "prop-types";
import { numberFormat } from "../../common/helper";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { HotKeys } from "react-hotkeys";
// import * as BSIcons from "react-icons/bs";
import * as FAIcons from "react-icons/fa";
import * as AIIcons from "react-icons/ai";
import * as BIIcons from "react-icons/bi";
import Search from "../search/search";
import { Link } from "react-router-dom";
import { clientSearch, createClient, emptyClient } from "../../api/clients.api";
import { getJVPartners } from "../../lookups";
import NoteModal from "../notes/notes";
import {
  createEntityBeneficiary,
  updateEntityBeneficiary,
} from "../../api/beneficiaries.api";
import TableHeaderSort from "../table-header-sort/table-header-sort";
import ClientForm from "./client-form/client-form";
import ClientView from "./client-view/client-view";
import EntityBeneficiaryView from "./entity-benficiary-view/entity-beneficiary-view";
import EntityBeneficiaryForm from "./entity-beneficiary-form/entity-benficiary-form";
import Pagination from "../pagination/pagination";
// import BeneficiaryForm from "./beneficiary-form/beneficiary-form";
// import CurrentTrades from "./current-trades/current-trades";
// import CurrentTradesReport from "./current-trade-report/current-trade-report";
// import CurrentMarketOrders from "./current-market-orders/current-market-orders";

import "./client-table.css";
import Loader from "../loader/loader";
import FormLoader from "../form-loader/form-loader";
import { RequestResponseModal } from "../form-cofirmation-modal/form-confirmation-modal";
import EntityBeneficiaryEditForm from "./entity-beneficiary-edit-form/entity-beneficiary-edit-form";
const searchOptions = [
  { name: "First Name", value: "firstname" },
  { name: "Last Name", value: "surname" },
];

const keyMap = {
  PREVIEW: "shift+r",
};

class ClientTable extends Component {
  handlers = {
    PREVIEW: () => this.clientSearch(),
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      showNote: false,

      showLoader: false,
      showConfirmation: false,
      requestMessage: "",
      success: false,

      allocatedClient: emptyClient,
      allocatedBeneficiary: {},

      addClient: false,
      viewClient: false,
      addBeneficiary: false,
      editBeneficiary: false,
      viewBeneficiary: false,
      currentTradeModal: false,
      currentTradeReportModal: false,
      currentMarkertOrdersModal: false,

      clientFormType: "",

      searchText: "",
      searchFilter: searchOptions[0].value,
      page: 1,
      page_count: 0,
      record_count: 0,
      rows_per_page: 10,
      sortArray: [],
    };

    this.handleChange = this.handleChange.bind(this);

    this.showAddClient = this.showAddClient.bind(this);
    this.closeAddClient = this.closeAddClient.bind(this);

    this.showViewClient = this.showViewClient.bind(this);
    this.closeViewClient = this.closeViewClient.bind(this);

    this.showAddBeneficiary = this.showAddBeneficiary.bind(this);
    this.closeAddBeneficiary = this.closeAddBeneficiary.bind(this);

    this.showViewBeneficiary = this.showViewBeneficiary.bind(this);
    this.closeViewBeneficiary = this.closeViewBeneficiary.bind(this);

    this.showEditBeneficiary = this.showEditBeneficiary.bind(this);
    this.closeEditBeneficiary = this.closeEditBeneficiary.bind(this);

    this.clientSort = this.clientSort.bind(this);
    this.clientSearch = this.clientSearch.bind(this);

    this.confirmClient = this.confirmClient.bind(this);
    this.confirmEntityBeneficiary = this.confirmEntityBeneficiary.bind(this);
    this.updateEntityBeneficiary = this.updateEntityBeneficiary.bind(this);

    this.resetPage = this.resetPage.bind(this);
    this.pageUp = this.pageUp.bind(this);
    this.pageDown = this.pageDown.bind(this);

    this.showNotes = this.showNotes.bind(this);
  }

  async componentDidMount() {
    this.clientSearch();
  }

  async clientSearch() {
    this.setState({ isLoading: true }, () => {
      clientSearch(
        this.state.searchFilter,
        this.state.searchText,
        this.state.page,
        this.state.rows_per_page,
        this.state.sortArray
      ).then((result) => {
        if (result) {
          this.setState({
            isLoading: false,
            data: result.data?.results,
            record_count: result.data?.count,
            page_count: result.data?.total_pages,
            upper_threshold: result.data?.position?.first,
            lower_threshold: result.data?.position?.last,
          });
        } else {
          this.setState({ isLoading: false });
        }
      });
    });
    let data = await clientSearch(
      this.state.searchFilter,
      this.state.searchText,
      this.state.page,
      this.state.rows_per_page,
      this.state.sortArray
    );
    if (data)
      this.setState({
        data: data.data?.results,
        record_count: data.data.count,
        page_count: data.data.total_pages,
      });
  }

  async clientSort(sortArray) {
    this.setState(
      {
        sortArray: sortArray,
      },
      () => {
        this.clientSearch();
      }
    );
  }

  showAddClient() {
    this.setState({
      allocatedClient: emptyClient,
      addClient: true,
      viewClient: false,
    });
  }

  closeAddClient() {
    this.setState({
      addClient: false,
    });
  }

  showViewClient(allocatedClient, type) {
    this.setState({
      allocatedClient: allocatedClient,
      viewClient: true,
      addClient: false,
      clientFormType: type,
    });
  }

  closeViewClient() {
    this.setState({
      viewClient: false,
      allocatedClient: emptyClient,
    });
  }

  showEditBeneficiary(ben) {
    this.setState({
      allocatedBeneficiary: ben,
      editBeneficiary: true,
    });
  }

  closeEditBeneficiary() {
    this.setState({
      allocatedBeneficiary: {},
      editBeneficiary: false,
    });
  }

  showAddBeneficiary() {
    this.setState({
      addBeneficiary: true,
    });
  }

  closeAddBeneficiary() {
    this.setState({
      addBeneficiary: false,
      allocatedClient: emptyClient,
    });
  }

  showViewBeneficiary() {
    this.setState({
      viewBeneficiary: true,
    });
  }

  closeViewBeneficiary() {
    this.setState({
      viewBeneficiary: false,
      allocatedClient: emptyClient,
    });
  }

  allocateClient(evt, client) {
    this.setState({
      allocatedClient: this.toggleFalse(evt, client),
    });
  }

  toggleFalse(evt, client) {
    let toggle = document.getElementById(evt.target.id);
    if (
      toggle?.checked == true ||
      evt.target.id == "beneficiary-view" ||
      evt.target.id == "edit-client" ||
      evt.target.id == "delete-client"
    ) {
      return client;
    } else {
      return emptyClient;
    }
  }

  confirmClient() {
    this.setState(
      {
        showLoader: true,
        viewClient: false,
      },
      async () => {
        await createClient(this.state.allocatedClient)
          .then(() => {
            this.setState({
              success: true,
              showLoader: false,
              showConfirmation: true,
              requestMessage: "Client Successfully Created",
            });
          })
          .catch((ex) => {
            this.setState({
              success: false,
              showLoader: false,
              showConfirmation: true,
              requestMessage: `${ex.message}. Please contact system admin.`,
            });
          })
          .finally(() => {
            this.clientSearch();
          });
      }
    );
  }

  showNotes(c) {
    this.setState({
      allocatedClient: c ? c : emptyClient,
      showNote: !this.state.showNote,
    });
  }

  confirmEntityBeneficiary(benficiary) {
    this.setState({ showLoader: true, showAddBeneficiary: false }, async () => {
      await createEntityBeneficiary(benficiary)
        .then(() => {
          this.setState({
            success: true,
            showLoader: false,
            addBeneficiary: false,
            showConfirmation: true,
            requestMessage: "Entity Beneficiary Successfully Created",
          });
        })
        .catch((ex) => {
          this.setState({
            success: false,
            showLoader: false,
            addBeneficiary: false,
            showConfirmation: true,
            requestMessage: `${ex.message}. Please contact system admin.`,
          });
        })
        .finally(() => {
          this.clientSearch();
        });
    });
  }

  updateEntityBeneficiary(beneficiary) {
    this.setState({ showLoader: true, showAddBeneficiary: false }, async () => {
      await updateEntityBeneficiary(beneficiary.id, beneficiary)
        .then(() => {
          this.setState({
            success: true,
            showLoader: false,
            editBeneficiary: false,
            viewBeneficiary: false,
            showConfirmation: true,
            requestMessage: "Entity Beneficiary Updated Created",
          });
        })
        .catch((ex) => {
          this.setState({
            success: false,
            showLoader: false,
            editBeneficiary: false,
            viewBeneficiary: false,
            showConfirmation: true,
            requestMessage: `${ex.message}. Please contact system admin.`,
          });
        })
        .finally(() => {
          this.clientSearch();
        });
    });
  }

  handleChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  }

  resetPage() {
    this.setState({
      page: 1,
    });
  }

  pageUp() {
    this.setState(
      (st) => ({
        page: st.page != st.page_count ? st.page + 1 : st.page,
      }),
      () => {
        this.clientSearch();
      }
    );
  }

  pageDown() {
    this.setState(
      (st) => ({
        page: st.page != 1 ? st.page - 1 : st.page,
      }),
      () => {
        this.clientSearch();
      }
    );
  }

  render() {
    return (
      <>
        <HotKeys className="w-100" keyMap={keyMap} handlers={this.handlers}>
          <div className="trade-table">
            <Search
              options={searchOptions}
              searchCallback={this.clientSearch}
              searchFilter={this.state.searchFilter}
              searchText={this.state.searchText}
              resetPage={this.resetPage}
              handleChange={this.handleChange}
            />
            <>
              <div className="button-container">
                <Button variant="primary" onClick={this.showAddClient}>
                  Add Client
                </Button>
                <Button
                  disabled={
                    this.state.allocatedClient === emptyClient ? true : false
                  }
                  variant="secondary"
                  onClick={this.showAddBeneficiary}
                >
                  Add Beneficiary
                </Button>
              </div>
            </>
            <Table hover className="">
              <thead>
                <tr className="header-row">
                  <th style={{ width: "20px" }}> </th>
                  <th style={{ width: "100px" }}>
                    <TableHeaderSort
                      header="Customer"
                      sortParam="firstname"
                      sortData={this.clientSort}
                      sortArray={this.state.sortArray}
                    />
                  </th>
                  <th style={{ width: "20px" }}>
                    <TableHeaderSort
                      header="JV"
                      sortParam="joint_venture"
                      sortData={this.clientSort}
                      sortArray={this.state.sortArray}
                    />
                  </th>
                  <th style={{ width: "100px" }}>
                    <TableHeaderSort
                      header="Balance"
                      sortParam="balance"
                      sortData={this.clientSort}
                      sortArray={this.state.sortArray}
                    />
                  </th>
                  <th style={{ width: "150px" }}>
                    <TableHeaderSort
                      header="Allowance SDA Usage"
                      sortParam="allowance_sda"
                      sortData={this.clientSort}
                      sortArray={this.state.sortArray}
                    />
                  </th>
                  <th style={{ width: "150px" }}>
                    <TableHeaderSort
                      header="Allowance FIA Usage"
                      sortParam="allowance_fia"
                      sortData={this.clientSort}
                      sortArray={this.state.sortArray}
                    />
                  </th>
                  <th style={{ width: "80px" }}>
                    <TableHeaderSort
                      header="Status"
                      sortParam="status__name"
                      sortData={this.clientSort}
                      sortArray={this.state.sortArray}
                    />
                  </th>
                  <th style={{ width: "50px" }}>
                    <TableHeaderSort
                      header="Notes +"
                      sortParam="notes_count"
                      sortData={this.clientSort}
                      sortArray={this.state.sortArray}
                    />
                  </th>
                  <th style={{ width: "80px" }}>
                    <TableHeaderSort
                      header="Beneficiaries"
                      sortParam="benficiary_count"
                      sortData={this.clientSort}
                      sortArray={this.state.sortArray}
                    />
                  </th>
                  <th style={{ width: "60px" }}></th>
                </tr>
              </thead>
              <tbody>
                {this.state.isLoading ? (
                  <tr>
                    <td colSpan={40}>
                      <Loader />
                    </td>
                  </tr>
                ) : this.state.data.length > 0 ? (
                  this.state.data.map((d) => (
                    <tr className="body-row" key={d.id}>
                      <td>
                        <Form.Check
                          id={`toggleClientRadio-${d.id}`}
                          name="toggleClientRadio"
                          type="radio"
                          onChange={(evt) => {
                            this.allocateClient(evt, d);
                          }}
                        />
                      </td>
                      <td>
                        <div
                          className="flex-row"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.showViewClient(d, "view");
                          }}
                        >
                          <span>{`${d.firstname} ${d.surname}`}</span>
                        </div>
                      </td>
                      <td>
                        <div
                          className="profileIco jv"
                          style={{ cursor: "pointer", margin: "auto" }}
                          onClick={() => {
                            this.showViewClient(d, "view");
                          }}
                        >
                          <span>
                            {d.joint_venture
                              ? getJVPartners()?.find(
                                  (jv) => jv.id == d.joint_venture
                                ).short_name
                              : "N/A"}
                          </span>
                        </div>
                      </td>
                      <td
                        style={{ textAlign: "right", cursor: "pointer" }}
                        onClick={() => {
                          this.showViewClient(d, "view");
                        }}
                      >
                        <div className="row">
                          <div className="col-12">
                            {numberFormat(d.balance ? d.balance : "0.00")}
                          </div>
                        </div>
                      </td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.showViewClient(d, "view");
                        }}
                      >
                        <div className="allowance-wrapper row">
                          <div className="col-3 pr-0">
                            <span className="allowance sda">SDA</span>
                          </div>
                          <div className="col-9 pr-0 pl-1">
                            <span className="allowace-figure">
                              {numberFormat(
                                d.sda_allowance ? d.sda_allowance : "0.00"
                              )}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.showViewClient(d, "view");
                        }}
                      >
                        <div className="allowance-wrapper row">
                          <div className="col-3 pr-0">
                            <span className="allowance fia">FIA</span>
                          </div>
                          <div className="col-9 pr-0 pl-1">
                            <span className="allowace-figure">
                              {numberFormat(
                                d.fia_allowance ? d.fia_allowance : "0.00"
                              )}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.showViewClient(d, "view");
                        }}
                      >
                        {d.is_active ? (
                          <span className="statusColumn active-client">
                            Active
                          </span>
                        ) : (
                          <span className="statusColumn inactive-client">
                            Inactive
                          </span>
                        )}
                      </td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={async (evt) => {
                          await this.allocateClient(evt, d);
                          this.showNotes(d);
                        }}
                      >
                        <FAIcons.FaStickyNote />{" "}
                        <span className="notes-count">{d.note_count}</span>
                      </td>
                      <td
                        id="beneficiary-view"
                        name="beneficiary-view"
                        style={{ cursor: "pointer" }}
                        onClick={async (evt) => {
                          await this.allocateClient(evt, d);
                          this.showViewBeneficiary();
                        }}
                      >
                        <AIIcons.AiOutlineTeam id="beneficiary-view" />{" "}
                        <span className="notes-count" id="beneficiary-view">
                          {d.beneficiary_count}
                        </span>
                      </td>
                      <td>
                        <div className="row">
                          <div
                            id="edit-client"
                            className="col-12 flexCol"
                            style={{ alignItems: "center" }}
                          >
                            <Link
                              className="flexCol"
                              style={{ alignItems: "center" }}
                              to={`/client-view/client-edit/${d.id}`}
                            >
                              <BIIcons.BiEdit
                                size={15}
                                style={{ color: "#25A9E1" }}
                              />

                              <span>Edit</span>
                            </Link>
                          </div>
                          {/* <div
                            className="col-6 flexCol"
                            style={{ alignItems: "center" }}
                            id="delete-client"
                          >
                            <FAIcons.FaTrash
                              size={15}
                              style={{ color: "#790000" }}
                            />
                            <span>Delete</span>
                          </div> */}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={40}> No records found </td>
                  </tr>
                )}
              </tbody>
            </Table>

            <div className="row mt-2 table-footer">
              <div className="col-8 card flex-row">
                <span>Hot Keys:</span>
                <span>Shift + R (Refresh)</span>
              </div>
              <div className="col-4 flex-row card bg-w">
                <Pagination
                  handleChange={this.handleChange}
                  search={this.clientSearch}
                  rows_per_page={this.state.rows_per_page}
                  resetPage={this.resetPage}
                  page={this.state.page}
                  page_count={this.state.page_count}
                  pageUp={this.pageUp}
                  pageDown={this.pageDown}
                  upper_threshold={this.state.upper_threshold}
                  lower_threshold={this.state.lower_threshold}
                />
              </div>
            </div>
          </div>
        </HotKeys>
        {this.state.showNote ? (
          <NoteModal
            showNotes={this.state.showNote}
            showModal={this.showNotes}
            trade_id={null}
            client_id={this.state.allocatedClient.entity}
            client={`${this.state.allocatedClient.firstname} ${this.state.allocatedClient.surname}`}
            tradeSearch={this.clientSearch}
          />
        ) : (
          <></>
        )}

        {this.state.addClient ? (
          <ClientForm
            showForm={this.showAddClient}
            client={this.state.allocatedClient}
            isShown={this.state.addClient}
            next={this.showViewClient}
            close={this.closeAddClient}
          />
        ) : null}

        {this.state.viewClient ? (
          <ClientView
            isShown={this.state.viewClient}
            close={this.closeViewClient}
            back={this.showAddClient}
            client={this.state.allocatedClient}
            next={this.confirmClient}
            type={this.state.clientFormType}
          />
        ) : null}

        {this.state.viewBeneficiary ? (
          <EntityBeneficiaryView
            isShown={this.state.viewBeneficiary}
            close={this.closeViewBeneficiary}
            client={this.state.allocatedClient}
            addNew={() => {
              this.setState({
                viewBeneficiary: false,
              });
              this.showAddBeneficiary();
            }}
            edit={this.showEditBeneficiary}
          />
        ) : null}

        {this.state.addBeneficiary ? (
          <EntityBeneficiaryForm
            isShown={this.state.addBeneficiary}
            close={this.closeAddBeneficiary}
            client={this.state.allocatedClient}
            next={this.confirmEntityBeneficiary}
            type="create"
          />
        ) : null}

        {this.state.editBeneficiary ? (
          <EntityBeneficiaryEditForm
            isShown={this.state.editBeneficiary}
            close={this.closeEditBeneficiary}
            client={this.state.allocatedClient}
            next={this.updateEntityBeneficiary}
            type="update"
            beneficiary={this.state.allocatedBeneficiary}
          />
        ) : null}

        <FormLoader
          isShown={this.state.showLoader}
          message="Saving Information"
          close={() => {
            this.setState({
              showLoader: false,
            });
          }}
        />

        <RequestResponseModal
          isShown={this.state.showConfirmation}
          message={this.state.requestMessage}
          success={this.state.success}
          close={() => {
            this.setState({
              showConfirmation: false,
            });
          }}
        />

        {/* <BeneficiaryForm
          isShown={this.state.addBeneficiary}
          close={this.closeAddBeneficiary}
          allocatedClient={this.state.allocatedClient}
        />

        <CurrentTrades
          isShown={this.state.currentTradeModal}
          close={this.closeCurrentTrade}
        />
        <CurrentTradesReport
          isShown={this.state.currentTradeReportModal}
          close={this.closeCurrentTradeReport}
        />

        <CurrentMarketOrders
          isShown={this.state.currentMarkertOrdersModal}
          close={this.closeCurrentMarketOrders}
        /> */}
      </>
    );
  }
}

ClientTable.propTypes = {
  data: PropTypes.array,
};

export default ClientTable;
