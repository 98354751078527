import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useInputState from "../../hooks/useInputState";
import { getJVPartners } from "../../lookups";
import { isNotEmpty } from "../../utils/inputValidationRules";
import Loader from "../UI/Loader/Loader";
import { clientSearch, updateClient } from "../../api/clients.api";
import { RequestResponseModal } from "../form-cofirmation-modal/form-confirmation-modal";

const PersonalInformationForm = (props) => {
  const [client, setClient] = useState(props.client);
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const unmounted = useRef(false);

  useEffect(() => {
    setIsLoading(true);
    getClient();
    setIsLoading(false);
    return () => {
      unmounted.current = true;
    };
  }, []);

  const getClient = async () => {
    try {
      const response = await clientSearch("id", props.id, 1, 1, []);
      if (response) {
        if (!unmounted.current) setClient(response.data.results[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const {
    id,
    firstname: defaultFirstName,
    surname: defaultLastName,
    birth: defaultDOB,
    identity_number: defaultIDNumber,
    joint_venture: defaultJVPartner,
    tax_number: defaultTaxNumber,
    entity,
  } = client;

  const {
    value: firstNameValue,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
  } = useInputState(isNotEmpty, defaultFirstName ? defaultFirstName : "");
  const {
    value: lastNameValue,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
  } = useInputState(isNotEmpty, defaultLastName ? defaultLastName : "");
  const {
    value: dobValue,
    isValid: dobIsValid,
    hasError: dobHasError,
    valueChangeHandler: dobChangeHandler,
    inputBlurHandler: dobBlurHandler,
  } = useInputState(isNotEmpty, defaultDOB ? defaultDOB : "");
  const {
    value: idNumberValue,
    isValid: idNumberIsValid,
    hasError: idNumberHasError,
    valueChangeHandler: idNumberChangeHandler,
    inputBlurHandler: idNumberBlurHandler,
  } = useInputState(isNotEmpty, defaultIDNumber ? defaultIDNumber : "");
  const {
    value: taxNumberValue,
    isValid: taxNumberIsValid,
    hasError: taxNumberHasError,
    valueChangeHandler: taxNumberChangeHandler,
    inputBlurHandler: taxNumberBlurHandler,
  } = useInputState(isNotEmpty, defaultTaxNumber ? defaultTaxNumber : "");
  const {
    value: jvPartnerValue,
    isValid: jvPartnerIsValid,
    hasError: jvPartnerHasError,
    valueChangeHandler: jvPartnerChangeHandler,
    inputBlurHandler: jvPartnerBlurHandler,
  } = useInputState(isNotEmpty, defaultJVPartner ? defaultJVPartner : "");

  let formIsValid = false;

  const editClient = async (client) => {
    setRequestIsLoading(true);
    try {
      const response = await updateClient(id, client);
      if (response) {
        setResponseMessage("Client updated successfully!");
        setSuccess(true);
        setRequestIsLoading(false);
        setConfirmationModal(true);
      }
    } catch (error) {
      setResponseMessage(`${error.message}. Please contact system admin.`);
      setSuccess(false);
      setRequestIsLoading(false);
      setConfirmationModal(true);
    }
  };

  if (
    firstNameIsValid &&
    lastNameIsValid &&
    dobIsValid &&
    idNumberIsValid &&
    taxNumberIsValid &&
    jvPartnerIsValid
  ) {
    formIsValid = true;
  }

  const submitHandler = async (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    const clientObject = {
      entity: entity,
      firstname: firstNameValue,
      surname: lastNameValue,
      birth: dobValue,
      tax_number: taxNumberValue,
      joint_venture: jvPartnerValue,
    };

    await editClient(clientObject);
  };

  const firstNameClasses = firstNameHasError
    ? "cams-form-control invalid"
    : "cams-form-control";
  const lastNameClasses = lastNameHasError
    ? "cams-form-control invalid"
    : "cams-form-control";
  const dobClasses = dobHasError
    ? "cams-form-control invalid"
    : "cams-form-control";
  const idNumberClasses = idNumberHasError
    ? "cams-form-control invalid"
    : "cams-form-control";
  const taxNumberClasses = taxNumberHasError
    ? "cams-form-control invalid"
    : "cams-form-control";

  const jvPartnerClasses = jvPartnerHasError
    ? "cams-form-control invalid"
    : "cams-form-control";

  return (
    <div className="card">
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <h4 className="card-title">Personal Information</h4>
          <form onSubmit={submitHandler}>
            <div className="flexCol">
              <div className="row w-100">
                <div className={`col-6 ${firstNameClasses}`}>
                  <label className="form-label">First Name*</label>
                  <input
                    readOnly={true}
                    type="text"
                    value={firstNameValue}
                    onChange={firstNameChangeHandler}
                    onBlur={firstNameBlurHandler}
                  />
                </div>
                <div className={`col-6 ${lastNameClasses}`}>
                  <label className="form-label">Last Name*</label>
                  <input
                    readOnly={true}
                    type="text"
                    value={lastNameValue}
                    onChange={lastNameChangeHandler}
                    onBlur={lastNameBlurHandler}
                  />
                </div>
                <div className={`col-6 ${dobClasses}`}>
                  <label className="form-label">Date of Birth*</label>
                  <input
                    readOnly={true}
                    type="date"
                    value={dobValue}
                    onChange={dobChangeHandler}
                    onBlur={dobBlurHandler}
                  />
                </div>
                <div className={`col-6 ${idNumberClasses}`}>
                  <label className="form-label">Identity Number*</label>
                  <input
                    readOnly={true}
                    type="text"
                    value={idNumberValue}
                    onChange={idNumberChangeHandler}
                    onBlur={idNumberBlurHandler}
                  />
                </div>
                <div className={`col-6 ${taxNumberClasses}`}>
                  <label className="form-label">Tax Number*</label>
                  <input
                    readOnly={true}
                    type="text"
                    value={taxNumberValue}
                    onChange={taxNumberChangeHandler}
                    onBlur={taxNumberBlurHandler}
                  />
                </div>
                <div className={`col-6 ${jvPartnerClasses}`}>
                  <label className="form-label">JV Partner*</label>
                  <select
                    value={jvPartnerValue}
                    onChange={jvPartnerChangeHandler}
                    onBlur={jvPartnerBlurHandler}
                  >
                    <option value={""}>-- Select Partner --</option>
                    {getJVPartners()?.map((c, index) => (
                      <option key={index} value={c.id}>
                        {c.long_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-2 offset-10">
                  <button disabled={!formIsValid} className="btn btn-primary">
                    Update Client
                  </button>
                </div>
              </div>
            </div>
          </form>
        </React.Fragment>
      )}

      {requestIsLoading ? (
        <RequestResponseModal
          isShown={requestIsLoading}
          loader={<Loader />}
          message={"Saving Information"}
          close={() => {
            setRequestIsLoading(false);
          }}
        />
      ) : null}

      {confirmationModal ? (
        <RequestResponseModal
          isShown={confirmationModal}
          success={success}
          message={responseMessage}
          close={() => {
            setConfirmationModal(false);
            getClient();
          }}
        />
      ) : null}
    </div>
  );
};

PersonalInformationForm.propTypes = {
  id: PropTypes.number,
  client: PropTypes.object,
};

export default PersonalInformationForm;
