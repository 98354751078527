import React from "react";
import EmptyStateRobot from "../../assets/empty-state-robot.png";
import PropTypes from "prop-types";
import "./empty-state.css";

export const EmptyState = (props) => {
  return (
    <div className="empty-state-container">
      <img
        src={EmptyStateRobot}
        alt="empty-state-robot.png"
        className="empty-state-image pt-4"
      />
      <p className="empty-state-message pl-2 pr-2">{props.message}</p>
      {props.callback ? (
        <button onClick={() => props.callback()}>{props.buttonText}</button>
      ) : null}
    </div>
  );
};

EmptyState.propTypes = {
  message: PropTypes.string,
  buttonText: PropTypes.string,
  callback: PropTypes.func,
};
