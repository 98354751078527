//Lookup Calls
import {
  LookupsApi,
  JointVenturesApi,
  StatusCodesApi,
  TradesApi,
  NotesApi,
  BanksApi,
  BankingApi,
} from "axios-cams-api";

let api = new LookupsApi();
let jvApi = new JointVenturesApi();
let statusCodesApi = new StatusCodesApi();
let tradesApi = new TradesApi();
let notesApi = new NotesApi();
let banksApi = new BanksApi();
let bankingApi = new BankingApi();

let bopCodes,
  documentTypes,
  statusList,
  currencies,
  countries,
  jv_partners,
  banks,
  bankBranches,
  accountTypes,
  tradeStatusCounts = [];

export function getBopCodes() {
  return bopCodes;
}

export function getDocumentTypes() {
  return documentTypes;
}

export function getCurrencies() {
  return currencies;
}

export function getStatusList() {
  return statusList;
}

export function getCountries() {
  return countries;
}

export function getJVPartners() {
  return jv_partners;
}

export function getBanks() {
  return banks;
}

export function getBankBranches() {
  return bankBranches;
}

export function getAccountTypes() {
  return accountTypes;
}

export function getTradeStatusCount() {
  return tradeStatusCounts;
}

export async function callBopCodes() {
  try {
    let res = await api.lookupsBopList("True", {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      bopCodes = res.data;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function callCurencies() {
  try {
    let res = await api.lookupsCurrenciesList({
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      currencies = res.data;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function callDocumentTypes() {
  try {
    let res = await api.lookupsDocumentTypesList({
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      documentTypes = res.data;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function callPartners() {
  try {
    let res = await jvApi.jointVenturesList("", 1, 0, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      jv_partners = res.data.results;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function callStatusList() {
  try {
    let res = await statusCodesApi.statusCodesList(1, 1000, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      statusList = res.data.results;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function callTradeHistory(id) {
  try {
    let res = await tradesApi.tradesHistoryRead(id, id, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      return res.data;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function callTradeNotes(id) {
  try {
    let res = await notesApi.notesRead(id, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      return res.data;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function callCountries() {
  try {
    let res = await api.lookupsCountryList(1, 0, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      countries = res.data.results;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function callGetProvinces(country) {
  try {
    let res = await api.lookupsProvinceList(country, 1, 0, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      return res.data.results;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function callGetBanks() {
  try {
    let res = await banksApi.banksList(1, 0, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      banks = res.data.results;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function callGetBankBranches() {
  try {
    let res = await bankingApi.bankingBranchesList("", 1, 0, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      bankBranches = res.data.results;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function callGetAccountTypes() {
  try {
    let res = await bankingApi.bankingAccounttypesList("", 1, 0, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      accountTypes = res.data.results;
    }
  } catch (ex) {
    console.log(ex);
  }
}

export async function callTradeStatusCount(startDate, endDate) {
  try {
    let res = await tradesApi.tradesStatusList(startDate, endDate, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      return res.data;
    }
  } catch (ex) {
    console.log(ex);
  }
}
