import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { requiredField } from "../../../common/helper";
import { getCurrencies, getCountries } from "../../../lookups";

import "./beneficiary-edit-form.css";

class BeneficiaryEditForm extends Component {
  constructor(props) {
    super(props);
  }

  handleChange(evt) {
    this.setState((st) => ({
      beneficiary: {
        ...st.beneficiary,
        [evt.target.name]: evt.target.value,
      },
    }));
  }

  handleCurrencyChange(evt) {
    this.setState((st) => ({
      beneficiary: {
        ...st.beneficiary,
        currency: {
          ...st.beneficiary.currency,
          [evt.target.name]: evt.target.value,
        },
      },
    }));
  }

  formValidation() {
    this.setState({
      errorArray: Object.keys(this.state.beneficiary).map((stateKey) => {
        if (stateKey !== "notes")
          return requiredField(this.state.beneficiary[stateKey], stateKey);
      }),
    });
  }

  render() {
    return (
      <Modal>
        <Modal.Header closeButton>
          <Modal.Title>Edit Beneficary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-12">
                  <label className="form-label">Name*</label>
                  <InputGroup className="mb-3">
                    <FormControl
                      name="name"
                      id="name"
                      value={this.state.beneficiary?.name}
                      onChange={this.handleChange}
                      onBlur={(evt) =>
                        requiredField(evt.target.value, evt.target.name)
                      }
                    />
                  </InputGroup>
                </div>
                <div className="col-12">
                  <label className="form-label">
                    Registered Beneficiary Name*
                  </label>
                  <InputGroup className="mb-3">
                    <FormControl
                      name="beneficiary_name"
                      id="beneficiary_name"
                      value={this.state.beneficiary?.beneficiary_name}
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </div>
                <div className="col-12">
                  <label className="form-label">Short Name*</label>
                  <InputGroup className="mb-3">
                    <FormControl
                      name="short_name"
                      id="short_name"
                      value={this.state.beneficiary?.short_name}
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </div>
                <div className={"col-12"}>
                  <label className="form-label">Name of Bank*</label>
                  <InputGroup className="mb-3">
                    <FormControl
                      id="bank_name"
                      name="bank_name"
                      value={this.state.beneficiary?.bank_name}
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </div>
                <div className="col-6">
                  <label className="form-label">Bank Country*</label>
                  <Form.Control
                    as="select"
                    name="bank_country"
                    id="bank_country"
                    value={this.state.beneficiary?.bank_country}
                    onChange={this.handleChange}
                  >
                    {getCountries().map((c) => (
                      <option key={c.id} value={c.name}>
                        {c.name}
                      </option>
                    ))}
                  </Form.Control>
                </div>
                <div className="col-6">
                  <label className="form-label">Currency*</label>
                  <Form.Control
                    as="select"
                    name="currency"
                    id="currency"
                    className="mb-3"
                    value={this.state.beneficiary?.currency}
                    onChange={this.handleChange}
                  >
                    {getCurrencies().map((c) => (
                      <option key={c.alpha_3}>{c.alpha_3}</option>
                    ))}
                  </Form.Control>
                </div>
                <div className="col-12">
                  <label className="form-label">Bank Address*</label>
                  <InputGroup className="mb-3">
                    <FormControl
                      id="bank_address"
                      name="bank_address"
                      as="textarea"
                      className="add-note-textarea"
                      value={this.state.beneficiary?.bank_address}
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-4">
                  <label className="form-label">Swift / BIC Code*</label>
                  <InputGroup className="mb-3">
                    <FormControl
                      id="swift_or_bic"
                      name="swift_or_bic"
                      value={this.state.beneficiary?.swift_or_bic}
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </div>
                <div className="col-8">
                  <label className="form-label">Account Number / IBAN*</label>
                  <InputGroup className="mb-3">
                    <FormControl
                      id="beneficiary_account_number"
                      name="beneficiary_account_number"
                      value={this.state.beneficiary?.beneficiary_account_number}
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </div>
                <div className="col-12">
                  <label className="form-label">Crypto*</label>
                  <Form.Control
                    as="select"
                    name="crypto"
                    id="crypto"
                    className="mb-3"
                    value={this.state.beneficiary?.crypto}
                    onChange={this.handleChange}
                  >
                    <option>True</option>
                    <option>False</option>
                  </Form.Control>
                </div>
                <div className="col-12">
                  <label className="form-label">Authorised Dealer*</label>
                  <InputGroup className="mb-3">
                    <FormControl
                      id="authorised_dealer"
                      name="authorised_dealer"
                      value={this.state.beneficiary?.authorised_dealer}
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </div>
                <div className="col-12">
                  <label className="form-label">
                    Authorised Dealer Account Number*
                  </label>
                  <InputGroup className="mb-3">
                    <FormControl
                      id="authorised_dealer_account_number"
                      name="authorised_dealer_account_number"
                      value={
                        this.state.beneficiary?.authorised_dealer_account_number
                      }
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </div>
                <div className="col-12">
                  <label className="form-label">Beneficiary Country*</label>
                  <InputGroup className="mb-3">
                    <FormControl
                      id="beneficiary_country"
                      name="beneficiary_country"
                      as="select"
                      value={this.state.beneficiary?.beneficiary_country}
                      onChange={this.handleChange}
                    >
                      {getCountries().map((c, index) => (
                        <option key={index} value={c.name}>
                          {c.name}
                        </option>
                      ))}
                    </FormControl>
                  </InputGroup>
                </div>
                <div className="col-12">
                  <label className="form-label">Beneficiary Address*</label>
                  <InputGroup className="mb-3">
                    <FormControl
                      id="beneficiary_address"
                      name="beneficiary_address"
                      as="textarea"
                      className="add-note-textarea"
                      value={this.state.beneficiary?.beneficiary_address}
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button>Back</Button>
          <Button>Confrim Changes</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BeneficiaryEditForm;
