//React Imports
import React, { useContext } from "react";

//Context Imports
import TradeContext from "../../store/tradeState/tradeContext";

//Component Imports
import TableHeaderSort from "../table-header-sort/table-header-sort";

//Icon Imports
import { FaStickyNote } from "react-icons/fa";

//Helper Import
import { numberFormat, handleStatusStyle } from "../../common/helper";

//Styles Import
import "./trade-table.css";

const TradeTable = () => {
  const tradeCtx = useContext(TradeContext);

  const handleShowTrade = (trade) => {
    tradeCtx.setTrade(trade);
    tradeCtx.setShowTrade(true);
  };

  return (
    <table className="table table-hover sticky-header">
      <thead>
        <tr>
          <th style={{ width: "20px" }}>
            <input
              type="checkbox"
              className="checkbox-circle"
              //   checked={this.state.toggleAllIsChecked}
              //   onChange={(evt) => this.toggleAllTrades(evt.target.checked)}
            />
          </th>
          <th style={{ width: "200px" }}>
            <TableHeaderSort
              header="Customer"
              sortParam="customer__name"
              sortData={(data) => tradeCtx.setSortArray([...data])}
              sortArray={tradeCtx.sortArray}
            />
          </th>
          <th style={{ width: "50px" }}>
            <TableHeaderSort
              header="Bank"
              sortParam="bank__name"
              sortData={(data) => tradeCtx.setSortArray([...data])}
              sortArray={tradeCtx.sortArray}
            />
          </th>
          <th style={{ width: "150px" }}>
            <TableHeaderSort
              header="Trade Number"
              sortParam="trade_reference_number"
              sortData={(data) => {
                tradeCtx.setSortArray([...data]);
              }}
              sortArray={tradeCtx.sortArray}
            />
          </th>
          <th style={{ width: "100px" }}>
            <TableHeaderSort
              header="Beneficiary"
              sortParam="beneficiary"
              sortData={(data) => tradeCtx.setSortArray([...data])}
              sortArray={tradeCtx.sortArray}
            />
          </th>
          <th style={{ width: "110px" }}>
            <TableHeaderSort
              header="Value Date"
              sortParam="value_date"
              sortData={(data) => tradeCtx.setSortArray([...data])}
              sortArray={tradeCtx.sortArray}
            />
          </th>
          <th style={{ width: "110px" }}>
            <TableHeaderSort
              header="Trade Date"
              sortParam="trade_date"
              sortData={(data) => tradeCtx.setSortArray([...data])}
              sortArray={tradeCtx.sortArray}
              isActive={true}
              isDescending={true}
            />
          </th>
          <th style={{ width: "140px" }}>
            <TableHeaderSort
              header="Balance"
              sortParam="customer_id__balance"
              sortData={(data) => tradeCtx.setSortArray([...data])}
              sortArray={tradeCtx.sortArray}
            />
          </th>
          <th style={{ width: "140px" }}>
            <TableHeaderSort
              header="Trade Amount"
              sortParam="trade_amount"
              sortData={(data) => tradeCtx.setSortArray([...data])}
              sortArray={tradeCtx.sortArray}
            />
          </th>
          <th style={{ width: "80px" }}>
            <TableHeaderSort
              header="Exc. Rate"
              sortParam="exchange_rate"
              sortData={(data) => tradeCtx.setSortArray([...data])}
              sortArray={tradeCtx.sortArray}
            />
          </th>
          <th style={{ width: "140px" }}>
            <TableHeaderSort
              header="FX Amount"
              sortParam="fx_amount"
              sortData={(data) => tradeCtx.setSortArray([...data])}
              sortArray={tradeCtx.sortArray}
            />
          </th>
          <th style={{ width: "100px" }}>
            <TableHeaderSort
              header="BOP Code"
              sortParam="bop_code"
              sortData={(data) => tradeCtx.setSortArray([...data])}
              sortArray={tradeCtx.sortArray}
            />
          </th>
          <th style={{ width: "50px" }}>
            <TableHeaderSort
              header="Type"
              sortParam="allowance_type"
              sortData={(data) => tradeCtx.setSortArray([...data])}
              sortArray={tradeCtx.sortArray}
            />
          </th>
          <th style={{ width: "100px" }}>
            <TableHeaderSort
              header="Status"
              sortParam="status__name"
              sortData={(data) => tradeCtx.setSortArray([...data])}
              sortArray={tradeCtx.sortArray}
            />
          </th>
          <th style={{ width: "60px" }}>
            <TableHeaderSort
              header="Notes"
              sortParam="notes_total"
              sortData={(data) => tradeCtx.setSortArray([...data])}
              sortArray={tradeCtx.sortArray}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {tradeCtx.trades.length > 0 ? (
          tradeCtx.trades.map((t) => (
            <tr key={t.id}>
              <td>
                <input
                  type="checkbox"
                  checked={
                    tradeCtx.tradeBatch.find((lt) => lt == t.id) ? true : false
                  }
                  onChange={() =>
                    tradeCtx.setTradeBatch(
                      tradeCtx.tradeBatch.find((lt) => lt == t.id)
                        ? tradeCtx.tradeBatch.filter((lt) => lt !== t.id)
                        : tradeCtx.tradeBatch.concat(t.id)
                    )
                  }
                />
              </td>
              <td
                onClick={() => {
                  handleShowTrade(t);
                }}
              >
                {t.customer?.name}
              </td>
              <td
                onClick={() => {
                  handleShowTrade(t);
                }}
              >
                {t.bank.display_name}
              </td>
              <td
                onClick={() => {
                  handleShowTrade(t);
                }}
              >
                {t.trade_reference_number}
              </td>
              <td
                onClick={() => {
                  handleShowTrade(t);
                }}
              >
                {t.beneficiary}
              </td>
              <td
                onClick={() => {
                  handleShowTrade(t);
                }}
              >
                {t.value_date}
              </td>
              <td
                onClick={() => {
                  handleShowTrade(t);
                }}
              >
                {t.trade_date}
              </td>
              <td
                onClick={() => {
                  handleShowTrade(t);
                }}
              >
                {numberFormat(t.customer?.balance)}
              </td>
              <td
                onClick={() => {
                  handleShowTrade(t);
                }}
              >{`${t.trade_currency.symbol} ${numberFormat(
                t.trade_amount
              )}`}</td>
              <td
                onClick={() => {
                  handleShowTrade(t);
                }}
              >
                {t.exchange_rate}
              </td>
              <td
                onClick={() => {
                  handleShowTrade(t);
                }}
              >
                {t.fx_currency.symbol} {numberFormat(t.fx_amount)}
              </td>
              <td
                onClick={() => {
                  handleShowTrade(t);
                }}
              >
                {t.bop_code}
              </td>
              <td
                onClick={() => {
                  handleShowTrade(t);
                }}
              >
                {t.allowance_type}
              </td>
              <td
                style={{ cursor: "pointer" }}
                onDoubleClick={() => {
                  tradeCtx.retryTrades([t.id]);
                }}
                className="statusTD"
              >
                <span
                  className={`${
                    t.locked ? "trade_locked" : "statusColumn"
                  } ${handleStatusStyle(t.status.name)} noselect`}
                >
                  {t.status.name}
                </span>
              </td>
              <td
                onClick={() => {
                  tradeCtx.setTrade(t);
                  tradeCtx.setShowNotes(true);
                }}
              >
                <FaStickyNote />
                <span className="notes-count"> {t.notes_total}</span>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={40}>No trades found</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TradeTable;
