import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { HotKeys } from "react-hotkeys";
import Loader from "../../loader/loader";
import "./entity-beneficiary-view.css";
import {
  getEntityBeneficiaryById,
  getBeneficiaryById,
} from "../../../api/beneficiaries.api";
import { BiEdit } from "react-icons/bi";
import Button from "react-bootstrap/Button";

const keyMap = {
  PREVIEW: "shift+a",
  EDIT: "shift+e",
};

class EntityBeneficiaryView extends Component {
  handlers = {
    PREVIEW: () => this.props.addNew(),
    EDIT: () => this.props.edit(this.state.beneficiaries[this.state.page]),
  };

  constructor(props) {
    super(props);
    this.state = {
      beneficiaries: [],
      benName: "",
      page: 0,
      isLoading: false,
    };

    this.pageUp = this.pageUp.bind(this);
    this.pageDown = this.pageDown.bind(this);
  }

  async componentDidMount() {
    await this.fetchBeneficiaries();
  }

  async getBeneficiaryById() {
    this.setState({ isLoading: true }, () => {
      getBeneficiaryById(
        this.state.beneficiaries[this.state.page]?.beneficiary_id
      ).then((res) => {
        this.setState({
          isLoading: false,
          benName: `${res?.name}: ${res?.currency} - ${res?.beneficiary_account_number}`,
        });
      });
    });
  }

  async fetchBeneficiaries() {
    this.setState({ isLoading: true }, () => {
      getEntityBeneficiaryById(this.props.client.entity).then((res) =>
        this.setState(
          {
            beneficiaries: res?.results,
            isLoading: false,
          },
          () => {
            this.getBeneficiaryById();
          }
        )
      );
    });
  }

  pageDown() {
    if (this.state.page != 0) {
      this.setState(
        (st) => ({
          page: st.page - 1,
        }),
        () => {
          this.getBeneficiaryById();
        }
      );
    }
  }

  pageUp() {
    if (this.state.page + 1 != this.state.beneficiaries.length) {
      this.setState(
        (st) => ({
          page: st.page + 1,
        }),
        () => {
          this.getBeneficiaryById();
        }
      );
    }
  }

  render() {
    return (
      <>
        <HotKeys className="w-100" keyMap={keyMap} handlers={this.handlers}>
          <Modal
            show={this.props.isShown}
            animation={false}
            onHide={() => {
              this.props.close();
            }}
          >
            <Modal.Header closeButton>
              <div className="col-6 no-padding-l">
                <h4>
                  {" "}
                  {this.props.client.firstname} {this.props.client.surname}
                </h4>
              </div>
              <div className="col-1">
                <p className="edit-icon">
                  <BiEdit
                    onClick={() =>
                      this.props.edit(this.state.beneficiaries[this.state.page])
                    }
                  />
                </p>
              </div>
              <div className="col-4 flexCol">
                <span className="tip-text">shift + a (add beneficiary)</span>
                <span className="tip-text">shift + e (edit beneficiary)</span>
              </div>
            </Modal.Header>
            {this.state.isLoading ? (
              <Modal.Body>
                <div style={{ textAlign: "center" }}>
                  <Loader />
                </div>
              </Modal.Body>
            ) : (
              <>
                <Modal.Body>
                  <div className="row">
                    {this.state.beneficiaries.length > 0 ? (
                      <>
                        <div className="col-4">
                          <label className="form-label">Friendly Name:</label>
                        </div>
                        <div className="col-8">
                          <label className="">
                            {
                              this.state.beneficiaries[this.state.page]
                                ?.friendly_name
                            }
                          </label>
                        </div>
                        <div className="col-4">
                          <label className="form-label">Beneficiary:</label>
                        </div>
                        <div className="col-8">
                          <label className="">
                            <span>{this.state.benName}</span>
                          </label>
                        </div>
                        <div className="col-4">
                          <label className="form-label">
                            Client Reference:
                          </label>
                        </div>
                        <div className="col-8">
                          <label className="">
                            {
                              this.state.beneficiaries[this.state.page]
                                ?.client_reference_beneficiary
                            }
                          </label>
                        </div>
                      </>
                    ) : (
                      <div className="col-12" style={{ textAlign: "center" }}>
                        <Button
                          onClick={() => {
                            this.props.addNew();
                          }}
                        >
                          Add New Enity Beneficiary
                        </Button>
                      </div>
                    )}
                  </div>
                </Modal.Body>

                {this.state.beneficiaries.length > 0 ? (
                  <Modal.Footer className="view-nav-footer">
                    <div className="flex-row">
                      <label
                        onClick={this.pageDown}
                        className="pagination-nav table-footer-label noselect"
                      >{` < `}</label>

                      <label className="table-footer-label noselect">
                        {this.state.page + 1} of{" "}
                        {this.state.beneficiaries.length}
                      </label>

                      <label
                        onClick={this.pageUp}
                        className="pagination-nav table-footer-label noselect"
                      >{` > `}</label>
                    </div>
                  </Modal.Footer>
                ) : null}
              </>
            )}
          </Modal>
        </HotKeys>
      </>
    );
  }
}

EntityBeneficiaryView.propTypes = {
  client: PropTypes.object,
  isShown: PropTypes.bool,
  close: PropTypes.func,
  addNew: PropTypes.func,
  edit: PropTypes.func,
};

export default EntityBeneficiaryView;
