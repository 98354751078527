import React, { useState, useEffect } from "react";
import "./table-header-sort.css";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import PropTypes from "prop-types";

const TableHeaderSort = ({
  isActive,
  isAscending,
  isDescending,
  sortArray,
  sortParam,
  sortData,
  header,
}) => {
  const [active, setActive] = useState(isActive);
  const [ascending, setAscending] = useState(isAscending);
  const [clickCount, setClickCount] = useState(
    isAscending ? 1 : isDescending ? 2 : 0
  );

  useEffect(() => {
    handleSort();
  }, [clickCount]);

  const handleSort = async () => {
    var tmpSortArray = [];
    var itemIndex = 0;
    if (clickCount === 3) {
      setActive(false);
      tmpSortArray = sortArray;
      itemIndex = sortArray.indexOf(`-${sortParam}`);
      tmpSortArray.splice(itemIndex, 1);
      sortData(tmpSortArray);
    } else if (clickCount === 2) {
      setAscending(false);
      tmpSortArray = sortArray;
      tmpSortArray.length != 0
        ? (tmpSortArray[tmpSortArray.indexOf(sortParam)] = `-${sortParam}`)
        : (tmpSortArray[0] = `-${sortParam}`);
      sortData(tmpSortArray);
    } else if (clickCount === 1) {
      setAscending(true);
      setActive(true);
      tmpSortArray = sortArray;
      tmpSortArray.push(`${sortParam}`);
      sortData(tmpSortArray);
    }
  };

  return (
    <>
      <span
        className="column-header noselect"
        onClick={() => {
          setClickCount(clickCount === 3 ? 0 : (prevValue) => prevValue + 1);
        }}
      >
        {header}
        {active ? (
          ascending ? (
            <>
              <IoMdArrowDropup className="sort-icon" />
              <span className="sort-icon-order-number">
                {sortArray?.indexOf(sortParam) + 1}
              </span>
            </>
          ) : (
            <>
              <IoMdArrowDropdown className="sort-icon" />
              <span className="sort-icon-order-number">
                {sortArray?.indexOf(`-${sortParam}`) + 1}
              </span>
            </>
          )
        ) : (
          <></>
        )}
      </span>
    </>
  );
};

TableHeaderSort.propTypes = {
  isActive: PropTypes.bool,
  isDescending: PropTypes.bool,
  isAscending: PropTypes.bool,
  header: PropTypes.string,
  sortArray: PropTypes.array,
  sortParam: PropTypes.string,
  sortData: PropTypes.func,
};

export default TableHeaderSort;
