import React, { Component, useContext } from "react";
import "./navbar.css";
import * as FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";
import Profile from "../profile/profile";
import CALogo from "../../assets/CA_logo.png";
import PropTypes from "prop-types";
import NotificationContext from "../../store/notificationsState/notificationContext";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.showSidebar = this.showSidebar.bind(this);
  }

  showSidebar() {
    this.props.toggle();
  }

  showNotifications() {}

  render() {
    let notification = this.context;
    return (
      <div className="sidebar">
        <div className="sidebar-left">
          <Link to="#" className="menu-bars" ref={this.props.outerRef}>
            <FaIcons.FaBars onClick={this.showSidebar} />
          </Link>
        </div>
        <div className="sidebar-center">
          <img src={CALogo} alt="" />
        </div>
        <div className="sidebar-right">
          {/* <div style={{ cursor: "pointer" }}>
            <FaIcons.FaBell
              onClick={() => notification.setShowNotifications(true)}
            />
          </div> */}
          <Profile profile={this.props.profile} />
        </div>
      </div>
    );
  }
}

Navbar.contextType = NotificationContext;
Navbar.propTypes = {
  toggle: PropTypes.func,
  outerRef: PropTypes.object,
  profile: PropTypes.object,
};
export default Navbar;
