import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { getJVPartners } from "../../../lookups";

import "./client-view.css";

class ClientView extends Component {
  render() {
    return (
      <>
        <Modal
          className="client-view-modal"
          animation={false}
          show={this.props.isShown}
          onHide={() => {
            this.props.close();
          }}
        >
          <Modal.Header closeButton>
            <div>
              <label className="pre-label">Client Name</label>
              <br />
              <h3>{`${this.props.client.firstname} ${this.props.client.surname}`}</h3>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <label>Personal Information</label>
                  </div>
                  <div className="col-4">
                    <label className="form-label">First Name:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {this.props.client.firstname}
                    </label>
                  </div>
                  <div className="col-4">
                    <label className="form-label">Last Name:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {this.props.client.surname}
                    </label>
                  </div>
                  <div className="col-4">
                    <label className="form-label">Date of Birth:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {this.props.client.birth}
                    </label>
                  </div>
                  <div className="col-4">
                    <label className="form-label">Email:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {this.props.client.email
                        ? this.props.client.email
                        : this.props.client.contacts.find(
                            (c) => c.type === "Email"
                          )?.detail}
                    </label>
                  </div>
                  <div className="col-4">
                    <label className="form-label">Contact Number:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {this.props.client.phone
                        ? this.props.client.phone
                        : this.props.client.contacts.find(
                            (c) => c.type === "Cell"
                          )?.detail}
                    </label>
                  </div>
                  <div className="col-4">
                    <label className="form-label">Identity Number:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {this.props.client.identity_number}
                    </label>
                  </div>
                  <div className="col-4">
                    <label className="form-label">TAX Number:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {this.props.client.tax_number}
                    </label>
                  </div>
                  <div className="col-4">
                    <label className="form-label">JV Partner:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {
                        getJVPartners().find(
                          (j) =>
                            j.id?.toString() ===
                            this.props.client.joint_venture?.toString()
                        )?.long_name
                      }
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-4">
                    <label className="form-label">Physical Address:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {this.props.client.address
                        ? this.props.client.address
                        : this.props.client.addresses[0]?.raw}
                    </label>
                  </div>
                  <div className="col-4">
                    <label className="form-label">Suburb:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {this.props.client.suburb
                        ? this.props.client.suburb
                        : this.props.client.addresses[0]?.suburb}
                    </label>
                  </div>
                  <div className="col-4">
                    <label className="form-label">City:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {this.props.client.city
                        ? this.props.client.city
                        : this.props.client.addresses[0]?.city}
                    </label>
                  </div>
                  <div className="col-4">
                    <label className="form-label">Postal Code:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {this.props.client.postal_code
                        ? this.props.client.postal_code
                        : this.props.client.addresses[0]?.code}
                    </label>
                  </div>
                  <div className="col-4">
                    <label className="form-label">Province:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {this.props.client.province
                        ? this.props.client.province
                        : this.props.client.addresses[0]?.province}
                    </label>
                  </div>
                  <div className="col-4">
                    <label className="form-label">Country:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {this.props.client.country
                        ? this.props.client.country
                        : this.props.client.addresses[0]?.country}
                    </label>
                  </div>
                  {/* <div className="col-4">
                    <label className="form-label">Notes:</label>
                  </div>
                  <div className="col-8">
                    <label className="view-label">
                      {this.props.client.note}
                    </label>
                  </div> */}
                </div>
              </div>
            </div>
          </Modal.Body>

          {this.props.type === "confirm" ? (
            <Modal.Footer>
              {" "}
              <Button
                variant="secondary"
                onClick={() => {
                  this.props.back();
                }}
              >
                Back
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  this.props.next();
                }}
              >
                Confirm New Client
              </Button>
            </Modal.Footer>
          ) : (
            <></>
          )}
        </Modal>
      </>
    );
  }
}

ClientView.propTypes = {
  isShown: PropTypes.bool,
  close: PropTypes.func,
  client: PropTypes.object,
  next: PropTypes.func,
  back: PropTypes.func,
  type: PropTypes.string,
};

export default ClientView;
