import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import AddressForm from "../address-form/address-form";
import Loader from "../UI/Loader/Loader";
import { BsTrash } from "react-icons/bs";
import {
  RequestResponseModal,
  FormComfirmationModal,
} from "../form-cofirmation-modal/form-confirmation-modal";

import {
  getAddressByEntityID,
  createAddress,
  updateAddress,
  removeAddress,
} from "../../api/clients.api";

const AddressListing = (props) => {
  const [addresses, setAddresses] = useState([]);
  const [showAddressForm, setAddressForm] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [success, setSuccess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const unmounted = useRef(false);

  useEffect(() => {
    setIsLoading(true);
    getAddresses();
    setIsLoading(false);
    return () => {
      unmounted.current = true;
    };
  }, []);

  const getAddresses = async () => {
    try {
      const response = await getAddressByEntityID(props.entity);
      if (response && !unmounted.current) {
        setAddresses(response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const addAddress = () => {
    setSelectedAddress({ address: {} });
    setAddressForm(true);
  };

  const editAddress = (index) => {
    setSelectedAddress(addresses[index]);
    setAddressForm(true);
  };

  const deleteAddress = (index) => {
    setSelectedAddress(addresses[index]);
    setDeleteConfirmation(true);
  };

  const addAddressSubmitHandler = async (address) => {
    setAddressForm(false);
    setRequestIsLoading(true);
    try {
      let response = await createAddress({
        address: address.address,
        entity: props.entity,
      });
      if (response) {
        setResponseMessage("Address created successfully!");
        setSuccess(true);
        setRequestIsLoading(false);
        setConfirmationModal(true);
      }
    } catch (error) {
      setResponseMessage(`${error.message}. Please contact system admin.`);
      setSuccess(false);
      setRequestIsLoading(false);
      setConfirmationModal(true);
    }
  };

  const editAddressSubmitHandler = async (address) => {
    setAddressForm(false);
    setRequestIsLoading(true);
    try {
      let response = await updateAddress(selectedAddress.id, {
        ...address.address,
        id: selectedAddress.id,
      });
      if (response) {
        setResponseMessage("Address updated successfully!");
        setSuccess(true);
        setRequestIsLoading(false);
        setConfirmationModal(true);
      }
    } catch (error) {
      setResponseMessage(`${error.message}. Please contact system admin.`);
      setSuccess(false);
      setRequestIsLoading(false);
      setConfirmationModal(true);
    }
  };

  const deleteAddressSubmitHandler = async () => {
    setDeleteConfirmation(false);
    setRequestIsLoading(true);
    try {
      let response = await removeAddress(selectedAddress.id);
      if (response) {
        setResponseMessage("Address removed successfully!");
        setSuccess(true);
        setRequestIsLoading(false);
        setConfirmationModal(true);
      }
    } catch (error) {
      setResponseMessage(`${error.message}. Please contact system admin.`);
      setSuccess(false);
      setRequestIsLoading(false);
      setConfirmationModal(true);
    }
  };

  return (
    <div className="card">
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className="row">
            <div className="col-10">
              <h4 className="card-title">Address Information</h4>
            </div>
            <div className="col-2">
              <div className="account-btn" onClick={addAddress}>
                +
              </div>
            </div>
          </div>

          <div className="row w-100">
            {addresses?.length > 0 ? (
              addresses?.map((a, i) => (
                <React.Fragment key={i}>
                  <div
                    key={i}
                    className="col-11"
                    onClick={() => editAddress(i)}
                  >
                    <span className="listing-text">
                      {a.address?.raw}, {a.address?.suburb?.name},{" "}
                      {a.address.city?.name}, {a.address?.country_id},
                      {a.address?.code}
                    </span>
                  </div>
                  <div className="col-1">
                    <BsTrash
                      style={{ cursor: "pointer", color: "red" }}
                      size={15}
                      onClick={() => deleteAddress(i)}
                    />
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div className="col-12 mt-3" style={{ textAlign: "center" }}>
                <span className="listing-text ">No addresses found</span>
              </div>
            )}
          </div>
          {showAddressForm ? (
            <AddressForm
              isShown={showAddressForm}
              address={selectedAddress}
              close={() => setAddressForm(false)}
              addAddress={addAddressSubmitHandler}
              editAddress={editAddressSubmitHandler}
              entity={props.entity}
            />
          ) : null}

          {requestIsLoading ? (
            <RequestResponseModal
              isShown={requestIsLoading}
              loader={<Loader />}
              message={"Saving Information"}
              close={() => {
                setRequestIsLoading(false);
              }}
            />
          ) : null}

          {confirmationModal ? (
            <RequestResponseModal
              isShown={confirmationModal}
              success={success}
              message={responseMessage}
              close={() => {
                setConfirmationModal(false);
                getAddresses();
              }}
            />
          ) : null}

          {deleteConfirmation ? (
            <FormComfirmationModal
              isShown={deleteConfirmation}
              message="Are you sure you want to delete Address?"
              deleteCallback={deleteAddressSubmitHandler}
              close={() => setDeleteConfirmation(false)}
            />
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
};

AddressListing.propTypes = {
  entity: PropTypes.string,
};

export default AddressListing;
