import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./account-listing.css";
import { getAccountTypes, getBankBranches, getCurrencies } from "../../lookups";
import {
  getClientAccount,
  updateClientAccount,
  createClientAccount,
} from "../../api/clients.api";
import Loader from "../UI/Loader/Loader";
import { BsTrash } from "react-icons/bs";
import AccountForm from "../account-form/account-form";
import {
  RequestResponseModal,
  FormComfirmationModal,
} from "../form-cofirmation-modal/form-confirmation-modal";

const AccountListing = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [showAccountForm, setAccountForm] = useState(false);
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const unmounted = useRef(false);

  useEffect(() => {
    setIsLoading(true);
    getClientAccounts();
    setIsLoading(false);

    return () => {
      unmounted.current = true;
    };
  }, []);

  const getClientAccounts = async () => {
    try {
      const response = await getClientAccount(props.entity);
      if (response) {
        const accounts = response.map((a) => {
          return a.account;
        });
        if (!unmounted.current) setAccounts(accounts);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const editAccount = (index) => {
    setSelectedAccount(accounts[index]);
    setAccountForm(true);
  };

  const addAccount = () => {
    setSelectedAccount({});
    setAccountForm(true);
  };

  const deleteAccount = (index) => {
    setSelectedAccount(accounts[index]);
    setDeleteConfirmationModal(true);
  };

  const editAccountSubmitHandler = async (id, account) => {
    setAccountForm(false);
    setRequestIsLoading(true);
    try {
      let response = await updateClientAccount(id, account);
      if (response) {
        setResponseMessage("Account updated successfully!");
        setSuccess(true);
        setRequestIsLoading(false);
        setConfirmationModal(true);
      }
    } catch (error) {
      setResponseMessage(`${error.message}. Please contact system admin.`);
      setSuccess(false);
      setRequestIsLoading(false);
      setConfirmationModal(true);
    }
  };

  const addAccountSubmitHandler = async (account) => {
    setAccountForm(false);
    setRequestIsLoading(true);
    try {
      let accountObj = { entity: props.entity, account: account.account };
      let response = await createClientAccount(accountObj);
      if (response) {
        setResponseMessage("Account created successfully!");
        setSuccess(true);
        setRequestIsLoading(false);
        setConfirmationModal(true);
      }
    } catch (error) {
      setResponseMessage(`${error.message}. Please contact system admin.`);
      setSuccess(false);
      setRequestIsLoading(false);
      setConfirmationModal(true);
    }
  };

  const deleteAccountSubmitHandler = async () => {
    setDeleteConfirmationModal(false);
    setRequestIsLoading(true);
    const accountObj = {
      ...selectedAccount,
      is_deleted: true,
    };
    try {
      let response = await updateClientAccount(accountObj.id, accountObj);
      if (response) {
        setResponseMessage("Account removed successfully!");
        setSuccess(true);
        setRequestIsLoading(false);
        setConfirmationModal(true);
      }
    } catch (error) {
      setResponseMessage(`${error.message}. Please contact system admin.`);
      setSuccess(false);
      setRequestIsLoading(false);
      setConfirmationModal(true);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="card">
      <h4 className="card-title">Account Information</h4>
      <div className="flexCol w-100">
        <div className="row">
          <div className="col-3">
            <span className="account-listing-header">Account No.</span>
          </div>
          <div className="col-2">
            <span className="account-listing-header">Type</span>
          </div>
          <div className="col-3">
            <span className="account-listing-header">Bank</span>
          </div>
          <div className="col-2">
            <span className="account-listing-header">Currency</span>
          </div>
          <div className="col-2 w-100">
            <div className="account-btn" onClick={addAccount}>
              +
            </div>
          </div>
        </div>
        {accounts.length > 0 ? (
          accounts.map((a, i) => (
            <div key={i} className="account-listing">
              <div className="row">
                <div
                  className="col-3"
                  onClick={() => {
                    editAccount(i);
                  }}
                >
                  <span className="listing-text">{a.account_number}</span>
                </div>
                <div
                  className="col-2"
                  onClick={() => {
                    editAccount(i);
                  }}
                >
                  <span className="listing-text">
                    {
                      getAccountTypes()?.find(
                        (type) =>
                          type.id?.toString() === a.account_type?.toString()
                      )?.description
                    }
                  </span>
                </div>
                <div
                  className="col-3"
                  onClick={() => {
                    editAccount(i);
                  }}
                >
                  <span className="listing-text">
                    {
                      getBankBranches()?.find(
                        (bank) =>
                          bank.id?.toString() === a.bank_branch?.toString()
                      )?.bank
                    }
                  </span>
                </div>
                <div
                  className="col-2"
                  onClick={() => {
                    editAccount(i);
                  }}
                >
                  <span className="listing-text">
                    {
                      getCurrencies()?.find((c) => c?.alpha_2 === a?.currency)
                        ?.alpha_3
                    }
                  </span>
                </div>
                <div
                  className="col-2 form-listing-remove-btn"
                  onClick={() => deleteAccount(i)}
                >
                  <BsTrash />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="row">
            <div className="col-12 mt-3" style={{ textAlign: "center" }}>
              <span className="listing-text ">No accounts found</span>
            </div>
          </div>
        )}
      </div>
      {showAccountForm ? (
        <AccountForm
          isShown={showAccountForm}
          close={() => setAccountForm(false)}
          account={selectedAccount}
          addAccount={addAccountSubmitHandler}
          editAccount={editAccountSubmitHandler}
        />
      ) : null}

      {requestIsLoading ? (
        <RequestResponseModal
          isShown={requestIsLoading}
          loader={<Loader />}
          message={"Saving Information"}
          close={() => {
            setRequestIsLoading(false);
          }}
        />
      ) : null}

      {confirmationModal ? (
        <RequestResponseModal
          isShown={confirmationModal}
          success={success}
          message={responseMessage}
          close={() => {
            setConfirmationModal(false);
            getClientAccounts();
          }}
        />
      ) : null}

      {deleteConfirmationModal ? (
        <FormComfirmationModal
          isShown={deleteConfirmationModal}
          message="Are you sure you want to delete account?"
          deleteCallback={deleteAccountSubmitHandler}
          close={() => setDeleteConfirmationModal(false)}
        />
      ) : null}
    </div>
  );
};

AccountListing.propTypes = {
  entity: PropTypes.string,
};

export default AccountListing;
