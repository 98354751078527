//Package Imports
import React, { Component } from "react";
import PropTypes from "prop-types";
//Component Imports
import ClientTable from "../../../components/client-table/client-table";
//Style Imports
import "./all-clients.css";
import Header from "../../../common/header/header";

class AllClients extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Header title="Client View" />
        <ClientTable data={this.props.clients} />
      </>
    );
  }
}

AllClients.propTypes = {
  clients: PropTypes.array,
};

export default AllClients;
