import React, { useContext } from "react";
import "./form-footer.css";
import TradeContext from "../../../../../store/tradeState/tradeContext";

const FormFooter = () => {
  const ctx = useContext(TradeContext);
  return (
    <div className="trade-form-footer">
      <button
        className="btn btn-secondary"
        onClick={() => ctx.setShowTrade(false)}
      >
        Close
      </button>
      <button className="btn btn-success" type="submit">
        Create New Trade
      </button>
    </div>
  );
};

export default FormFooter;
