import React from "react";
import TableForm from "./table-form/table-form";
import TradeViewContainer from "./trade-view/trade-view-container";
import PropTypes from "prop-types";

function TradeContainer(props) {
  return (
    <>
      {props.currentTrade.id ? (
        <TradeViewContainer
          inTrade={props.currentTrade}
          isShown={props.showForm}
          callbackCancel={props.callbackCancel}
          callbackRetry={props.callbackRetry}
          callbackConfirm={props.callbackConfirm}
          callbackShowForm={props.callbackShowForm}
          close={props.closeForm}
        />
      ) : (
        <TableForm
          inTrade={props.currentTrade}
          isShown={props.showForm}
          callbackExecute={props.callbackExecute}
          callbackAdd={props.callbackAdd}
          callbackShowForm={props.callbackShowForm}
          type={"New"}
          close={props.closeForm}
        />
      )}
    </>
  );
}

TradeContainer.propTypes = {
  currentTrade: PropTypes.object,

  showForm: PropTypes.bool,
  closeForm: PropTypes.func,

  callbackExecute: PropTypes.func,
  callbackAdd: PropTypes.func,
  callbackRetry: PropTypes.func,
  callbackCancel: PropTypes.func,
  callbackConfirm: PropTypes.func,
  callbackShowForm: PropTypes.func,
};

export default TradeContainer;
