import React, { useState, useEffect, useRef } from "react";
import ContactForm from "../contact-form/contact-form";
import Loader from "../UI/Loader/Loader";
import PropTypes from "prop-types";
import { BsTrash } from "react-icons/bs";
import {
  RequestResponseModal,
  FormComfirmationModal,
} from "../form-cofirmation-modal/form-confirmation-modal";
import {
  getContactsByEntityID,
  createContact,
  updateContact,
  removeContact,
} from "../../api/clients.api";

const ContactListing = (props) => {
  const [contacts, setContacts] = useState([]);
  const [showContactForm, setContactForm] = useState(false);
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [success, setSuccess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const unmounted = useRef(false);

  useEffect(() => {
    setIsLoading(true);
    getContacts();
    setIsLoading(false);
    return () => {
      unmounted.current = true;
    };
  }, []);

  const getContacts = async () => {
    try {
      const response = await getContactsByEntityID(props.entity);
      if (response) {
        const contacts = response.filter((c) => c.contact_type === 1);

        if (!unmounted.current) setContacts(contacts);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const addContact = () => {
    setSelectedContact({ contact_type: 1 });
    setContactForm(true);
  };

  const editContact = (index) => {
    setSelectedContact(contacts[index]);
    setContactForm(true);
  };

  const deleteContact = (index) => {
    setSelectedContact(contacts[index]);
    setDeleteConfirmation(true);
  };

  const addContactSubmitHandler = async (contact) => {
    setContactForm(false);
    setRequestIsLoading(true);
    try {
      let response = await createContact(contact);
      if (response) {
        setResponseMessage("Contact created successfully!");
        setSuccess(true);
        setRequestIsLoading(false);
        setConfirmationModal(true);
      }
    } catch (error) {
      setResponseMessage(`${error.message}. Please contact system admin.`);
      setSuccess(false);
      setRequestIsLoading(false);
      setConfirmationModal(true);
    }
  };

  const editContactSubmitHandler = async (id, contact) => {
    setContactForm(false);
    setRequestIsLoading(true);
    try {
      let response = await updateContact(id, contact);
      if (response) {
        setResponseMessage("Contact updated successfully!");
        setSuccess(true);
        setRequestIsLoading(false);
        setConfirmationModal(true);
      }
    } catch (error) {
      setResponseMessage(`${error.message}. Please contact system admin.`);
      setSuccess(false);
      setRequestIsLoading(false);
      setConfirmationModal(true);
    }
  };

  const deleteContactSubmitHandler = async () => {
    setDeleteConfirmation(false);
    setRequestIsLoading(true);
    try {
      let response = await removeContact(selectedContact.id);
      if (response) {
        setResponseMessage("Contact removed successfully!");
        setSuccess(true);
        setRequestIsLoading(false);
        setConfirmationModal(true);
      }
    } catch (error) {
      console.log(error);
      setResponseMessage(`${error.message}. Please contact system admin.`);
      setSuccess(false);
      setRequestIsLoading(false);
      setConfirmationModal(true);
    }
  };
  return (
    <div className="card">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-10">
              <h4 className="card-title">Contact Information</h4>
            </div>
            <div className="col-2">
              <div className="account-btn" onClick={addContact}>
                +
              </div>
            </div>
          </div>
          <div className="row w-100">
            {contacts.length > 0 ? (
              contacts?.map((c, i) => (
                <React.Fragment key={i}>
                  <div className="col-11" onClick={() => editContact(i)}>
                    <span className="listing-text">{c.contact}</span>
                  </div>
                  <div
                    className="col-1"
                    onClick={() => {
                      deleteContact(i);
                    }}
                  >
                    <BsTrash
                      style={{ cursor: "pointer", color: "red" }}
                      size={15}
                    />
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div className="col-12 mt-3" style={{ textAlign: "center" }}>
                <span className="listing-text ">No contacts found</span>
              </div>
            )}
          </div>
          {showContactForm ? (
            <ContactForm
              contact={selectedContact}
              isShown={showContactForm}
              entity={props.entity}
              close={() => setContactForm(false)}
              addContact={addContactSubmitHandler}
              editContact={editContactSubmitHandler}
            />
          ) : null}
          {requestIsLoading ? (
            <RequestResponseModal
              isShown={requestIsLoading}
              loader={<Loader />}
              message={"Saving Information"}
              close={() => {
                setRequestIsLoading(false);
              }}
            />
          ) : null}
          {confirmationModal ? (
            <RequestResponseModal
              isShown={confirmationModal}
              success={success}
              message={responseMessage}
              close={() => {
                setConfirmationModal(false);
                getContacts();
              }}
            />
          ) : null}
          {deleteConfirmation ? (
            <FormComfirmationModal
              isShown={deleteConfirmation}
              message="Are you sure you want to delete contact?"
              deleteCallback={deleteContactSubmitHandler}
              close={() => setDeleteConfirmation(false)}
            />
          ) : null}{" "}
        </>
      )}
    </div>
  );
};

ContactListing.propTypes = {
  entity: PropTypes.string,
};

export default ContactListing;
