import React from "react";
import PropTypes from "prop-types";
import { preventStrings } from "../../../../common/helper";
const NumberInput = ({ value, onChange, placeholder }) => {
  return (
    <>
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        value={value}
        onChange={(e) =>
          e.target.value != ""
            ? preventStrings(e.target.value) && onChange(e)
            : onChange(e)
        }
        onBlur={(e) => {
          e.target.value != "" &&
            (e.target.value = Number.parseFloat(value).toFixed(2).toString());
          onChange(e);
        }}
      />
    </>
  );
};

export default NumberInput;

NumberInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};
