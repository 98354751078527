import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { benficiariesSearch } from "../../../api/beneficiaries.api";
import Form from "react-bootstrap/Form";
import { requiredField, isNull } from "../../../common/helper";
import "./entity-beneficiary-form.css";

class EntityBeneficiaryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beneficiary: {
        friendly_name: "",
        client_reference_beneficiary: "",
      },
      listBeneficiaries: [],
      errorArray: [],

      page: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    // this.fetchBeneficiaries();
    this.setState((st) => ({
      beneficiary: { ...st.beneficiary, entity_id: this.props.client.entity },
    }));
    this.fetchDropDownBeneficiaries();
  }

  async fetchDropDownBeneficiaries() {
    let res = await benficiariesSearch("", "", 1, 100, []);
    if (res) {
      this.setState((st) => ({
        beneficiary: { ...st.beneficiary, beneficiary_id: res.results[0].id },
        listBeneficiaries: res.results,
      }));
    }
  }

  handleChange(evt) {
    this.setState((st) => ({
      beneficiary: {
        ...st.beneficiary,
        [evt.target.name]: evt.target.value,
      },
    }));
  }

  formValidation() {
    this.setState({
      errorArray: Object.keys(this.state.beneficiary).map((stateKey) => {
        if (stateKey !== "notes")
          return requiredField(this.state.beneficiary[stateKey], stateKey);
      }),
    });
  }

  render() {
    return (
      <>
        <Modal
          show={this.props.isShown}
          animation={false}
          onHide={() => {
            this.props.close();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Entity Beneficiary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <label className="form-label">Friendly Name*</label>
                <InputGroup className="mb-3">
                  <FormControl
                    name="friendly_name"
                    value={this.state.beneficiary.friendly_name}
                    onChange={this.handleChange}
                    onBlur={(evt) =>
                      requiredField(evt.target.value, evt.target.name)
                    }
                  />
                </InputGroup>
              </div>
              <div className="col-12">
                <label className="form-label">Beneficiary*</label>
                <InputGroup className="mb-3">
                  <Form.Control
                    as="select"
                    name="beneficiary_id"
                    value={this.state.beneficiary.beneficiary_id}
                    onChange={this.handleChange}
                    onBlur={(evt) =>
                      requiredField(evt.target.value, evt.target.name)
                    }
                  >
                    {this.state.listBeneficiaries ? (
                      this.state.listBeneficiaries.map((b, index) => (
                        <option key={index} value={b.id}>
                          {b.name}: {b.currency} -{" "}
                          {b.beneficiary_account_number}
                        </option>
                      ))
                    ) : (
                      <option>Beneficiary</option>
                    )}
                  </Form.Control>
                </InputGroup>
              </div>
              <div className="col-12">
                <label className="form-label">Client Reference*</label>
                <InputGroup className="mb-3">
                  <FormControl
                    name="client_reference_beneficiary"
                    value={this.state.beneficiary?.client_reference_beneficiary}
                    onChange={this.handleChange}
                    onBlur={(evt) =>
                      requiredField(evt.target.value, evt.target.name)
                    }
                  />
                </InputGroup>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={async () => {
                //Check errors before moving foward on form
                await this.formValidation();
                if (isNull(this.state.errorArray.find((d) => d === false)))
                  this.props.next(this.state.beneficiary);
              }}
            >
              Create Entity Beneficiary
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

EntityBeneficiaryForm.propTypes = {
  isShown: PropTypes.bool,
  close: PropTypes.func,
  next: PropTypes.func,
  beneficiary: PropTypes.array,
  client: PropTypes.object,
};
export default EntityBeneficiaryForm;
