import {
  AllowanceTypeApi,
  BankingApi,
  BeneficiariesApi,
  ClientsApi,
} from "axios-cams-api";
import { getCountries } from "../lookups";

let api = new ClientsApi();
let bankingApi = new BankingApi();
let beneficiariesAPI = new BeneficiariesApi();
let allowanceTypeApi = new AllowanceTypeApi();

export const emptyClient = {
  firstname: "",
  surname: "",
  identity_number: "",
  email: "",
  phone: "",
  tax_number: "",
  address: "",
  suburb: "",
  city: "",
  postal_code: "",
  province: "",
  country: getCountries() ? getCountries()[0].name : "South Africa",
  note: "",
  birth: "",
  joint_venture: "",
};

export async function clientSearch(
  param,
  value,
  page,
  rows_per_page,
  sortArray
) {
  try {
    let res = await api.clientsIndividualList(
      value,
      sortArray.toString(),
      page,
      rows_per_page,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("access")}`,
        },
        params: {
          search_fields: param,
        },
      }
    );

    if (res) {
      return res;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function documentUpload(file) {
  const res = await api.clientsFileCreate({
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
    data: file,
  });

  return res;
}

export async function fetchDocumentsByEntityID(entityID, fileType) {
  const res = await api.clientsFileList({
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
    params: {
      entity_id: entityID,
      file_type: fileType,
    },
  });
  return res;
}

export async function createClient(data) {
  let res = await api.clientsIndividualCreate(data, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  return res;
}

export async function createClientAccount(data) {
  let res = await api.clientsEntityAccountCreate(data, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  return res;
}

export async function updateClientAccount(id, data) {
  let res = await bankingApi.bankingAccountsPartialUpdate(id, data, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  return res;
}

export async function getClientAccount(id) {
  let res = await api.clientsEntityAccountList(id, "", "", 1, 0, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  return res.data.results;
}

export async function updateClient(id, data) {
  let res = await api.clientsIndividualPartialUpdate(id, data, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  return res;
}

export async function getBeneficiaryById(id) {
  try {
    let response = await beneficiariesAPI.beneficiariesCommonRead(id, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (response) {
      return response.results;
    }
  } catch (ex) {
    console.log(ex.messge);
  }
}

export async function getContactsByEntityID(id) {
  let res = await api.clientsContactsList(id, 0, 0, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  return res.data.results;
}

export async function createContact(contact) {
  let res = await api.clientsContactsCreate(contact, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  return res;
}

export async function updateContact(id, contact) {
  let res = await api.clientsContactsPartialUpdate(id, contact, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  return res;
}

export async function removeContact(id) {
  let res = await api.clientsContactsDelete(id, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  return res;
}

export async function getAddressByEntityID(id) {
  let res = await api.clientsEntityAddressList(id, 1, 0, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  return res.data.results;
}

export async function createAddress(address) {
  let res = await api.clientsEntityAddressCreate(address, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  return res;
}

export async function updateAddress(id, address) {
  let res = await api.clientsAddressesPartialUpdate(id, address, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  return res;
}

export async function removeAddress(id) {
  let res = await api.clientsAddressesDelete(id, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  return res;
}

export async function getClientBalance(id) {
  let res = await bankingApi.bankingAccountsRead(id, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  return res;
}

export async function getClientBalanceByAllowanceType(type, entity) {
  let res = await allowanceTypeApi.allowanceTypeSdaRead(
    entity,
    new Date().getFullYear(),
    {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    }
  );
  return res.data;
}
