import React, { Component } from "react";
import PropTypes from "prop-types";
import "./loader.css";

class Loader extends Component {
  render() {
    return (
      <div className="lds-ring">
        <div
          style={{
            borderTopColor: this.props.color,
            borderRightColor: "transparent",
            borderLeftColor: "transparent",
            borderBottomColor: "transparent",
          }}
        ></div>
        <div
          style={{
            borderTopColor: this.props.color,
            borderRightColor: "transparent",
            borderLeftColor: "transparent",
            borderBottomColor: "transparent",
          }}
        ></div>
        <div
          style={{
            borderTopColor: this.props.color,
            borderRightColor: "transparent",
            borderLeftColor: "transparent",
            borderBottomColor: "transparent",
          }}
        ></div>
        <div
          style={{
            borderTopColor: this.props.color,
            borderRightColor: "transparent",
            borderLeftColor: "transparent",
            borderBottomColor: "transparent",
          }}
        ></div>
      </div>
    );
  }
}

Loader.propTypes = {
  color: PropTypes.string,
};

export default Loader;
