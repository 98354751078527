import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import "./client-form.css";
import Form from "react-bootstrap/Form";
import {
  getCountries,
  callGetProvinces,
  getJVPartners,
} from "../../../lookups";

import {
  preventNumber,
  preventStrings,
  requiredField,
  isNull,
} from "../../../common/helper";

class ClientForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: this.props.client,
      accountForm: false,
      editAccountForm: false,
      allocatedAccount: {},
      accountIndex: null,
      countries: [],
      provinces: [],
      errorArray: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleStringChange = this.handleStringChange.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.formValidation = this.formValidation.bind(this);
    this.showAccountForm = this.showAccountForm.bind(this);
    this.closeAccountForm = this.closeAccountForm.bind(this);
    this.addAccount = this.addAccount.bind(this);
    this.updateAccount = this.updateAccount.bind(this);
    this.removeAccount = this.removeAccount.bind(this);
    this.showEditAccountForm = this.showEditAccountForm.bind(this);
    this.closeEditAccountForm = this.closeEditAccountForm.bind(this);
  }

  componentDidMount() {
    this.getProvinces();
  }

  addAccount(account) {
    this.setState(
      (st) => ({
        client: {
          ...st.client,
          accounts: [...this.state.client.accounts, account],
        },
      }),
      () => {
        this.closeAccountForm();
      }
    );
  }

  updateAccount(index, account) {
    let accounts = [...this.state.client.accounts];

    accounts[index] = account;

    this.setState(
      (st) => ({
        client: {
          ...st.client,
          accounts: accounts,
        },
      }),
      () => {
        this.closeEditAccountForm();
      }
    );
  }

  removeAccount(index) {
    let accounts = this.state.client.accounts;
    accounts.splice(index, 1);
    this.setState((st) => ({
      client: {
        ...st.client,
        accounts: accounts,
      },
    }));
  }

  showAccountForm() {
    this.setState({
      accountForm: true,
    });
  }

  showEditAccountForm(i) {
    this.setState((st) => ({
      accountIndex: i,
      allocatedAccount: st.client.accounts[i],
      editAccountForm: true,
    }));
  }

  closeEditAccountForm() {
    this.setState({
      allocatedAccount: {},
      editAccountForm: false,
    });
  }

  closeAccountForm() {
    this.setState({
      accountForm: false,
    });
  }

  handleChange(evt) {
    this.setState((st) => ({
      client: {
        ...st.client,
        [evt.target.name]: evt.target.value,
      },
    }));
  }

  handleCountryChange(evt) {
    this.setState(
      (st) => ({
        client: {
          ...st.client,
          country: evt.target.value,
        },
      }),
      () => {
        this.getProvinces();
      }
    );
  }

  async getProvinces() {
    let provs = await callGetProvinces(440);
    if (provs)
      this.setState((st) => ({
        client: {
          ...st.client,
          province: provs[0]?.name,
        },
        provinces: provs,
      }));
  }

  handleStringChange(evt) {
    if (preventNumber(evt.target.value) || evt.target.value === "")
      this.setState((st) => ({
        client: {
          ...st.client,
          [evt.target.name]: evt.target.value,
        },
      }));
  }

  handleNumberChange(evt) {
    if (preventStrings(evt.target.value) || evt.target.value === "")
      this.setState((st) => ({
        client: {
          ...st.client,
          [evt.target.name]: evt.target.value,
        },
      }));
  }

  inputValidation(evt) {
    if (evt.target.name !== "note")
      requiredField(evt.target.value, evt.target.name);
  }

  formValidation() {
    this.setState({
      errorArray: Object.keys(this.state.client)?.map((stateKey) => {
        return requiredField(this.state.client[stateKey], stateKey);
      }),
    });
  }

  render() {
    return (
      <>
        <Modal
          className={
            this.state.accountForm
              ? "client-modal model-hidden"
              : "client-modal"
          }
          animation={false}
          show={this.props.isShown}
          onHide={() => {
            this.props.close();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Client</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <label>Personal Informatrion</label>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-12">
                    <label className="form-label">First Name*</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        name="firstname"
                        value={this.state.client?.firstname}
                        onChange={this.handleStringChange}
                        onBlur={this.inputValidation}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-12">
                    <label className="form-label">Surname*</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        name="surname"
                        value={this.state.client?.surname}
                        onChange={this.handleStringChange}
                        onBlur={this.inputValidation}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-7">
                    <label className="form-label">Email Address*</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        name="email"
                        type="email"
                        value={this.state.client?.email}
                        onChange={this.handleChange}
                        onBlur={this.inputValidation}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-5">
                    <label className="form-label">Date of Birth*</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        name="birth"
                        type="date"
                        value={this.state.client?.birth}
                        onChange={this.handleChange}
                        onBlur={this.inputValidation}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-12">
                    <label className="form-label">Contact Number*</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        name="phone"
                        value={this.state.client?.phone}
                        onChange={this.handleChange}
                        onBlur={this.inputValidation}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-12">
                    <label className="form-label">Identity Number*</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        name="identity_number"
                        onChange={this.handleNumberChange}
                        value={this.state.client?.identity_number}
                        onBlur={this.inputValidation}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="row">
                  <div className="col-6">
                    <label className="form-label">JV Partner</label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="select"
                        name="joint_venture"
                        value={this.state.client?.joint_venture}
                        onChange={this.handleChange}
                        onBlur={this.inputValidation}
                      >
                        <option value={""}>-- Select Partner --</option>
                        {getJVPartners()?.map((c, index) => (
                          <option key={index} value={c.id}>
                            {c.long_name}
                          </option>
                        ))}
                      </Form.Control>
                    </InputGroup>
                  </div>
                  <div className="col-6">
                    <label className="form-label">TAX Number*</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        name="tax_number"
                        onChange={this.handleNumberChange}
                        value={this.state.client?.tax_number}
                        onBlur={this.inputValidation}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-12">
                    <label className="form-label">Physical Address*</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        name="address"
                        as="textarea"
                        className="add-note-textarea"
                        onChange={this.handleChange}
                        onBlur={this.inputValidation}
                        value={this.state.client?.address}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-3">
                    <label className="form-label">Postal Code*</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        name="postal_code"
                        onChange={this.handleNumberChange}
                        onBlur={this.inputValidation}
                        value={this.state.client?.postal_code}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-4">
                    <label className="form-label">Suburb*</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        name="suburb"
                        onChange={this.handleChange}
                        onBlur={this.inputValidation}
                        value={this.state.client?.suburb}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-5">
                    <label className="form-label">City*</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        name="city"
                        onChange={this.handleChange}
                        onBlur={this.inputValidation}
                        value={this.state.client?.city}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-6">
                    <label className="form-label">Country*</label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="select"
                        name="country"
                        value={this.state.client?.country}
                        onChange={this.handleCountryChange}
                        onBlur={this.inputValidation}
                      >
                        {getCountries()?.map((c, index) => (
                          <option key={index} value={c.name}>
                            {c.name}
                          </option>
                        ))}
                      </Form.Control>
                    </InputGroup>
                  </div>
                  <div className="col-6">
                    <label className="form-label">Province*</label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="select"
                        name="province"
                        onChange={this.handleChange}
                        value={this.state.client?.province?.name}
                        onBlur={this.inputValidation}
                      >
                        {this.state.provinces ? (
                          this.state.provinces?.map((p, i) => (
                            <option key={i} value={p.name}>
                              {p.name}
                            </option>
                          ))
                        ) : (
                          <option>No Provinces Found</option>
                        )}
                      </Form.Control>
                    </InputGroup>
                  </div>

                  {/* <div className="col-12">
                    <label className="form-label">
                      Define Client&apos;s Initial Spread
                    </label>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup.Prepend>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Pips</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder="Start Typing"
                        aria-label="Start Typing"
                        aria-describedby="basic-addon1"
                        name="default_pip"
                        onChange={this.handleChange}
                        value={this.state.client.default_pip}
                      />
                    </InputGroup>
                  </div> */}

                  <div className="col-12">
                    <label className="form-label">Add Note</label>
                    <InputGroup className="mb-3">
                      <FormControl
                        className="add-note-textarea"
                        name="note"
                        as="textarea"
                        value={this.state.client?.note}
                        placeholder="start your note here"
                        onChange={this.handleChange}
                        onBlur={this.inputValidation}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.props.close();
              }}
            >
              Back
            </Button>
            <Button
              variant="success"
              onClick={async () => {
                //Check errors before moving foward on form
                await this.formValidation();
                if (isNull(this.state.errorArray.find((d) => d === false))) {
                  this.props.next(this.state.client, "confirm");
                }
              }}
            >
              Create New Client
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

ClientForm.propTypes = {
  showForm: PropTypes.func,
  isShown: PropTypes.bool,
  close: PropTypes.func,
  next: PropTypes.func,
  client: PropTypes.object,
};

export default ClientForm;
