import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  capitalizeFirstLetter,
  handleStatusStyle,
} from "../../../../../../common/helper";

class StatusInfoHeader extends Component {
  render() {
    return (
      <>
        <div className="row seperator">
          <div className="col-7">
            <label className="formlabel">
              Status
              <span
                className={
                  "statusOval " + handleStatusStyle(this.props.status.name)
                }
              >
                {capitalizeFirstLetter(this.props.status.name)}
              </span>
            </label>
          </div>
          <div className="col-5 flexCol">
            <label className="formlabel">Information</label>
            <p className="description">All in order</p>
          </div>
        </div>
      </>
    );
  }
}

StatusInfoHeader.propTypes = {
  status: PropTypes.object,
};

export default StatusInfoHeader;
