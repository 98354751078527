import React, { Component } from "react";
import PropTypes from "prop-types";
import "./notes.css";
import { AiOutlineLeft } from "react-icons/ai";
import AddNotes from "./add-notes/add-notes";
import { getNotesByID, addNotes } from "../../api/trades.api";
import Loader from "../loader/loader";
import { EmptyState } from "../empty-state/empty-state";

class NoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addNote: false,
      note: "",
      isOpen: false,
      isLoading: false,
      notes: [],
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchNotes = this.fetchNotes.bind(this);
  }

  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.fetchNotes();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  async fetchNotes() {
    this.setState({ isLoading: true }, () => {
      getNotesByID(
        this.props.client_id ? this.props.client_id : ``,
        this.props.trade_id ? this.props.trade_id : ``
      ).then((result) => {
        if (result) {
          this.setState({ notes: result.data.results, isLoading: false });
        } else {
          this.setState({ isLoading: false });
        }
      });
    });
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (this.props.showNotes) this.props.showModal();
    }
  }

  handleChange(evt) {
    this.setState({
      note: evt.target.value,
    });
  }

  async createNote(data) {
    let res = await addNotes(data);
    //TODO: Add validation for textbox
    //After note creation refresh trades.
    if (res) {
      this.fetchNotes();
      this.props.tradeSearch();
    }
  }

  render() {
    return (
      <div
        className={`notes-modal ${this.props.showNotes ? "show-notes" : ""}`}
        ref={this.wrapperRef}
      >
        <div
          className={
            this.props.showNotes ? "notes-modal-visble" : "notes-modal-hidden"
          }
        >
          <div className="notes-modal-header row">
            <div
              className="col-1"
              onClick={() => {
                this.props.showModal();
              }}
            >
              <span className="notes-modal-back">
                {" "}
                <AiOutlineLeft></AiOutlineLeft>
              </span>
            </div>
            <div className="col-11">
              <span className="notes-modal-title">Notes</span>
            </div>
          </div>
          <div className="notes-modal-client-name">
            <div className="col-11 offset-1">
              <span className="notes-modal-client-name-title">
                {this.props.client ? this.props.client : "No Client Name"}
              </span>
            </div>
          </div>
          <div className="notes-modal-body-wrapper">
            {this.state.isLoading ? (
              <span className="no-note">
                <Loader />
                <br />
                Fetching notes...
              </span>
            ) : this.state.notes.length > 0 ? (
              this.state.notes.map((n) => (
                <div key={n.created_at} className="notes-modal-body">
                  <div className="offset-1 col-11">
                    <span className="note-title">
                      {n.created_at.substring(10, -1)} - {n.username}
                    </span>
                    <span className="note-body">{n.note}</span>
                  </div>
                </div>
              ))
            ) : (
              <EmptyState
                message={
                  "There are no notes here. Click the button below to add new notes"
                }
              />
            )}
          </div>
          <div className="notes-modal-footer">
            {this.state.addNote ? (
              <AddNotes handleChange={this.handleChange} />
            ) : (
              <></>
            )}
            <button
              className="notes-modal-btn"
              onClick={() => {
                this.setState((st) => ({
                  addNote: !st.addNote,
                }));

                if (this.state.addNote) {
                  this.createNote({
                    note: this.state.note,
                    trade: this.props.trade_id ? this.props.trade_id : null,
                    entity: this.props.client_id,
                  });
                  this.fetchNotes();
                }
              }}
            >
              {this.state.addNote ? "Confrim" : "Add Note"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

NoteModal.propTypes = {
  showNotes: PropTypes.bool,
  showModal: PropTypes.func,
  outerRef: PropTypes.object,
  trade_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  client: PropTypes.string,
  client_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tradeSearch: PropTypes.func,
};

export default NoteModal;
