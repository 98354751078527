import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SocketsContext from "./socketsContext";

const SocketsProvider = ({ children }) => {
  const [modelUpdated, setModelUpdated] = useState([]);
  const callbackFunction = (callback) => {
    callback();
  };
  let ws = new WebSocket(
    process.env.REACT_APP_SOCKET_URL + "socket/notification/"
  );

  useEffect(() => {
    setupSocket();
    ws.onclose = async () => {
      await setupSocket();
    };
    return () => {};
  }, []);

  const setupSocket = () => {
    ws.onopen = () => {};

    ws.onmessage = (evt) => {
      const message = JSON.parse(evt.data);
      if (message) {
        console.log("Message!", message);
        const tmpArray = modelUpdated;
        tmpArray.push(message.text);
        setModelUpdated(tmpArray);
      }
      return false;
    };
  };

  return (
    <SocketsContext.Provider value={{ modelUpdated, callbackFunction }}>
      {children}
    </SocketsContext.Provider>
  );
};

SocketsProvider.propTypes = {
  children: PropTypes.node,
};

export default SocketsProvider;
