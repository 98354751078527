import { AuthApi } from "axios-cams-api";

const api = new AuthApi();

const options = {
  method: "POST",
};

export async function obtainToken(body) {
  let res = api.authLoginCreate(body, options);
  return res;
}

export async function verifyOTP(body) {
  let res = api.authVerifyOtpCreate(body, options);
  return res;
}

export async function refreshToken(body) {
  let res = api.authTokenRefreshCreate(body, options);
  return res;
}
