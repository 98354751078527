import React, { useState, useEffect } from "react";
import TradeTracker from "../../../../trade-tracker/trade-tracker";

import {
  capitalizeFirstLetter,
  handleStatusStyle,
} from "../../../../../common/helper";
import PropTypes from "prop-types";
import { getStatusList } from "../../../../../lookups";

import StatusInfoHeader from "./status-info-header/status-info-header";

function TradeView(props) {
  const [statusData, setStatusData] = useState([]);

  useEffect(() => {
    setStatusData(getStatusList());
  });

  const handleProgressStyle = () => {
    return handleStatusStyle(props.inTrade.status.name);
  };

  return (
    <>
      <StatusInfoHeader statusdata={statusData} status={props.inTrade.status} />
      <div className="row seperator">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <label className="form-label">Summary</label>
            </div>
            <div className="col-6">
              <span className="view-label">
                {props.inTrade.trade_reference_number}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <label className="form-label">Trade Amount</label>
                </div>
                <div className="col-6">
                  <span className="view-label">
                    {props.inTrade.trade_currency.symbol}
                    {props.inTrade.trade_amount}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="form-label">Exc. Rate</label>
                </div>
                <div className="col-6">
                  <span className="view-label">
                    {props.inTrade.exchange_rate}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="form-label">FX amount</label>
                </div>
                <div className="col-6">
                  <span className="view-label">
                    {props.inTrade.fx_currency.symbol}
                    {props.inTrade.fx_amount}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="form-label">BOP Code</label>
                </div>
                <div className="col-6">
                  <span className="view-label">{props.inTrade.bop_code}</span>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <label className="form-label">Trade Type</label>
                </div>
                <div className="col-6">
                  <span className="view-label">
                    {props.inTrade.allowance_type}
                  </span>
                </div>
                <div className="col-6">
                  <label className="form-label">Trade Date</label>
                </div>
                <div className="col-6">
                  <span className="view-label">{props.inTrade.trade_date}</span>
                </div>
                <div className="col-6">
                  <label className="form-label">Bank</label>
                </div>
                <div className="col-6">
                  <span className="view-label">
                    {props.inTrade.bank.display_name}
                  </span>
                </div>
                <div className="col-6">
                  <label className="form-label">Beneficiary</label>
                </div>
                <div className="col-6">
                  <span className="view-label">
                    {props.inTrade.beneficiary}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <TradeTracker
            status={props.inTrade.status}
            bgColor={handleProgressStyle()}
            levelColor={handleProgressStyle()}
          />
        </div>
      </div>
      <div
        className="row seperator"
        style={{ marginTop: "5px", paddingBottom: "20px" }}
      >
        <div className="col-12">
          <label className="formlabel">
            Trade Tracker
            <span className={"statusOval " + handleProgressStyle()}>
              {capitalizeFirstLetter(props.inTrade.status.name)}
            </span>
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-12 flexCol">
          <label>Attachments</label>
          {props.inTrade.documents && props.inTrade.documents.length > 0 ? (
            props.inTrade.documents.map((t) => {
              <span className="fileList">{t.filename}</span>;
            })
          ) : (
            <span className="fileList">No files</span>
          )}
        </div>
      </div>
    </>
  );
}

TradeView.propTypes = {
  inTrade: PropTypes.object,
  data: PropTypes.array,
  addTrade: PropTypes.func,
  updateTradeStatus: PropTypes.func,
  executeTrade: PropTypes.func,
  close: PropTypes.func,
  isShown: PropTypes.bool,
};

export default TradeView;
