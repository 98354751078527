import React, { useEffect, useState } from "react";
import {
  fetchDocumentsByEntityID,
  documentUpload,
} from "../../../../../api/clients.api";
import DragAndDrop from "../../../../drag-and-drop/drag-and-drop";
import FileUpload from "../../../../file-upload/file-upload";
import { getDocumentTypes } from "../../../../../lookups";

function NotesAndDocuments() {
  const [docIsOpen, setIsDocOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [fileType, setFileType] = useState(getDocumentTypes()[0].id);

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = async () => {
    try {
      const response = await fetchDocumentsByEntityID(null, null);
      console.log("Documents call respoonse", response);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadDocuments = async () => {
    let fileUploadData = new FormData();
    fileUploadData.append("file", documents[0]);
    fileUploadData.append("file_type", "11");
    fileUploadData.append("entity_id", "1");
    const response = documentUpload(fileUploadData);
    console.log("request response", response);
    setIsDocOpen(false);
    setDocuments([]);
  };

  const handleDrop = (file) => {
    setDocuments((arr) => [...arr, file[0]]);
  };

  return (
    <div className="row mb-1 pt-2 pb-3">
      <div className="col-7">
        {docIsOpen ? (
          <DragAndDrop
            handleDrop={handleDrop}
            fileType={fileType}
            setFileType={(e) => setFileType(e.target.value)}
          />
        ) : (
          <>
            <label className="form-label">Add note</label>
            <div className="input-group">
              <textarea
                rows="4"
                className="form-control add-note-textarea"
                placeholder="start your note here"
              ></textarea>
            </div>
          </>
        )}
      </div>
      <div className="col-5">
        <FileUpload
          documents={documents}
          uploadDocuments={
            docIsOpen ? () => uploadDocuments() : () => setIsDocOpen(true)
          }
        />
      </div>
    </div>
  );
}

export default NotesAndDocuments;
