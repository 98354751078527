import { TradesApi, RpaApi, NotesApi } from "axios-cams-api";

export const emptyTrade = {
  bank: "",
  customer: {
    id: 0,
    name: "",
    type: "FIA",
    balance: 0.0,
    balance_timestamp: new Date(),
  },
  value_date: "",
  trade_amount: "",
  trade_rate: "",
  fx_amount: "",
  fx_currency: { alpha_2: "US", alpha_3: "USD", name: "United States" },
  dealer_id: 1,
  jv_partner: "",
  balance: "",
  trade_currency: {
    alpha_2: "US",
    alpha_3: "USD",
    name: "United States",
  },
  documents: [],
  txn_type: "outbound",
  bop_code: "",
  status: {
    code: "",
    name: "",
    isError: false,
  },
  notes: "",
  pip_value: 0,
};

let api = new TradesApi();
let rpaApi = new RpaApi();
let notesApi = new NotesApi();

export async function tradeBatchRetry(tradeArray) {
  let trades = [];
  tradeArray.forEach((id) => {
    trades.push({ trade_id: id, message: "", retry_success: false });
  });
  try {
    let res = await rpaApi.rpaRetryCreate(
      { trades: trades },
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("access")}`,
        },
      }
    );
    return res;
  } catch (error) {
    return error;
  }
}

export async function tradeSearch(
  param,
  value,
  page,
  rows_per_page,
  sortArray
) {
  try {
    let res = await api.tradesList(
      sortArray?.toString(),
      value.toLowerCase(),
      page,
      rows_per_page,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("access")}`,
        },
        params: {
          search_fields: param,
        },
      }
    );
    if (res) {
      return res;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function createTrade(data) {
  let res = await api.tradesCreate(data, {
    headers: {
      Authorization: `JWT ${sessionStorage.getItem("access")}`,
    },
  });
  if (res) return res;
}

export async function getNotesByID(entityID, tradeID) {
  try {
    let res = await notesApi.notesList(entityID, tradeID, 1, 10, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      return res;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function addNotes(data) {
  try {
    let res = await notesApi.notesCreate(data, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      return res;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function updateNote(id, data) {
  try {
    let res = await notesApi.notesUpdate(id, data, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) return res;
  } catch (ex) {
    console.log(ex);
  }
}
