import React from "react";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/login/login";
import AppLayout from "./layout/applayout";
import { NotFound } from "./pages/404";
import { ProtectedRoute } from "./auth/protected.routes";
import { createGlobalStyle } from "styled-components";
import OTPLogin from "./pages/otp/otp";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'HelveticaNeueBold';
    src: url("${process.env.REACT_APP_FONT_URL}/HelveticaNeueLTStd-Bd.otf")
  }
  
  @font-face {
    font-family: 'HelveticaNeueBoldItalic';
    src: url("${process.env.REACT_APP_FONT_URL}/HelveticaNeueLTStd-BdIt.otf")
  }
  
  @font-face {
    font-family: 'HelveticaNeueMedium';
    src: url("${process.env.REACT_APP_FONT_URL}/HelveticaNeueLTStd-Md.otf")
  }
  @font-face {
    font-family: 'HelveticaNeueMediumItalic';
    src: url("${process.env.REACT_APP_FONT_URL}/HelveticaNeueLTStd-MdIt.otf")
  }
  
  @font-face {
    font-family: 'HelveticaNeueRegular';
    src: url("${process.env.REACT_APP_FONT_URL}/HelveticaNeueLTStd-Roman.otf")
  }
`;

function App() {
  return (
    <div className="App">
      <GlobalStyles />
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={(routeProps) => <Login {...routeProps} />}
          />

          <Route
            exact
            path="/otp"
            render={(routeProps) => <OTPLogin {...routeProps} />}
          />
          {/* Dashboard Route */}
          <ProtectedRoute exact path="/dashboard" component={AppLayout} />
          {/* Trade Routes */}
          <ProtectedRoute exact path="/trades/trades" component={AppLayout} />

          <ProtectedRoute
            exact
            path="/trades/market-watch"
            component={AppLayout}
          />
          <ProtectedRoute
            exact
            path="/trades/current-market-orders"
            component={AppLayout}
          />
          <ProtectedRoute
            exact
            path="/trades/my-trades"
            component={AppLayout}
          />
          <ProtectedRoute
            exact
            path="/trades/cancelled-trades"
            component={AppLayout}
          />
          {/* Client View Routes */}
          <ProtectedRoute
            exact
            path="/client-view/all-clients"
            component={AppLayout}
          />
          <ProtectedRoute
            exact
            path="/client-view/client-edit/:id"
            component={AppLayout}
          />
          <ProtectedRoute
            exact
            path="/client-view/common-beneficiaries"
            component={AppLayout}
          />
          <ProtectedRoute
            exact
            path="/client-view/individuals"
            component={AppLayout}
          />
          <ProtectedRoute
            exact
            path="/client-view/companies"
            component={AppLayout}
          />

          {/* Reporting Routes */}
          <ProtectedRoute
            exact
            path="/reporting/current-trade-report"
            component={AppLayout}
          />
          <ProtectedRoute
            exact
            path="/reporting/current-market-orders"
            component={AppLayout}
          />
          <ProtectedRoute
            exact
            path="/reporting/monthly-trade-report"
            component={AppLayout}
          />
          <ProtectedRoute
            exact
            path="/reporting/invoices"
            component={AppLayout}
          />

          <Route exact path="*" render={() => <NotFound />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
