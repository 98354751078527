import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import useInputState from "../../hooks/useInputState";
import { getCountries, callGetProvinces } from "../../lookups";
import { isNotEmpty } from "../../utils/inputValidationRules";

const AddressForm = (props) => {
  const { raw, suburb, city, country_id, province_id, code } =
    props.address.address;

  const formType = props.address.id ? "Edit" : "New";

  const [provinces, setProvinces] = useState([]);

  const {
    value: rawValue,
    isValid: rawIsValid,
    hasError: rawHasError,
    valueChangeHandler: rawChangeHandler,
    inputBlurHandler: rawBlurHandler,
  } = useInputState(isNotEmpty, raw ? raw : "");

  const {
    value: suburbValue,
    isValid: suburbIsValid,
    hasError: suburbHasError,
    valueChangeHandler: suburbChangeHandler,
    inputBlurHandler: suburbBlurHandler,
  } = useInputState(isNotEmpty, suburb?.name ? suburb?.name : "");

  const {
    value: cityValue,
    isValid: cityIsValid,
    hasError: cityHasError,
    valueChangeHandler: cityChangeHandler,
    inputBlurHandler: cityBlurHandler,
  } = useInputState(isNotEmpty, city?.name ? city?.name : "");

  const {
    value: countryValue,
    isValid: countryIsValid,
    hasError: countryHasError,
    valueChangeHandler: countryChangeHandler,
    inputBlurHandler: countryBlurHandler,
  } = useInputState(isNotEmpty, country_id ? country_id : "");

  const {
    value: provinceValue,
    isValid: provinceIsValid,
    hasError: provinceHasError,
    valueChangeHandler: provinceChangeHandler,
    inputBlurHandler: provinceBlurHandler,
  } = useInputState(isNotEmpty, province_id ? province_id : "");

  const {
    value: codeValue,
    isValid: codeIsValid,
    hasError: codeHasError,
    valueChangeHandler: codeChangeHandler,
    inputBlurHandler: codeBlurHandler,
  } = useInputState(isNotEmpty, code ? code : "");

  useEffect(() => {
    const getProvinces = async () => {
      let res = await callGetProvinces(440);
      setProvinces(res ? res : []);
    };
    getProvinces();
  }, [country_id]);

  let formIsValid = false;

  if (
    rawIsValid &&
    suburbIsValid &&
    cityIsValid &&
    countryIsValid &&
    provinceIsValid &&
    codeIsValid
  ) {
    formIsValid = true;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formIsValid) {
      return;
    }

    const addressObject = {
      address: {
        raw: rawValue,
        suburb: { name: suburbValue },
        city: { name: cityValue },
        country_id: countryValue,
        province_id: parseInt(provinceValue),
        code: codeValue,
      },
    };

    formType === "New"
      ? props.addAddress(addressObject)
      : props.editAddress(addressObject);
  };

  const rawClasses = rawHasError
    ? "cams-form-control invalid"
    : "cams-form-control";
  const suburbClasses = suburbHasError
    ? "cams-form-control invalid"
    : "cams-form-control";
  const cityClasses = cityHasError
    ? "cams-form-control invalid"
    : "cams-form-control";
  const countryClasses = countryHasError
    ? "cams-form-control invalid"
    : "cams-form-control";
  const provinceClasses = provinceHasError
    ? "cams-form-control invalid"
    : "cams-form-control";
  const codeClasses = codeHasError
    ? "cams-form-control invalid"
    : "cams-form-control";

  return (
    <Modal
      show={props.isShown}
      onHide={() => {
        props.close();
      }}
      animation={false}
    >
      <Modal.Header>
        <Modal.Title>{formType === "New" ? "Add" : "Edit"} Address</Modal.Title>
      </Modal.Header>
      <form onSubmit={submitHandler}>
        <Modal.Body>
          <div className="row">
            <div className={`col-12 ${rawClasses}`}>
              <label className="form-label">Physical Address*</label>
              <textarea
                type="textarea"
                className="add-note-textarea"
                value={rawValue}
                onChange={rawChangeHandler}
                onBlur={rawBlurHandler}
              />
            </div>
            <div className={`col-3 ${codeClasses}`}>
              <label className="form-label">Postal Code*</label>
              <input
                type="text"
                value={codeValue}
                onChange={codeChangeHandler}
                onBlur={codeBlurHandler}
              />
            </div>
            <div className={`col-4 ${suburbClasses}`}>
              <label className="form-label">Suburb*</label>
              <input
                type="text"
                value={suburbValue}
                onChange={suburbChangeHandler}
                onBlur={suburbBlurHandler}
              />
            </div>
            <div className={`col-5 ${cityClasses}`}>
              <label className="form-label">City*</label>
              <input
                type="text"
                value={cityValue}
                onChange={cityChangeHandler}
                onBlur={cityBlurHandler}
              />
            </div>
            <div className={`col-6 ${countryClasses}`}>
              <label className="form-label">Country*</label>
              <select
                value={countryValue}
                onChange={countryChangeHandler}
                onBlur={countryBlurHandler}
              >
                <option value={""}>-- Select Country --</option>
                {getCountries()?.map((c, index) => (
                  <option key={index} value={c.country_id}>
                    {c.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={`col-6 ${provinceClasses}`}>
              <label className="form-label">Province*</label>
              <select
                value={provinceValue}
                onChange={provinceChangeHandler}
                onBlur={provinceBlurHandler}
              >
                <option value={""}>-- Select province --</option>
                {provinces ? (
                  provinces?.map((p, i) => (
                    <option key={i} value={p.id}>
                      {p.name}
                    </option>
                  ))
                ) : (
                  <option>No Provinces Found</option>
                )}
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={() => props.close()}>
            Back
          </button>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={!formIsValid}
          >
            {`${formType === "New" ? "Add" : "Edit"} Address`}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

AddressForm.propTypes = {
  isShown: PropTypes.bool,
  address: PropTypes.object,
  addAddress: PropTypes.func,
  editAddress: PropTypes.func,
  close: PropTypes.func,
  entity: PropTypes.string,
};

export default AddressForm;
