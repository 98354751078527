//Package Imports
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../auth/auth-context";
import { obtainToken } from "../../api/auth.api";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Loader from "../../components/loader/loader";
import CALogo from "../../assets/CA_logo.png";
import { isNotEmpty } from "../../utils/inputValidationRules";
import useInputState from "../../hooks/useInputState";
//Style Imports
import "./login.css";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);

  const history = useHistory();

  const {
    value: usernameValue,
    isValid: usernameValid,
    hasError: usernameHasError,
    valueChangeHandler: usernameChangeHandler,
    inputBlurHandler: usernameBlurHandler,
  } = useInputState(isNotEmpty, "");

  const {
    value: passwordValue,
    isValid: passwordValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
  } = useInputState(isNotEmpty, "");

  let formIsValid = false;

  if (usernameValid && passwordValid) {
    formIsValid = true;
  }

  const usernameClasses = usernameHasError
    ? "cams-form-control invalid"
    : "cams-form-control";
  const passwordClasses = passwordHasError
    ? "cams-form-control invalid"
    : "cams-form-control";

  const formLogin = async () => {
    setIsLoading(true);
    if (!formIsValid) {
      return;
    }
    try {
      let loginResponse = await obtainToken({
        username: usernameValue,
        password: passwordValue,
      });

      if (loginResponse.status != 200 && loginResponse.status != 201) {
        setIsLoading(false);
        setErrorMessage(loginResponse.statusText || "Something went wrong");
        return;
      }

      authCtx.login(
        loginResponse.data.correlation_key,
        loginResponse.data.sent_to,
        loginResponse.data.message
      );

      setIsLoading(false);
      history.replace("/otp");
    } catch (error) {
      console.log("Login Res", error.response);
      setErrorMessage(error.response.data.non_field_errors);
      setIsLoading(false);
    }
  };

  return (
    <div id="page" className="login">
      <div className="frm">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="formCard">
            <img src={CALogo} alt="CA_Logo" className="mb-3" />
            <div className="row mb-3">
              <div className={`col-12 ${usernameClasses}`}>
                <label>Username*</label>
                <input
                  type="text"
                  value={usernameValue}
                  onChange={usernameChangeHandler}
                  onBlur={usernameBlurHandler}
                />
              </div>

              <div className={`col-12 ${passwordClasses}`}>
                <label>Password*</label>
                <input
                  type="password"
                  value={passwordValue}
                  onChange={passwordChangeHandler}
                  onBlur={passwordBlurHandler}
                />
              </div>
            </div>
            {errorMessage === "" ? null : (
              <div className="messageDisplay">
                <span className="errMsg">{errorMessage}</span>
              </div>
            )}
            <Button disabled={!formIsValid} onClick={formLogin}>
              Login
            </Button>
            <br />
          </div>
        )}
      </div>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object,
};

export default Login;
