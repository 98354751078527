import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { FaCalendarAlt } from "react-icons/fa";
import { BiChevronDown } from "react-icons/bi";
import { DateRangePicker } from "react-date-range";

function RangeCalendar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [dateSelection, setDateSelection] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const ref = useRef(null);

  const handleClickOutside = (evt) => {
    if (ref && !ref.current.contains(evt.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    props.onChange(dateSelection);
  }, [dateSelection]);

  return (
    <div ref={ref} className="date-picker-input-wrapper">
      <div className="date-picker-input" onClick={() => setIsOpen(!isOpen)}>
        <FaCalendarAlt />
        <input
          type="text"
          readOnly
          placeholder="Choose Date Range"
          className="date-picker-placehoder-input"
          value={`${dateSelection.startDate.getDate()}/${
            dateSelection.startDate.getMonth() + 1
          }/${dateSelection.startDate.getFullYear()} - ${dateSelection.endDate.getDate()}/${
            dateSelection.endDate.getMonth() + 1
          }/${dateSelection.endDate.getFullYear()}`}
        />
        <div className="date-picker-input-chevron">
          <BiChevronDown />
        </div>
      </div>
      {isOpen && (
        <DateRangePicker
          className="date-picker-range-component"
          ranges={[dateSelection]}
          onChange={(ranges) => {
            setDateSelection({
              ...dateSelection,
              startDate: ranges.selection.startDate,
              endDate: ranges.selection.endDate,
            });
            if (ranges.selection.startDate != ranges.selection.endDate)
              setIsOpen(false);
          }}
        />
      )}
    </div>
  );
}

RangeCalendar.propTypes = {
  onChange: PropTypes.func,
};

export default RangeCalendar;
