import React from "react";
import useInputState from "../../hooks/useInputState";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { isNotEmpty } from "../../utils/inputValidationRules";

import { getBankBranches, getAccountTypes, getCurrencies } from "../../lookups";

const AccountForm = (props) => {
  const { id, account_number, account_type, bank_branch, currency } =
    props.account;

  const formType = id ? "Edit" : "New";

  const {
    value: accountNumberValue,
    isValid: accountNumberIsValid,
    hasError: accountNumberHasError,
    valueChangeHandler: accountNumberChangeHandler,
    inputBlurHandler: accountNumberBlurHandler,
  } = useInputState(isNotEmpty, account_number ? account_number : "");

  const {
    value: accountTypeValue,
    isValid: accountTypeIsValid,
    hasError: accountTypeHasError,
    valueChangeHandler: accountTypeChangeHandler,
    inputBlurHandler: accountTypeBlurHandler,
  } = useInputState(isNotEmpty, account_type ? account_type : "");

  const {
    value: bankBranchValue,
    isValid: bankBranchIsValid,
    hasError: bankBranchHasError,
    valueChangeHandler: bankBranchChangeHandler,
    inputBlurHandler: bankBranchBlurHandler,
  } = useInputState(isNotEmpty, bank_branch ? bank_branch : "");

  const {
    value: currencyValue,
    isValid: currencyValueIsValid,
    hasError: currencyHasError,
    valueChangeHandler: currencyChangeHandler,
    inputBlurHandler: currencyBlurHandler,
  } = useInputState(isNotEmpty, currency ? currency : "");

  let formIsValid = false;

  if (
    accountNumberIsValid &&
    accountTypeIsValid &&
    bankBranchIsValid &&
    currencyValueIsValid
  ) {
    formIsValid = true;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formIsValid) {
      return;
    }

    let accountObject = {};
    formType === "New"
      ? (accountObject = {
          account: {
            bank_branch: bankBranchValue,
            account_number: accountNumberValue,
            account_type: accountTypeValue,
            currency: currencyValue,
          },
        })
      : (accountObject = {
          bank_branch: bankBranchValue,
          account_number: accountNumberValue,
          account_type: accountTypeValue,
          currency: currencyValue,
        });

    formType === "New"
      ? props.addAccount(accountObject)
      : props.editAccount(props.account.id, accountObject);
  };

  const accountNumberClasses = accountNumberHasError
    ? "cams-form-control invalid"
    : "cams-form-control";
  const accountTypeClasses = accountTypeHasError
    ? "cams-form-control invalid"
    : "cams-form-control";
  const bankBranchClasses = bankBranchHasError
    ? "cams-form-control invalid"
    : "cams-form-control";
  const currencyClasses = currencyHasError
    ? "cams-form-control invalid"
    : "cams-form-control";

  return (
    <>
      <Modal
        show={props.isShown}
        onHide={() => {
          props.close();
        }}
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>
            {formType === "New" ? "Add New Account" : "Edit Account"}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={submitHandler}>
          <Modal.Body>
            <div className="row">
              <div className={`col-12 ${accountNumberClasses}`}>
                <label className="form-label">Account Number*</label>
                <input
                  type="text"
                  value={accountNumberValue}
                  onChange={accountNumberChangeHandler}
                  onBlur={accountNumberBlurHandler}
                />
              </div>
              <div className={`col-12 ${accountTypeClasses}`}>
                <label className="form-label">Account Type*</label>
                <select
                  value={accountTypeValue}
                  onChange={accountTypeChangeHandler}
                  onBlur={accountTypeBlurHandler}
                >
                  <option value={""}>-- Select Account Type --</option>
                  {getAccountTypes()?.map((type, index) => (
                    <option key={index} value={type.id}>
                      {type.description}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`col-12 ${bankBranchClasses}`}>
                <label className="form-label">Bank Branch*</label>
                <select
                  value={bankBranchValue}
                  onChange={bankBranchChangeHandler}
                  onBlur={bankBranchBlurHandler}
                >
                  <option value={""}>-- Select Bank Branch --</option>
                  {getBankBranches()?.map((bank, index) => (
                    <option key={index} value={bank.id}>
                      {bank.name}: {bank.bank} - {bank.branch_code}
                    </option>
                  ))}
                </select>
              </div>
              <div className={`col-12 ${currencyClasses}`}>
                <label className="form-label">Currency*</label>
                <select
                  value={currencyValue}
                  onChange={currencyChangeHandler}
                  onBlur={currencyBlurHandler}
                >
                  <option value={""}>-- Select currency --</option>
                  {getCurrencies()?.map((currency, index) => (
                    <option key={index} value={currency.alpha_2}>
                      {currency.alpha_2} ({currency.alpha_3}) {currency.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                props.close();
              }}
            >
              Back
            </Button>
            <button
              className="btn btn-primary"
              disabled={!formIsValid}
              type="submit"
            >{`${formType === "New" ? "Add" : "Edit"} Account`}</button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

AccountForm.propTypes = {
  isShown: PropTypes.bool,
  close: PropTypes.func,
  addAccount: PropTypes.func,
  editAccount: PropTypes.func,
  account: PropTypes.object,
  entity: PropTypes.string,
};

export default AccountForm;
