import React from "react";
import PropTypes from "prop-types";

function ClientInfoHeader() {
  return (
    <div className="row seperator">
      <div className="col-7">
        <label className="formlabel">{this.props.clientName}</label>
      </div>
      <div className="col-5 flexCol">
        <label className="formlabel">Information</label>
        <p className="description">{this.props.clientInformation}</p>
      </div>
    </div>
  );
}

ClientInfoHeader.propTypes = {
  clientName: PropTypes.string,
  clientInformation: PropTypes.string,
};

export default ClientInfoHeader;
