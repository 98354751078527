import React, { useContext } from "react";
import "./notification-panel.css";
import * as MdIcons from "react-icons/md";
import NotificationCard from "../notificaiton-card/notification-card";
import NotificationContext from "../../store/notificationsState/notificationContext";

const NotificationPanel = () => {
  const notiCtx = useContext(NotificationContext);
  return (
    <>
      <div className="notification-panel">
        <div className="notification-panel-header">
          <h1 className="title">Notifications</h1>
          <MdIcons.MdClose
            className="icon"
            onClick={() => notiCtx.setShowNotifications(false)}
          />
        </div>
        <div className="notification-panel-body">
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
        </div>
      </div>
      <div className="modal-backdrop show"></div>
    </>
  );
};

export default NotificationPanel;
