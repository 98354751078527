import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import "./trade-information.css";
import { getEntityBeneficiaryById } from "../../../../../api/beneficiaries.api";
import { getClientBalanceByAllowanceType } from "../../../../../api/clients.api";
import TradeContext from "../../../../../store/tradeState/tradeContext";
import { getBopCodes, getCurrencies } from "../../../../../lookups";
import NumberInput from "../../../../UI/Inputs/NumberInput/NumberInput";

function TradeInformation({ pip }) {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [pips, setPips] = useState(true);
  const [sda, setSDA] = useState(true);
  const [currentAllowance, setCurrentAllowance] = useState();
  const [fetchingAllowance, setFechingAllowance] = useState(false);
  const [bopCode, setBopCode] = useState();
  const [bopSubCode, setBopSubCode] = useState();
  const [bopCodes, setBopCodes] = useState([]);
  const [bopSubCodes, setBopSubCodes] = useState([]);

  const unmounted = useRef(false);
  const ctx = useContext(TradeContext);

  useEffect(() => {
    getBeneficiaries();
    setBopCode(getBopCodes()[0].code);
    setBopSubCodes(getBopCodes()[0].sub_codes);
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    ctx.trade.entity && getAllowance();
    return () => {
      unmounted.current = true;
    };
  }, [ctx.trade.entity, sda]);

  useEffect(() => {
    ctx.trade.entity && getSubCodes();
    return () => {
      unmounted.current = true;
    };
  }, [bopCode]);

  const getSubCodes = () => {
    const subCodes = getBopCodes().find((c) => c.code === bopCode);
    if (subCodes) setBopSubCodes(subCodes.sub_codes);

    setBopSubCode(
      subCodes
        ? subCodes[0]?.sub_code
        : [{ code: "00", description: "no code found" }]
    );
  };

  const getBeneficiaries = async () => {
    try {
      const response = await getEntityBeneficiaryById(1);
      if (response && !unmounted.current) setBeneficiaries(response.results);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAllowance = async () => {
    setFechingAllowance(true);
    try {
      const response = await getClientBalanceByAllowanceType(
        sda ? "SDA" : "FIA",
        ctx.trade.entity
      );
      if (response && !unmounted.current) setCurrentAllowance(response.results);

      setFechingAllowance(false);
    } catch (error) {
      console.log("Error", error);
      setFechingAllowance(false);
    }
  };

  return (
    <div className="row pt-2 pb-4 modal-section">
      <div className="col-6">
        <label className="form-label">Client Spread</label>
        <div className="input-group">
          <div
            role="button"
            className={`input-group-prepend ${!pips ? "prepend-active" : ""}`}
            onClick={() => setPips(false)}
          >
            <span className="input-group-text">%</span>
          </div>
          <div
            role="button"
            className={`input-group-prepend ${pips ? "prepend-active" : ""}`}
            onClick={() => setPips(true)}
          >
            <span className="input-group-text">Pips</span>
          </div>
          <NumberInput
            value={ctx.trade.spread}
            placeholder=""
            onChange={(e) =>
              ctx.setTrade({ ...ctx.trade, spread: e.target.value })
            }
          />
          <input
            type="text"
            className="form-control"
            placeholder="Current Value"
            readOnly
            value={pip}
          />
        </div>
      </div>
      <div className="col-6">
        <label className="form-label">Allowance Type w/Balance</label>
        <div className="input-group">
          <div
            role="button"
            className={`input-group-prepend ${sda ? "prepend-active" : ""}`}
            onClick={() => setSDA(true)}
          >
            <span className="input-group-text">SDA</span>
          </div>
          <div
            role="button"
            className={`input-group-prepend ${!sda ? "prepend-active" : ""}`}
            onClick={() => setSDA(false)}
          >
            <span className="input-group-text">FIA</span>
          </div>
          <input
            type="text"
            className="balance-field form-control"
            value={
              fetchingAllowance
                ? "Fetching allowance..."
                : currentAllowance
                ? currentAllowance
                : "No results"
            }
            readOnly
          />
        </div>
      </div>

      <div className="col-5">
        <label className="form-label">Trade Amount</label>
        <div className="input-group">
          <select className="col-2 max-27 form-control">
            {getCurrencies().map((c) => (
              <option key={c.alpha_3}>{c.alpha_3}</option>
            ))}
          </select>
          <NumberInput
            value={ctx.trade.trade_amount}
            onChange={(e) =>
              ctx.setTrade({ ...ctx.trade, trade_amount: e.target.value })
            }
          />
        </div>
      </div>
      <div className="col-5">
        <label className="form-label">FX Amount</label>
        <div className="input-group">
          <select className="col-2 max-27 form-control">
            {getCurrencies().map((c) => (
              <option key={c.alpha_3}>{c.alpha_3}</option>
            ))}
          </select>
          <NumberInput
            value={ctx.trade.fx_amount}
            onChange={(e) =>
              ctx.setTrade({ ...ctx.trade, fx_amount: e.target.value })
            }
          />
        </div>
      </div>
      <div className="col-2">
        <label className="form-label">Exc. Rate</label>
        <NumberInput
          value={ctx.trade.exchange_rate}
          onChange={(e) =>
            ctx.setTrade({ ...ctx.trade, exchange_rate: e.target.value })
          }
        />
      </div>
      <div className="col-6">
        <label className="form-label">Beneficiaries</label>
        <select
          className="form-control"
          onChange={(e) =>
            ctx.setTrade({ ...ctx.trade, beneficiary: e.target.value })
          }
        >
          {beneficiaries.length > 0 ? (
            beneficiaries.map((b) => (
              <option key={b.id} value={b.id}>
                {`${b.friendly_name} - ${b.client_reference_beneficiary}`}
              </option>
            ))
          ) : (
            <option>No beneficiaries found</option>
          )}
        </select>
      </div>
      <div className="col-6">
        <label className="form-label">BOP Code</label>
        <div className="input-group">
          <select
            className="form-control"
            onChange={(e) => {
              setBopCode(e.target.value);
            }}
          >
            {getBopCodes().map((c, i) => (
              <option key={i} value={c.code}>
                {c.code} - {c.category}
              </option>
            ))}
          </select>
          <select className="form-control">
            {bopSubCodes?.map((sc, i) => (
              <option key={i} value={sc.sub_code}>
                {sc.sub_code} - {sc.description}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

TradeInformation.propTypes = {
  pip: PropTypes.string,
  spread: PropTypes.string,
  spreadChangeHandler: PropTypes.func,
  tradeAmount: PropTypes.string,
  tradeAmountChangeHandler: PropTypes.func,
};

export default TradeInformation;
