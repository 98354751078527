import React, { Component } from "react";
import PropTypes from "prop-types";
import "./add-notes.css";

class AddNotes extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="col-10 offset-1 mb-1">
        <textarea
          className="form-control add-note-textarea"
          name="notes"
          rows="4"
          onChange={(evt) => {
            this.props.handleChange(evt);
          }}
        />
      </div>
    );
  }
}

AddNotes.propTypes = {
  handleChange: PropTypes.func,
};

export default AddNotes;
