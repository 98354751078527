import React from "react";
import { useState } from "react";
import NotificationContext from "./notificationContext";
import PropTypes from "prop-types";

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        showNotifications,
        setShowNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node,
};

export default NotificationProvider;
