import React from "react";
import PropTypes from "prop-types";
import * as FaIcons from "react-icons/fa";
import "./vs-button.css";

function VSButton({
  link,
  title,
  subtitle,
  bgColor,
  headerColor,
  subheaderColor,
  buttonColor,
}) {
  return (
    <a className="vs-button-link" href={link}>
      <div className="vs-button" style={{ backgroundColor: bgColor }}>
        <div className="vs-button-content">
          <h4 className="vs-button-title" style={{ color: headerColor }}>
            {title}
          </h4>

          <span
            className="vs-button-subtitle"
            style={{ color: subheaderColor }}
          >
            {subtitle}
          </span>
          <span
            className="vs-button-cta"
            style={{ backgroundColor: buttonColor }}
          >
            <FaIcons.FaArrowRight className="vs-button-icon" />
          </span>
        </div>
      </div>
    </a>
  );
}

VSButton.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
    PropTypes.object,
  ]),
  schemeColor: PropTypes.string,
  bgColor: PropTypes.string,
  subheaderColor: PropTypes.string,
  headerColor: PropTypes.string,
  buttonColor: PropTypes.string,
  subtitle: PropTypes.string,
  link: PropTypes.string,
};

export default VSButton;
