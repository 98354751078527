import React from "react";
import PropTypes from "prop-types";
import "./Loader.css";

const Loader = (props) => {
  return (
    <div className="lds-ring">
      <div
        style={{
          borderTopColor: props.color,
          borderRightColor: "transparent",
          borderLeftColor: "transparent",
          borderBottomColor: "transparent",
        }}
      ></div>
      <div
        style={{
          borderTopColor: props.color,
          borderRightColor: "transparent",
          borderLeftColor: "transparent",
          borderBottomColor: "transparent",
        }}
      ></div>
      <div
        style={{
          borderTopColor: props.color,
          borderRightColor: "transparent",
          borderLeftColor: "transparent",
          borderBottomColor: "transparent",
        }}
      ></div>
      <div
        style={{
          borderTopColor: props.color,
          borderRightColor: "transparent",
          borderLeftColor: "transparent",
          borderBottomColor: "transparent",
        }}
      ></div>
    </div>
  );
};

Loader.propTypes = {
  color: PropTypes.string,
};

export default Loader;
