import React, { useEffect, useState, useRef, useContext } from "react";
import TradeContext from "../../../../../store/tradeState/tradeContext";
import {
  clientSearch,
  getClientAccount,
  getClientBalance,
} from "../../../../../api/clients.api";

function ClientDealerInformation() {
  const [customers, setCustomers] = useState([]);
  const [customerAccounts, setCustomerAccounts] = useState([]);
  const [customerBalance, setCustomerBalance] = useState({});

  const unmounted = useRef(false);

  const ctx = useContext(TradeContext);

  useEffect(() => {
    getCustomerList();
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    getCustomerAccounts();
  }, [ctx.trade.entity]);

  useEffect(() => {
    ctx.trade.entity && retrieveCustomerBalance();
  }, [ctx.trade.entity, ctx.trade.account_number]);

  const getCustomerList = async () => {
    try {
      const response = await clientSearch("", "", 1, 0, []);
      if (response) {
        if (!unmounted.current) {
          setCustomers(response.data.results);
          ctx.setTrade({
            ...ctx.trade,
            customer: {
              ...ctx.customer,
              id: response.data.results[0].entity,
            },
            entity: response.data.results[0].entity,
          });
          console.log("Setting entity");
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const getCustomerAccounts = async () => {
    try {
      const response = await getClientAccount(ctx.trade.entity);
      if (response) {
        const accounts = response.map((a) => {
          return a.account;
        });
        setCustomerAccounts(accounts);
        ctx.setTrade({
          ...ctx.trade,
          account_number: accounts.length > 0 ? accounts[0].account_number : "",
          account_currency: accounts.length > 0 ? accounts[0].currency : "ZAR",
        });
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const retrieveCustomerBalance = async () => {
    try {
      const response = await getClientBalance(
        parseInt(ctx.trade.account_number)
      );
      setCustomerBalance(response.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-6">
          {/* Todo: Format customer object to object with new customer id */}
          <label className="form-label">Customer Name</label>
          <select
            value={ctx.trade.customer.id}
            onChange={(e) => {
              ctx.setTrade({
                ...ctx.trade,
                customer: { ...ctx.customer, id: e.target.value },
                entity: e.target.value,
              });
            }}
            className="form-control"
          >
            {customers.length > 0 ? (
              customers.map((c) => (
                <option key={c.id} value={c.entity}>
                  {`${c.firstname} ${c.surname}`}
                </option>
              ))
            ) : (
              <option>No customers returned</option>
            )}
          </select>
        </div>
        <div className="col-6">
          <label className="form-label">Client Account</label>
          <select
            value={ctx.trade.account_number}
            onChange={(e) =>
              ctx.setTrade({
                ...ctx.trade,
                account_number: e.target.value,
                account_currency: customerAccounts.find(
                  (ca) => ca.id == e.target.value
                )?.currency,
              })
            }
            className="form-control"
          >
            {customerAccounts.length > 0 ? (
              customerAccounts.map((a) => (
                <option key={a.id} value={a.id}>
                  {`${a.account_number}`}
                </option>
              ))
            ) : (
              <option>No accounts returned</option>
            )}
          </select>
        </div>
      </div>
      <div className="row modal-section">
        <div className={`col-4`}>
          <label className="form-label">Customer balance</label>
          <div className="input-group">
            <input
              className="col-2 max-27 form-control"
              type="text"
              readOnly
              value={ctx.trade.account_currency}
            />
            <input
              type="text"
              readOnly={true}
              className="balance-field form-control"
              value={customerBalance.balance}
              onChange={(e) =>
                setCustomerBalance({
                  ...customerBalance,
                  balance: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col-3">
          <label className="form-label">Balance as of</label>
          <input
            type="text"
            readOnly
            className="balance-field form-control"
            value={
              customerBalance.balance_timestamp
                ? customerBalance.balance_timestamp
                : new Date().toLocaleDateString()
            }
            onChange={(e) =>
              setCustomerBalance({
                ...customerBalance,
                balance_timestamp: e.target.value,
              })
            }
          />
        </div>
        <div className="col-5">
          <button
            className="btn btn-primary balance-btn"
            onClick={(e) => {
              e.preventDefault();
              retrieveCustomerBalance();
            }}
          >
            Retrieve Latest Balance
          </button>
        </div>
      </div>
    </div>
  );
}

export default ClientDealerInformation;
