//Package Imports
import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
//Component Imports
import Header from "../../../common/header/header";
import Search from "../../../components/search/search";
// import TradeTable from "../../../components/trade-table/trade-table";
import TradeTable from "../../../components/trade-table-fc/trade-table";
import Pagination from "../../../components/pagination/pagination";
import ToastNotifcation from "../../../components/toast/toast";
//Provider Import
import TradeContext from "../../../store/tradeState/tradeContext";

//Style Imports
import "./trades.css";
import TradeButtonContainer from "../../../components/trade-button-container/trade-button-container";
import TradeContainer from "../../../components/trade-table/trade/trade-container";
import NoteModal from "../../../components/notes/notes";

const searchOptions = [
  { name: "Trade Number", value: "trade_reference_number" },
  { name: "Account", value: "account_number" },
  { name: "Date", value: "value_date" },
  { name: "Status", value: "status__name" },
  { name: "Type", value: "allowance_type" },
  { name: "Exchange Rate", value: "exchange_rate" },
];

const Trades = () => {
  const trdCtx = useContext(TradeContext);

  const handleChange = (e) => {
    console.log(e.target.id);
    e.target.id === "searchFilter"
      ? trdCtx.setSearchParam(e.target.value)
      : trdCtx.setSearchValue(e.target.value);
  };

  return (
    <div className="flex flexCol w-full">
      <Header title="Trades" />
      <ToastNotifcation
        showToast={trdCtx.showToast}
        closeToast={() => trdCtx.setShowToast(false)}
        toastResult={trdCtx.toastResult}
        toastMessage={trdCtx.toastMessage}
      />
      <Search
        options={searchOptions}
        handleChange={handleChange}
        searchCallback={() => console.log("use effect will search")}
        resetPage={() => trdCtx.setPage(1)}
        searchFilter={trdCtx.searchParam}
        searchText={trdCtx.searchValue}
      />
      {/* <TradeButtonContainer /> */}
      <TradeTable />
      <div className="row mt-2 table-footer">
        <div className="col-8 card flex-row">
          <span>Hot Keys:</span>
          <span>Shift + N (New Trade)</span>
          <span>Shift + B (Retry Batch)</span>
        </div>
        <div className="col-4 card bg-w">
          <Pagination
            handleChange={(e) => trdCtx.setRowsPerPage(e.target.value)}
            search={() => {}}
            rows_per_page={trdCtx.rowsPerPage}
            resetPage={() => trdCtx.setPage(1)}
            page={trdCtx.page}
            page_count={trdCtx.pageCount}
            //upper_threshold={this.state.upper_threshold}
            //lower_threshold={this.state.lower_threshold}
            pageUp={() =>
              trdCtx.setPage(
                trdCtx.page != trdCtx.pageCount ? trdCtx.page + 1 : trdCtx.page
              )
            }
            pageDown={() =>
              trdCtx.setPage(trdCtx.page != 1 ? trdCtx.page - 1 : trdCtx.page)
            }
          />
        </div>
      </div>
      {trdCtx.showNotes && (
        <NoteModal
          showNotes={trdCtx.showNotes}
          showModal={() => {
            trdCtx.setShowNotes(!trdCtx.showNotes);
          }}
          id={trdCtx.trade.id}
          trade_id={trdCtx.trade.id}
          client_id={trdCtx.trade.customer.id}
          client={trdCtx.trade.customer.name}
          tradeSearch={() => trdCtx.getTrades()}
          searchField="trade"
        />
      )}
      {trdCtx.showTrade && (
        <TradeContainer
          currentTrade={trdCtx.trade}
          showForm={trdCtx.showTrade}
          callbackShowForm={() => trdCtx.setShowTrade(true)}
          closeForm={() => trdCtx.setShowTrade(false)}
          callbackExecute={() => console.log("Execute Trade")}
          callbackCancel={() => console.log("Cancel Trade")}
          callbackAdd={() => console.log("Add Trade")}
          callbackRetry={() => {
            trdCtx.setShowTrade(false);
            trdCtx.retryTrades([trdCtx.trade.id]);
          }}
        />
      )}
    </div>
  );
};

Trades.propTypes = {
  trades: PropTypes.array,
  add: PropTypes.func,
  updateStatus: PropTypes.func,
  executeTrade: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  newTrades: PropTypes.bool,
  tradeSearch: PropTypes.func,
};

export default Trades;
