import React, { Component } from "react";
import PropTypes from "prop-types";
import "./trade-view-button-container.css";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";

const popover = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">
      The Trade cannot be cancelled manually
    </Popover.Title>
    <Popover.Content>
      Have you contacted Investec Help Desk (### ### ####) and cancellled the
      trade manually?
    </Popover.Content>
  </Popover>
);

const errorGroup = [16, 17, 18, 19];

class TradeViewButtonContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  render() {
    return (
      <>
        <div className="tvbc-left-button-group">
          <button
            className="btn btn-secondary"
            onClick={() => {
              this.props.close();
            }}
          >
            Close
          </button>
          <OverlayTrigger
            show={this.state.show}
            key={"top"}
            placement={"top"}
            overlay={popover}
          >
            <Button
              variant="secondary"
              onMouseEnter={() => {
                this.setState({ show: true });
              }}
              onMouseLeave={() => {
                this.setState({ show: false });
              }}
            >
              Cancel Trade
            </Button>
          </OverlayTrigger>
        </div>
        <div className="tvbc-right-button-group">
          {this.props.code === 16 ? (
            <button
              className="btn btn-success"
              onClick={() => {
                this.props.clientView();
              }}
            >
              Client View
            </button>
          ) : (
            <></>
          )}

          {this.props.code === 22 ? (
            <button
              className="btn btn-primary"
              onClick={() => {
                this.props.retryDeal();
              }}
            >
              Retry Trade
            </button>
          ) : (
            <></>
          )}

          {errorGroup.find((code) => code === this.props.code) ? (
            this.props.code != 16 ? (
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.props.mailSupport();
                }}
              >
                Mail Support
              </button>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {/* {errorGroup.find((code) => code === this.props.code) ? (
            this.props.code != 16 ? (
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.props.retryDeal();
                }}
              >
                Retry Deal
              </button>
            ) : (
              <></>
            )
          ) : (
            <></>
          )} */}

          {errorGroup.find((code) => code === this.props.code) ? (
            <button
              className="btn btn-primary"
              onClick={() => {
                this.props.tryLater();
              }}
            >
              Try Later
            </button>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

TradeViewButtonContainer.propTypes = {
  code: PropTypes.number,
  close: PropTypes.func,
  back: PropTypes.func,
  cancelTrade: PropTypes.func,
  clientView: PropTypes.func,
  retry: PropTypes.func,
  mailSupport: PropTypes.func,
  tryLater: PropTypes.func,
  retryDeal: PropTypes.func,
};

export default TradeViewButtonContainer;
