import { ACTIONS } from "../../actions/common/input.actions";

const inputStateReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.INPUT:
      return { value: action.value, isTouched: state.isTouched };
    case ACTIONS.BLUR:
      return { isTouched: true, value: state.value };
    case ACTIONS.RESET:
      return { isTouched: false, value: "" };
  }
  return inputStateReducer;
};

export default inputStateReducer;
