import React from "react";
import PropTypes from "prop-types";
import "./notification-card.css";

const NotificationCard = ({ bgColor }) => {
  return (
    <div className="notification-card">
      <div
        className="notification-card-block"
        style={{ backgroundColor: bgColor }}
      ></div>
      <div className="notification-card-text">
        <span>
          Your Investec trade #394584 couldn&0027t be confirmed. Please contact
          them telephonically to confirm the trade - 011 789 4654
        </span>
        <p className="noticiation-card-text-time">3m ago</p>
      </div>
    </div>
  );
};

NotificationCard.propTypes = {
  bgColor: PropTypes.string,
};

NotificationCard.defaultProps = {
  bgColor: "#DB4748",
};

export default NotificationCard;
