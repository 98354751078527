import React, { Component } from "react";
import PropTypes from "prop-types";
import "./toast.css";
import Toast from "react-bootstrap/Toast";
import $ from "jquery";

class ToastNotifcation extends Component {
  componentDidMount() {
    $(window).scroll(function () {
      var $el = $(".toast-wrapper");
      var isPositionFixed = $el.css("position") == "fixed";
      if ($(this).scrollTop() > 200 && !isPositionFixed) {
        $el.css({ position: "fixed", top: "0px", right: "20px" });
      }
      if ($(this).scrollTop() < 200 && isPositionFixed) {
        $el.css({ position: "static", top: "0px", right: "20px" });
      }
    });
  }

  render() {
    return (
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
        }}
      >
        <div className="toast-wrapper">
          <Toast
            show={this.props.showToast}
            onClose={() => {
              this.props.closeToast();
            }}
            className={`toast ${
              this.props.toastResult === false ? "error-toast" : "success-toast"
            }
            `}
            autohide
          >
            <Toast.Header></Toast.Header>
            <Toast.Body>
              <strong>{`${
                this.props.toastResult ? "Sucess" : "Failed"
              }! `}</strong>
              {this.props.toastMessage}
            </Toast.Body>
          </Toast>
        </div>
      </div>
    );
  }
}

ToastNotifcation.propTypes = {
  showToast: PropTypes.bool,
  closeToast: PropTypes.func,
  toastResult: PropTypes.string,
  toastMessage: PropTypes.string,
};

export default ToastNotifcation;
