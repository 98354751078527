import React, { useState, useContext } from "react";
import CALogo from "../../assets/CA_logo.png";
import { verifyOTP } from "../../api/auth.api";
import AuthContext from "../../auth/auth-context";
import "./otp.css";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const OTPLogin = () => {
  const [otp, setOTP] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const OTPverify = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let response = await verifyOTP({
        correlation_key: authCtx.correlation_key,
        otp: otp.trim(),
      });

      if (response.status != 200 && response.status != 201) {
        setIsLoading(false);
        setErrorMessage(
          response.data.otp || response.statusText || "Something went wrong"
        );
        return;
      }

      authCtx.verifyOTP(
        response.data.access_token,
        response.data.access_token_expiration,
        response.data.refresh_token,
        response.data.refresh_token_expiration,
        response.data.access_time,
        response.data.user.username,
        response.data.user.groups
      );
      setIsLoading(false);
      history.replace("/dashboard");
    } catch (error) {
      setErrorMessage(
        error.response.data.non_field_errors ||
          error.response.data.otp ||
          "Something went wrong"
      );
      setIsLoading(false);
      //console.log("Verify OTP Error", error);
    }
  };

  return (
    <div id="page" className="login">
      <div className="frm">
        <form className="formCard" onSubmit={OTPverify}>
          <img src={CALogo} alt="CA_Logo" className="mb-3" />
          <div className="row mb-3">
            <div className="col-12">
              <label>OTP sent to {authCtx.send_to}</label>
            </div>
            <div className="col-12 cams-form-control">
              <input
                type="text"
                placeholder="Enter OTP here"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
              />
            </div>
          </div>
          {errorMessage === "" ? null : (
            <div className="messageDisplay">
              <span className="errMsg">{errorMessage}</span>
            </div>
          )}
          <Button type="submit">Verify OTP</Button>
        </form>
      </div>
    </div>
  );
};

export default OTPLogin;
