export const Statuses = {
  start: "start",
  in_progress: "in progress",
  funds_recieved: "funds received",
  mandates_received: "mandates received",
  rate_confirmed: "rate confirmed",
  bop_confirmed: "bop confirmed",
  trade_confirmed: "trade confirmed",
  notification_received: "notification received",
  trade_executed_received: "trade executed received",
  bop_completed: "bop completed",
  bop_uploaded: "bop uploaded",
  bank_query: "bank query",
  bank_checks_sent: "bank checks sent",
  bank_completed: "bank completed",
  notification_sent: "notification sent",
  end: "end",
  missing_client_information: "missing client information",
  missing_client_files: "missing client files",
  timeout: "timeout",
  http_error: "http error",
  unknown_error: "unknown error",
  manual_intervention: "manual intervention",
  created: "created",
  bop_files_found: "bop files found",
  bop_files_missing: "bop files missing",
  trade_completed: "trade completed",
  awaiting_authorisation: "awaiting authorisation",
  bop_files_required: "bop files required",
  documents_required: "documents required",
  insufficient_balance: "insufficient balance",
  fia_pin_not_found: "FIA PIN not found",
};

export function handleStatusStyle(status) {
  switch (status) {
    case Statuses.start:
      return "start";
    case Statuses.in_progress:
      return "in_progress";
    case Statuses.funds_recieved:
      return "funds_recieved";
    case Statuses.mandates_received:
      return "mandates_received";
    case Statuses.rate_confirmed:
      return "rate_confirmed";
    case Statuses.bop_confirmed:
      return "bop_confirmed";
    case Statuses.trade_confirmed:
      return "trade_confirmed";
    case Statuses.notification_received:
      return "notification_received";
    case Statuses.trade_executed_received:
      return "trade_executed_received";
    case Statuses.bop_completed:
      return "bop_completed";
    case Statuses.bop_uploaded:
      return "bop_uploaded";
    case Statuses.bank_query:
      return "bank_query";
    case Statuses.bank_checks_sent:
      return "bank_checks_sent";
    case Statuses.bank_completed:
      return "bank_completed";
    case Statuses.notification_sent:
      return "notification_sent";
    case Statuses.end:
      return "end";
    case Statuses.missing_client_information:
      return "missing_client_information";
    case Statuses.missing_client_files:
      return "missing_client_files";
    case Statuses.timeout:
      return "timeout";
    case Statuses.http_error:
      return "http_error";
    case Statuses.unknown_error:
      return "unknown_error";
    case Statuses.manual_intervention:
      return "manual_intervention";
    case Statuses.created:
      return "created";
    case Statuses.bop_files_found:
      return "bop_files_found";
    case Statuses.bop_files_missing:
      return "bop_files_missing";
    case Statuses.trade_completed:
      return "trade_completed";
    case Statuses.awaiting_authorisation:
      return "awaiting_authorisation";
    case Statuses.bop_files_required:
      return "bop_files_required";
    case Statuses.documents_required:
      return "documents_required";
    case Statuses.insufficient_balance:
      return "insufficient_balance";
    case Statuses.fia_pin_not_found:
      return "fia_pin_not_found";
    default:
      return "start";
  }
}

export function numberFormat(x) {
  if (x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
  }
  return x;
}

export function nonEmptyField(val) {
  let isValid = false;
  if (val === null || !val) {
    isValid = true;
  } else if (val.trim() === "") {
    isValid = true;
  } else {
    isValid = false;
  }
  return isValid;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function stringInputValidation(val) {
  let isValid = false;
  if (!val) {
    isValid = false;
  } else if (!val.match(/^[a-zA-Z]+$/)) {
    isValid = false;
  }
  return isValid;
}

export function currencyInputValidation(val) {
  let isValid = false;
  if (!val) {
    isValid = false;
  } else if (!val.match(/^[0-9]+$/)) {
    isValid = false;
  }
  return isValid;
}

export function preventStrings(val) {
  return /^[0-9*,.]+$/.test(val);
}

export function preventNumber(val) {
  return /^[a-zA-Z-]+$/.test(val);
}

export function isNull(val) {
  if (val === undefined || val === null) {
    return true;
  } else {
    return false;
  }
}

export function requiredField(val, name) {
  if (val.toString().trim() === "" || val === 0 || val === 0.0) {
    let elem = document.getElementsByName(name);
    if (elem[0]) {
      elem[0].style.borderColor = "red";
    }
    return false;
  }
  {
    if (document.getElementsByName(name)[0]) {
      document.getElementsByName(name)[0].style.borderColor = "#ced4da";
      return true;
    }
  }
}

export function urltoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function exchangeRateBlur(fxAmount, tradeAmount, exchangeRate) {
  let obj = {
    fxAmount: fxAmount,
    tradeAmount: tradeAmount,
    exchangeRate: exchangeRate,
  };

  if (
    notNullOrEmpty(fxAmount) &&
    notNullOrEmpty(tradeAmount) &&
    notNullOrEmpty(exchangeRate)
  ) {
    obj = {
      fxAmount: tradeAmount * fxAmount,
      tradeAmount: tradeAmount,
      exchangeRate: exchangeRate,
    };
  } else if (notNullOrEmpty(tradeAmount) && notNullOrEmpty(exchangeRate)) {
    obj = {
      fxAmount: tradeAmount * fxAmount,
      tradeAmount: tradeAmount,
      exchangeRate: exchangeRate,
    };
  } else if (notNullOrEmpty(fxAmount) && notNullOrEmpty(exchangeRate)) {
    obj = {
      fxAmount: fxAmount,
      tradeAmount: fxAmount / exchangeRate,
      exchangeRate: exchangeRate,
    };
  } else {
    // do nothing
  }
  return obj;
}

export function tradeAmountBlur(fxAmount, tradeAmount, exchangeRate) {
  let obj = {
    fxAmount: fxAmount,
    tradeAmount: tradeAmount,
    exchangeRate: exchangeRate,
  };

  if (
    notNullOrEmpty(fxAmount) &&
    notNullOrEmpty(tradeAmount) &&
    notNullOrEmpty(exchangeRate)
  ) {
    obj = {
      fxAmount: tradeAmount * exchangeRate,
      tradeAmount: tradeAmount,
      exchangeRate: exchangeRate,
    };
  } else if (notNullOrEmpty(tradeAmount) && notNullOrEmpty(exchangeRate)) {
    obj = {
      fxAmount: tradeAmount * exchangeRate,
      tradeAmount: tradeAmount,
      exchangeRate: exchangeRate,
    };
  } else if (notNullOrEmpty(fxAmount) && notNullOrEmpty(tradeAmount)) {
    obj = {
      fxAmount: fxAmount,
      tradeAmount: tradeAmount,
      exchangeRate: fxAmount / tradeAmount,
    };
  } else {
    // do nothing
  }
  return obj;
}

export function fxAmountBlur(fxAmount, tradeAmount, exchangeRate) {
  let obj = {
    fxAmount: fxAmount,
    tradeAmount: tradeAmount,
    exchangeRate: exchangeRate,
  };

  if (
    notNullOrEmpty(fxAmount) &&
    notNullOrEmpty(tradeAmount) &&
    notNullOrEmpty(exchangeRate)
  ) {
    obj = {
      fxAmount: fxAmount,
      tradeAmount: tradeAmount,
      exchangeRate: fxAmount / tradeAmount,
    };
  } else if (notNullOrEmpty(tradeAmount) && notNullOrEmpty(fxAmount)) {
    obj = {
      fxAmount: fxAmount,
      tradeAmount: tradeAmount,
      exchangeRate: fxAmount / tradeAmount,
    };
  } else if (notNullOrEmpty(fxAmount) && notNullOrEmpty(exchangeRate)) {
    obj = {
      fxAmount: fxAmount,
      tradeAmount: fxAmount / exchangeRate,
      exchangeRate: exchangeRate,
    };
  } else {
    // do nothing
  }
  return obj;
}

function notNullOrEmpty(variable) {
  if (variable === null || variable.trim() === "") {
    return false;
  } else {
    return true;
  }
}
