import React from "react";
import "./incomplete-client.css";

function IncompleteClient() {
  return (
    <>
      <span>Incomplete client information.</span>
      <span>Unable to complete the trade at this time</span>
    </>
  );
}

export default IncompleteClient;
