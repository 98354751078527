import { BeneficiariesApi } from "axios-cams-api";

let api = new BeneficiariesApi();

export const emptyCommonBeneficiary = {
  authorised_dealer: "",
  authorised_dealer_account_number: "",
  bank_address: "",
  bank_country: "",
  bank_name: "",
  beneficiary_account_number: "",
  beneficiary_address: "",
  beneficiary_country: "",
  beneficiary_name: "",
  crypto: "",
  name: "",
  short_name: "",
  swift_or_bic: "",
};

export async function benficiariesSearch(
  param,
  value,
  page,
  rows_per_page,
  sortArray
) {
  try {
    let res = await api.beneficiariesCommonList(
      value,
      [],
      page,
      rows_per_page,
      {
        headers: {
          Authorization: `JWT ${sessionStorage.getItem("access")}`,
        },
        params: {
          search_fields: param,
          sort_array: sortArray.toString(),
        },
      }
    );
    if (res) {
      return res.data;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function createCommonBeneficiary(beneficiary) {
  try {
    let res = await api.beneficiariesCommonCreate(beneficiary, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      return res;
    }
  } catch (ex) {
    console.log(ex.message);
  }
}

export async function getBeneficiaryById(id) {
  try {
    let res = await api.beneficiariesCommonRead(id, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      return res.data;
    }
  } catch (ex) {
    console.log(ex.messge);
  }
}

export async function getEntityBeneficiaryById(id) {
  try {
    let res = await api.beneficiariesEntityList(id, 1, 0, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
      params: {
        search_fields: "entity_id__id",
      },
    });
    if (res) {
      return res.data;
    }
  } catch (ex) {
    console.log(ex.messge);
  }
}

export async function createEntityBeneficiary(beneficiary) {
  try {
    let res = await api.beneficiariesEntityCreate(beneficiary, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      return res;
    }
  } catch (ex) {
    return ex;
  }
}

export async function updateEntityBeneficiary(id, beneficiary) {
  try {
    let res = await api.beneficiariesEntityUpdate(id, beneficiary, {
      headers: {
        Authorization: `JWT ${sessionStorage.getItem("access")}`,
      },
    });
    if (res) {
      return res;
    }
  } catch (ex) {
    return ex;
  }
}
