import React from "react";
import useInputState from "../../hooks/useInputState";
import { isNotEmpty } from "../../utils/inputValidationRules";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";

const ContactForm = (props) => {
  const { contact, contact_type, main_contact } = props.contact;

  const formType = props.contact.id ? "Edit" : "New";

  const {
    value: detailValue,
    isValid: detailIsValid,
    hasError: detailHasError,
    valueChangeHandler: detailChangeHandler,
    inputBlurHandler: detailBlurHandler,
  } = useInputState(isNotEmpty, contact ? contact : "");

  let formIsValid = false;

  if (detailIsValid) {
    formIsValid = true;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formIsValid) {
      return;
    }
    let contactObj = {
      entity: props.entity,
      contact_type: contact_type,
      contact: detailValue,
      main_contact: main_contact,
    };

    formType === "New"
      ? props.addContact(contactObj)
      : props.editContact(props.contact.id, contactObj);
  };

  const detailClasses = detailHasError
    ? "cams-form-control invalid"
    : "cams-form-control";

  return (
    <Modal
      show={props.isShown}
      onHide={() => {
        props.close();
      }}
      animation={false}
    >
      <Modal.Header>
        <Modal.Title>
          {formType === "New" ? "Add Contact" : "Edit Contact"}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={submitHandler}>
        <Modal.Body>
          <div className="row">
            <div className={`col-12 ${detailClasses}`}>
              <label className="form-label">
                {contact_type === 4 ? "Email Address*" : "Contact Number*"}
              </label>
              <input
                type="text"
                value={detailValue}
                onChange={detailChangeHandler}
                onBlur={detailBlurHandler}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => {
              props.close();
            }}
          >
            Back
          </button>
          <button
            className="btn btn-primary"
            disabled={!formIsValid}
            type="submit"
          >{`${formType === "New" ? "Add" : "Edit"} Contact`}</button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

ContactForm.propTypes = {
  isShown: PropTypes.bool,
  close: PropTypes.func,
  addContact: PropTypes.func,
  editContact: PropTypes.func,
  contact: PropTypes.object,
  entity: PropTypes.string,
};

export default ContactForm;
