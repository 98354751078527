import React, { useContext } from "react";
import { emptyTrade } from "../../api/trades.api";
import TradeContext from "../../store/tradeState/tradeContext";

const TradeButtonContainer = () => {
  const trdCtx = useContext(TradeContext);
  return (
    <div className="button-container">
      <button
        className="btn btn-primary"
        onClick={() => {
          trdCtx.setTrade(emptyTrade);
          trdCtx.setShowTrade(true);
        }}
      >
        New Trade
      </button>
      <button
        className="btn btn-primary"
        disabled={trdCtx.tradeBatch.length > 0 ? false : true}
        onClick={() => {
          trdCtx.retryTrades(trdCtx.tradeBatch);
        }}
      >
        Retry Trade(s)
      </button>
    </div>
  );
};

export default TradeButtonContainer;
