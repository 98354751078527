import React from "react";
import PropTypes from "prop-types";
import "./Card.css";

const Card = (props) => {
  return (
    <div className="card">
      <h4 className="card-title">{props.title}</h4>
      <span className="card-subtitle">{props.subtitle}</span>
      <div className="card-body">{props.children}</div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

export default Card;
