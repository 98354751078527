import React, { Component } from "react";

import { SidebarData } from "./sidebarData";
import PropTypes from "prop-types";
import SubNav from "./subnav";

import "./sidebar.css";

class Sidebar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <nav
        className={this.props.isSidebar ? "nav-menu active" : "nav-menu"}
        ref={this.props.outerRef}
      >
        <div
          className={
            this.props.isSidebar ? "nav-wrapper" : "nav-wrapper-hidden"
          }
        >
          <div style={{ margin: "auto" }}>
            <div className="sidebar-title-container">
              <h2 className="sidebar-title">DealerDesk</h2>
            </div>
            <ul className="nav-menu-items">
              {SidebarData.map((item, index) => {
                return <SubNav item={item} key={index} />;
              })}
            </ul>
          </div>
          <div className="version-footer-wrapper">
            <span>Version 0.0.107</span>
          </div>
        </div>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  isSidebar: PropTypes.bool,
  handleClickAway: PropTypes.func,
  outerRef: PropTypes.object,
};

export default Sidebar;
