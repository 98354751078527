import React, { Component } from "react";
import PropTypes from "prop-types";
import "./ticker-item.css";

class TickerItem extends Component {
  render() {
    return (
      <div className="ticker-card-item">
        <img
          //src={`https://www.countryflags.io/${this.props.flag}/flat/64.png`}
          src={`${
            process.env.REACT_APP_IMAGE_URL
          }${this.props.flag.toLowerCase()}.png`}
          alt={this.props.flag}
          className="ticker-flag"
        />
        <p className="ticker-tick">{this.props.pair}</p>
        <p className="ticker-tick">{this.props.rate}</p>
      </div>
    );
  }
}

TickerItem.propTypes = {
  flag: PropTypes.string,
  pair: PropTypes.string,
  rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
};

export default TickerItem;
