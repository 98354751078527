import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

let startTime = new Date().getTime();
let logoutTimer;
let timeoutIDs = [];

const AuthContext = React.createContext({
  send_to: "",
  correlation_key: "",
  token: "",
  isLoggedIn: false,
  username: "",
  login: () => {},
  verifyOTP: () => {},
  logout: () => {},
  refresh: () => {},
});

const checkRemainingTime = (expirationTime) => {
  return expirationTime - (new Date().getTime() - startTime);
};

const retrieveStoredToken = () => {
  //Get token from session storage
  const storedToken = sessionStorage.getItem("access");
  const storedExpirationTime = sessionStorage.getItem("access_time");
  const username = sessionStorage.getItem("username");
  const group = sessionStorage.getItem("group");

  //Check how much time is left from the stored session
  const remainingTime = checkRemainingTime(storedExpirationTime);

  if (remainingTime <= 0) {
    //If time is over remove all the session variables and return null
    sessionStorage.removeItem("access");
    sessionStorage.removeItem("refresh");
    sessionStorage.removeItem("access_expiration_time");
    sessionStorage.removeItem("refresh_expiration_time");
    sessionStorage.removeItem("access_time");
    return null;
  }

  //Otherwise return the session information
  return {
    token: storedToken,
    remainingTime: remainingTime,
    username: username,
    group: group,
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();

  let initalToken, initialUsername, initalGroup;
  if (tokenData) {
    initalToken = tokenData.token;
    initialUsername = tokenData.username;
    initalGroup = tokenData.group;
  }
  const [token, setToken] = useState(initalToken);
  const [username, setUsername] = useState(initialUsername);
  const [group, setGroup] = useState(initalGroup);

  const [send_to, setSend_To] = useState("");
  const [correlation_key, setCorrelation_Key] = useState("");
  const [messsage, setMessage] = useState("");

  const userIsLoggedIn = token ? true : false;

  const lougoutHandler = () => {
    //Remove token
    setToken(null);

    //Remove session variables
    sessionStorage.removeItem("access");
    sessionStorage.removeItem("access_expiration_time");
    sessionStorage.removeItem("refresh");
    sessionStorage.removeItem("refresh_expiration_time");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("group");

    if (timeoutIDs.length > 0) {
      //If the timer still exists clear it out.
      clearAllTimers();
    }
  };

  const loginHandler = (correlation_key, sent_to, message) => {
    //Set correlation key
    setCorrelation_Key(correlation_key);
    //Set email or contact number being sent to
    setSend_To(sent_to);
    //Set login response message
    setMessage(message);
  };

  const verifyOTPHandler = (
    token,
    expirationTime,
    refreshToken,
    refreshExpirationTime,
    accessTime,
    username,
    group
  ) => {
    setToken(token);
    setUsername(username);
    setGroup(group);

    //Set session items
    sessionStorage.setItem("access", token);
    sessionStorage.setItem("access_expiration_time", expirationTime);
    sessionStorage.setItem("refresh", refreshToken);
    sessionStorage.setItem("refresh_expiration_time", refreshExpirationTime);
    sessionStorage.setItem("access_time", accessTime);
    sessionStorage.setItem("username", username);
    sessionStorage.setItem("group", group);

    //Get remaining session time
    startTime = new Date().getTime();
    const remainingTime = checkRemainingTime(accessTime);

    //Clear all timers
    clearAllTimers();
    //Set logout timer based on session time

    logoutTimer = setTimeout(lougoutHandler, remainingTime);
    timeoutIDs.push(logoutTimer);
  };

  const refreshToken = (
    token,
    expirationTime,
    refreshToken,
    refreshExpirationTime
  ) => {
    //Set new access token
    setToken(token);

    //Re-set session variables
    sessionStorage.setItem("access", token);
    sessionStorage.setItem("access_expiration_time", expirationTime);
    sessionStorage.setItem("refresh", refreshToken);
    sessionStorage.setItem("refresh_expiration_time", refreshExpirationTime);

    //Get remaining time
    const remainingTime = checkRemainingTime(refreshExpirationTime);

    //Clear previous logout timer
    clearAllTimers();
    //Set new logout timer
    logoutTimer = setTimeout(lougoutHandler, remainingTime);
    timeoutIDs.push(logoutTimer);
  };

  const refreshAccess = () => {
    clearAllTimers();
    startTime = new Date().getTime();
    const remainingTime = checkRemainingTime(
      sessionStorage.getItem("access_time")
    );

    //Set logout timer based on session time
    logoutTimer = setTimeout(lougoutHandler, remainingTime);
    timeoutIDs.push(logoutTimer);
  };

  const clearAllTimers = () => {
    while (timeoutIDs.length > 0) {
      timeoutIDs.forEach((t) => {
        clearTimeout(t);
        timeoutIDs.splice(timeoutIDs.indexOf(t));
      });
    }
  };

  useEffect(() => {
    if (tokenData?.token) {
      clearAllTimers();
      // On load of the context check if there is still a token if the token is still there check how much time is left
      logoutTimer = setTimeout(lougoutHandler, tokenData.remainingTime);
      timeoutIDs.push(logoutTimer);
    }
  }, [tokenData, lougoutHandler]);

  useEffect(() => {
    document.addEventListener("mousemove", refreshAccess);
    document.addEventListener("mousedown", refreshAccess);
    document.addEventListener("keypress", refreshAccess);
  }, []);

  const contextValue = {
    send_to: send_to,
    correlation_key: correlation_key,
    token: token,
    isLoggedIn: userIsLoggedIn,
    username: username,
    group: group,
    login: loginHandler,
    verifyOTP: verifyOTPHandler,
    logout: lougoutHandler,
    refresh: refreshToken,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthContext;
