import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import TradeView from "./trade-view-body-container/trade-view";
import TradeViewButtonContainer from "./trade-view-button-container/trade-view-button-container";
import ServerTimeout from "./trade-view-body-container/server-timeout/server-timeout";
import "./trade-view-container.css";
import ClientInfoBody from "./trade-view-body-container/client-info-body/client-info-body";
import ClientInfoHeader from "./trade-view-body-container/client-info-header/client-info-header";
import IncompleteClient from "./trade-view-body-container/incomplete-client/incomplete-client";
import ServerUnavailable from "./trade-view-body-container/server-unavailable/server-unavailable";

const errorGroup = [16, 17, 18, 19];

function TradeViewContainer(props) {
  return (
    <>
      <Modal
        animation={false}
        show={props.isShown}
        onHide={() => {
          props.close();
        }}
      >
        <Modal.Header closeButton>
          {errorGroup.find((e) => e === props.inTrade.status.code) ? (
            <span>Trade Fail Management</span>
          ) : (
            <div className="row fw">
              <div className="col-10 ">
                <span className="reduced-header">
                  {props.inTrade.customer.name}
                </span>
              </div>
              {/* <div className="col-6 flex-row">
                  <div>
                    <span className="pre-label">Dealer</span>
                    <div className="profileIco dealer">
                      <span>AT</span>
                    </div>
                  </div>
                </div> */}
            </div>
          )}
        </Modal.Header>
        <Modal.Body>
          {errorGroup.find((e) => e === props.inTrade.status.code) ? (
            <>
              <ClientInfoHeader
                clientName={props.inTrade.customer.name}
                clientInformation={props.inTrade.status.name}
              />
              <ClientInfoBody>
                {props.inTrade.status.code === 16 ? (
                  <IncompleteClient />
                ) : (
                  <></>
                )}
                {props.inTrade.status.code === 17 ? (
                  <IncompleteClient />
                ) : (
                  <></>
                )}
                {props.inTrade.status.code === 18 ? <ServerTimeout /> : <></>}
                {props.inTrade.status.code === 19 ? (
                  <ServerUnavailable />
                ) : (
                  <></>
                )}
              </ClientInfoBody>
            </>
          ) : (
            <TradeView inTrade={props.inTrade} />
          )}
        </Modal.Body>
        <Modal.Footer className="trade-view-footer">
          <TradeViewButtonContainer
            close={props.close}
            tryLater={props.close}
            mailSupport={props.close}
            clientView={props.close}
            cancelTrade={props.close}
            retryDeal={props.callbackRetry}
            code={props.inTrade.status.code}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

TradeViewContainer.propTypes = {
  inTrade: PropTypes.object,
  isShown: PropTypes.bool,
  close: PropTypes.func,
  callbackCancel: PropTypes.func,
  callbackRetry: PropTypes.func,
  callbackConfirm: PropTypes.func,
};

export default TradeViewContainer;
