import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import AuthContext from "./auth-context";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const authCtx = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authCtx.isLoggedIn) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object,
};
