import React, { Component } from "react";
import PropTypes from "prop-types";
import "./pagination.css";

class Pagination extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="d-flex flex-row w-100 justify-content-between ml-2">
        <label className="table-footer-label">Rows per page:</label>
        <select
          className="rpp-select"
          id="rows_per_page"
          name="rows_per_page"
          onChange={async (evt) => {
            await this.props.handleChange(evt);
            this.props.resetPage();
            this.props.search();
          }}
          value={this.props.rows_per_page}
        >
          <option>1</option>
          <option>5</option>
          <option>10</option>
          <option>15</option>
          <option>20</option>
          <option>30</option>
          <option>50</option>
          <option>100</option>
        </select>
        <label className="table-footer-label noselect">
          {this.props.upper_threshold} - {this.props.lower_threshold}
        </label>
        <label className="table-footer-label noselect">
          page {this.props.page} of {this.props.page_count}
        </label>
        <button
          onClick={this.props.pageDown}
          className="btn btn-secondary pagination-nav  noselect"
        >{`<`}</button>
        <button
          onClick={this.props.pageUp}
          className="btn btn-secondary pagination-nav noselect"
        >{`>`}</button>
      </div>
    );
  }
}

Pagination.propTypes = {
  rows_per_page: PropTypes.any,
  page: PropTypes.number,
  page_count: PropTypes.number,
  upper_threshold: PropTypes.number,
  lower_threshold: PropTypes.number,
  pageDown: PropTypes.func,
  pageUp: PropTypes.func,
  search: PropTypes.func,
  handleChange: PropTypes.func,
  resetPage: PropTypes.func,
};

export default Pagination;
