//Package Imports
import React, { Component } from "react";
import PropTypes from "prop-types";
//Component Imports
//import TFCardContainer from "../../components/trade-frequency-card/tf-card-container/tf-card-container";
import GenericTable from "../../components/table/generic-table";
import Card from "../../components/UI/Card/Card";
import Header from "../../common/header/header";
import VSButton from "../../components/vs-button/vs-button";
// import PrgChart from "../../components/progress-chart/prg-chart";
// import SkdPrgChart from "../../components/stacked-progress-chart/skd-prg-chart";
import { Statuses } from "../../common/helper";
import { callTradeStatusCount } from "../../lookups";
import Loader from "../../components/loader/loader";

// Asset Imports
import InvestecImgSrc from "../../assets/investec.png";

//Style Imports
import "./dashboard.css";
import RangeCalendar from "../../components/UI/RangeCalendar/RangeCalendar";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datePickerIsOpen: false,
      isLoading: true,
      tradeStatusCounts: [],
    };
    this.getStatusCount = this.getStatusCount.bind(this);
  }

  getStatusCount(dateSelection) {
    const startDate = `${dateSelection.startDate.getDate()}-${
      dateSelection.startDate.getMonth() + 1
    }-${dateSelection.startDate.getFullYear()}`;
    const endDate = `${dateSelection.endDate.getDate()}-${
      dateSelection.endDate.getMonth() + 1
    }-${dateSelection.endDate.getFullYear()}`;
    this.setState({ isLoading: true }, () => {
      callTradeStatusCount(startDate, endDate).then((res) =>
        this.setState({ isLoading: false, tradeStatusCounts: res })
      );
    });
  }

  render() {
    return (
      <>
        <Header
          title="Dealer Dashbaord"
          subtitle="Welcome to Currency Assist Dealer Dashboard"
        />
        <div className="card">
          <div className="card-header-row">
            <h4 className="card-title">Trade Completion by Bank</h4>
            <RangeCalendar onChange={this.getStatusCount} />
          </div>
          <div className="card-body">
            <img src={InvestecImgSrc} alt="" className="responsiveImg" />
            <VSButton
              bgColor="#FFFFFF"
              headerColor="#25A9E1"
              buttonColor="#25A9E1"
              subheaderColor="#000000"
              link="/trades/my-trades"
              title={
                this.state.isLoading ? (
                  <Loader color="#25A9E1" />
                ) : this.state.tradeStatusCounts ? (
                  this.state.tradeStatusCounts?.reduce(
                    (a, b) => a + (b["count"] || 0),
                    0
                  )
                ) : (
                  0
                )
              }
              subtitle={
                this.state.isLoading ? "Fetching count..." : "In Progress"
              }
            />
            <VSButton
              bgColor="#FFFFFF"
              headerColor="#8CC63F"
              buttonColor="#8CC63F"
              subheaderColor="#000000"
              link={`trades/my-trades/?status=${Statuses.bop_completed}`}
              title={
                this.state.isLoading ? (
                  <Loader color="#8CC63F" />
                ) : this.state.tradeStatusCounts ? (
                  this.state.tradeStatusCounts?.find(
                    (s) => s.status === Statuses.bop_completed
                  ).count
                ) : (
                  0
                )
              }
              subtitle={
                this.state.isLoading ? "Fetching count..." : "Bop Completed"
              }
            />
            <VSButton
              bgColor="#FFFFFF"
              headerColor="#F5A623"
              buttonColor="#F5A623"
              subheaderColor="#000000"
              link={`trades/my-trades/?status=${Statuses.bop_uploaded}`}
              title={
                this.state.isLoading ? (
                  <Loader color="#F5A623" />
                ) : this.state.tradeStatusCounts ? (
                  this.state.tradeStatusCounts?.find(
                    (s) => s.status === Statuses.bop_uploaded
                  ).count
                ) : (
                  0
                )
              }
              subtitle={
                this.state.isLoading ? "Fetching count..." : "BOP Uploaded"
              }
            />
            <VSButton
              bgColor="#FFFFFF"
              headerColor="#E1261C"
              buttonColor="#E1261C"
              subheaderColor="#000000"
              link={`trades/my-trades/?status=${Statuses.manual_intervention}`}
              title={
                this.state.isLoading ? (
                  <Loader color="#E1261C" />
                ) : this.state.tradeStatusCounts ? (
                  this.state.tradeStatusCounts?.find(
                    (s) => s.status === Statuses.manual_intervention
                  ).count
                ) : (
                  0
                )
              }
              subtitle={
                this.state.isLoading
                  ? "Fetching count..."
                  : "Manual Intervention"
              }
            />
            <VSButton
              bgColor="#000000"
              headerColor="#8CC63F"
              buttonColor="#8CC63F"
              subheaderColor="#FFFFFF"
              link={`trades/my-trades/?status=${Statuses.bank_completed}`}
              title={
                this.state.isLoading ? (
                  <Loader color="#8CC63F" />
                ) : this.state.tradeStatusCounts ? (
                  this.state.tradeStatusCounts?.find(
                    (s) => s.status === Statuses.bank_completed
                  ).count
                ) : (
                  0
                )
              }
              subtitle={
                this.state.isLoading ? "Fetching count..." : "Bank Completed"
              }
            />
          </div>
        </div>

        <Card title="Pending Trades" subtitle="List of clients ready to trade">
          <GenericTable data={this.props.trades ? this.props.trades : []} />
        </Card>
      </>
    );
  }
}

Dashboard.propTypes = {
  trades: PropTypes.array,
};

export default Dashboard;
