import React, { useEffect, useState } from "react";
import TradeContext from "./tradeContext";
import { defaultTrade } from "../../defaults/tradeDefault";
import PropTypes from "prop-types";
import { tradeBatchRetry, tradeSearch } from "../../api/trades.api";

const TradeProvider = ({ children }) => {
  const [trade, setTrade] = useState(defaultTrade);
  const [trades, setTrades] = useState([]);

  const [sortArray, setSortArray] = useState([]);
  const [searchParam, setSearchParam] = useState("trade_reference_number");
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  const [showToast, setShowToast] = useState(false);
  const [toastResult, setToastResult] = useState("");
  const [toastMessage, setToastMessage] = useState("");

  const [showTrade, setShowTrade] = useState(false);
  const [showNotes, setShowNotes] = useState(false);

  const [tradeBatch, setTradeBatch] = useState([]);

  useEffect(() => {
    getTrades();
  }, [searchParam, searchValue, page, rowsPerPage, JSON.stringify(sortArray)]);

  const getTrades = async () => {
    try {
      const response = await tradeSearch(
        searchParam,
        searchValue,
        page,
        rowsPerPage,
        sortArray
      );

      if (response) {
        setTrades(response.data.results);
        setPageCount(response.data.total_pages);
      } else {
        setTrades([]);
      }
    } catch (error) {
      setTrades([]);
    }
  };

  const retryTrades = async (batch) => {
    if (batch.length > 0) {
      const response = await tradeBatchRetry(batch);
      if (response.data.trades.length > 0) {
        const { trades } = response.data;
        setShowToast(true);
        setToastMessage(trades[0].message);
        setToastResult(trades[0].retry_success);
      } else {
        setShowToast(true);
        setToastMessage("Something went wrong!");
        setToastResult(false);
      }
    }
  };

  return (
    <TradeContext.Provider
      value={{
        trade,
        setTrade,
        trades,
        setTrades,
        getTrades,
        retryTrades,
        sortArray,
        setSortArray,
        searchParam,
        setSearchParam,
        searchValue,
        setSearchValue,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        pageCount,
        setPageCount,
        showToast,
        setShowToast,
        toastResult,
        setToastResult,
        toastMessage,
        setToastMessage,
        showTrade,
        setShowTrade,
        showNotes,
        setShowNotes,
        tradeBatch,
        setTradeBatch,
      }}
    >
      {children}
    </TradeContext.Provider>
  );
};

TradeProvider.propTypes = {
  children: PropTypes.node,
};

export default TradeProvider;
