import React from "react";

import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <FaIcons.FaInbox />,
    cName: "nav-text",
  },
  {
    title: "Trades",
    path: "#",
    icon: <FaIcons.FaRegCheckCircle />,
    iconClosed: <RiIcons.RiArrowDropDownLine />,
    iconOpened: <RiIcons.RiArrowDropUpLine />,
    cName: "nav-text",
    subNav: [
      {
        title: "My Trades",
        path: "/trades/my-trades",
        icon: <RiIcons.RiCheckboxBlankCircleLine />,
        cName: "nav-text",
      },
    ],
  },
  {
    title: "Client View",
    path: "#",
    icon: <FaIcons.FaRegCheckCircle />,
    iconClosed: <RiIcons.RiArrowDropDownLine />,
    iconOpened: <RiIcons.RiArrowDropUpLine />,
    cName: "nav-text",
    subNav: [
      {
        title: "All Clients",
        path: "/client-view/all-clients",
        icon: <RiIcons.RiCheckboxBlankCircleLine />,
        cName: "nav-text",
      },
      {
        title: "Common Beneficiaries",
        path: "/client-view/common-beneficiaries",
        icon: <RiIcons.RiCheckboxBlankCircleLine />,
        cName: "nav-text",
      },
    ],
  },
];
