import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { FaTrash } from "react-icons/fa";
import { FaRegFrown, FaRegSmileWink } from "react-icons/fa";
import "./form-confirmation-modal.css";

export const RequestResponseModal = (props) => {
  return (
    <>
      <Modal
        className="confrim-modal"
        animation={false}
        show={props.isShown}
        onHide={() => {
          props.close();
        }}
      >
        <Modal.Body>
          <div className="row">
            <div className="col-2">
              {props.success ? (
                <FaRegSmileWink className="confirmation success" />
              ) : props.loader ? (
                props.loader
              ) : (
                <FaRegFrown className="confirmation error" />
              )}
            </div>
            <div className="col-10 form-message">{props.message}</div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const FormComfirmationModal = (props) => {
  return (
    <>
      {" "}
      <Modal
        className="confrim-modal"
        animation={false}
        show={props.isShown}
        onHide={() => {
          props.close();
        }}
      >
        <Modal.Body>
          <div className="row">
            <div className="col-2">
              <FaTrash className="confirmation error" />
            </div>
            <div className="col-10 form-message">{props.message}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-seconday" onClick={() => props.close()}>
            No
          </button>
          <button
            className="btn btn-error"
            onClick={() => props.deleteCallback()}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

RequestResponseModal.propTypes = {
  message: PropTypes.string,
  isShown: PropTypes.bool,
  close: PropTypes.func,
  icon: PropTypes.node,
  success: PropTypes.bool,
  loader: PropTypes.object,
};

FormComfirmationModal.propTypes = {
  message: PropTypes.string,
  isShown: PropTypes.bool,
  close: PropTypes.func,
  deleteCallback: PropTypes.func,
};
