import { createContext } from "react";
import { defaultTrade } from "../../defaults/tradeDefault";

const TradeContext = createContext({
  trade: defaultTrade,
  setTrade: () => {},
  trades: [],
  setTrades: () => {},
  getTrades: () => {},
  retryTrades: () => {},
  sortArray: [],
  setSortArray: () => {},
  searchParam: "trade_reference_number",
  setSearchParam: () => {},
  searchValue: "",
  setSearchValue: () => {},
  page: 1,
  setPage: () => {},
  rowsPerPage: 10,
  setRowsPerPage: () => {},
  pageCount: 1,
  setPageCount: () => {},
  showToast: false,
  setShowToast: () => {},
  toastResult: "",
  setToastResult: () => {},
  toastMessage: "",
  setToastMessage: () => {},
  showTrade: false,
  setShowTrade: () => {},
  showNotes: false,
  setShowNotes: () => {},
  tradeBatch: [],
  setTradeBatch: () => {},
});

export default TradeContext;
