export const defaultTrade = {
  id: null,
  account_number: "",
  account_id: "",
  allowance_type: "",
  bank: {
    id: "",
    display_name: "",
    image: null,
    referral_code: null,
  },
  beneficiary: "",
  bop_code: "",
  customer: {
    id: "",
    name: "",
  },
  entity: "",
  dealer_id: null,
  exchange_rate: "",
  fx_amount: "",
  fx_currency: { alpha_2: "", alpha_3: "", name: "", symbol: "" },
  last_updated: "",
  locked: false,
  notes_total: 0,
  retry_count: 0,
  status: {
    code: null,
    display_order: null,
    is_error: false,
    name: "",
  },
  trade_amount: "",
  trade_currency: { alpha_2: "", alpha_3: "", name: "", symbol: "" },
  trade_date: "",
  trade_rate: null,
  trade_reference_number: null,
  txn_type: "",
  value_date: new Date(),
};
