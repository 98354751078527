import React, { Component } from "react";
import Header from "../../../common/header/header";
import BeneficiariesTable from "../../../components/beneficiaries-table/beneficiaries-table";
import PropTypes from "prop-types";

class Beneficiaries extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Header title="Common Beneficiaries" />
        <BeneficiariesTable data={this.props.beneficiaries} />
      </>
    );
  }
}

Beneficiaries.propTypes = {
  beneficiaries: PropTypes.array,
};

export default Beneficiaries;
