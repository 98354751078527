import React, { Component } from "react";
import TickerGroup from "../ticker-group/ticker-group";
import "./ticker-card.css";
import Loader from "../../loader/loader";

class TickerCard extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      tickers: [
        {
          flag: "be",
          currency: "",
          rate: 0.0,
        },
      ],
      pause: false,
      timestamp: "",
      isLoading: true,
    };
  }

  async componentDidMount() {
    this.ws = new WebSocket(process.env.REACT_APP_SOCKET_URL);
    this._isMounted = true;
    this.setState({ isLoading: true }, () => {
      this.setupSocket().then(() => {
        this.setState({ isLoading: false });
      });
    });

    this.ws.onclose = async () => {
      await this.setupSocket();
      // automatically try to reconnect on connection loss
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async setupSocket() {
    this.ws.onopen = () => {
      // on connecting, close loader
    };

    this.ws.onmessage = (evt) => {
      // listen to data sent from the websocket server
      const message = JSON.parse(evt.data);
      if (message) {
        this.setState({
          tickers: message.currencies ? message.currencies : [],
          timestamp: message.timestamp,
        });
      }
      return false;
    };
  }

  render() {
    return (
      <div className="marquee-wrapper">
        <div className="marquee-block">
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <div
              className={`ticker-groups-wrapper ${
                this.state.pause && "ticker-pause"
              }`}
              onClick={() => this.setState({ pause: !this.state.pause })}
            >
              <div className="marquee-item">
                <TickerGroup
                  key="1"
                  groupName="Investec"
                  items={this.state.tickers}
                />
                <TickerGroup
                  key="2"
                  groupName="Investec"
                  items={this.state.tickers}
                />
                <TickerGroup
                  key="3"
                  groupName="Investec"
                  items={this.state.tickers}
                />
              </div>
            </div>
          )}
        </div>
        <div className="ticker-card-anchor">
          <span>last updated</span>
          <br />
          <span>{this.state.timestamp}</span>
        </div>
      </div>
    );
  }
}

export default TickerCard;
