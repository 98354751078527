import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Loader from "../loader/loader";

import "./form-loader.css";

class FormLoader extends Component {
  render() {
    return (
      <>
        <Modal
          className="confirm-modal"
          animation={false}
          show={this.props.isShown}
          onHide={() => {
            this.props.close();
          }}
        >
          <Modal.Body>
            <div className="row">
              <div className="col-2">
                <Loader class="form-loader" />
              </div>
              <div className="col-10 form-message">{this.props.message}</div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

FormLoader.propTypes = {
  message: PropTypes.string,
  isShown: PropTypes.bool,
  close: PropTypes.func,
};

export default FormLoader;
