import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import PropTypes from "prop-types";
import { numberFormat } from "../../common/helper";
import "./generic-table.css";
import { tradeSearch } from "../../api/trades.api";
import Loader from "../loader/loader";

class GenericTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      record_count: 1,
      page_count: 10,
      rows_per_page: 10,
      data: [],
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.pageUp = this.pageUp.bind(this);
    this.pageDown = this.pageDown.bind(this);
    this.pageTrade = this.pageTrade.bind(this);
  }

  async pageTrade() {
    this.setState({ isLoading: true }, () => {
      tradeSearch("", "", this.state.page, this.state.rows_per_page, [
        "-value_date",
      ]).then((currentTrades) => {
        if (currentTrades) {
          this.setState({
            isLoading: false,
            data: currentTrades,
            record_count: currentTrades?.data?.count,
            page_count: currentTrades?.data?.total_pages,
          });
        } else {
          this.setState({ isLoading: false });
        }
      });
    });
  }

  async componentDidMount() {
    this.pageTrade();
  }

  handleChange(evt) {
    this.setState(
      {
        [evt.target.name]: evt.target.value,
      },
      () => {
        this.pageTrade();
      }
    );
  }

  pageUp() {
    if (this.state.page != this.state.page_count)
      this.setState(
        (st) => ({
          page: st.page != st.page_count ? st.page + 1 : st.page,
        }),
        () => {
          this.pageTrade();
        }
      );
  }

  pageDown() {
    if (this.state.page != 1)
      this.setState(
        (st) => ({
          page: st.page != 1 ? st.page - 1 : st.page,
        }),
        () => {
          this.pageTrade();
        }
      );
  }

  render() {
    return (
      <div className="paginated-table">
        <Table hover>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Value Date</th>
              <th>Bank</th>
              <th>Trade Amount</th>
              <th>Exc. Rate</th>
              <th>FX amount</th>
              <th>BOP code</th>
            </tr>
          </thead>
          <tbody>
            {this.state.isLoading ? (
              <tr>
                <td colSpan={40}>
                  <Loader />
                </td>
              </tr>
            ) : this.state.data.data?.results.length > 0 ? (
              this.state.data.data?.results.map((d) => (
                <tr key={d.id}>
                  <td>{d.customer?.name ? d.customer.name : ""}</td>
                  <td>{d.value_date}</td>
                  <td>{d.bank ? d.bank.display_name : ""}</td>
                  <td className="right-text">{`${
                    d.trade_currency ? d.trade_currency.symbol : ""
                  } ${numberFormat(d.trade_amount)}`}</td>
                  <td className="right-text">{d.exchange_rate}</td>
                  <td className="right-text">{`${
                    d.fx_currency ? d.fx_currency.symbol : ""
                  } ${numberFormat(d.fx_amount)}`}</td>
                  <td>{d.bop_code}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={40}>There are currently no trades booked</td>
              </tr>
            )}
          </tbody>
        </Table>

        <div className="row mt-2 table-footer">
          <div className="col-8 flex-row"></div>
          <div className="col-4 flex-row">
            <label className="table-footer-label">Rows per page:</label>
            <select
              className="rpp-select"
              id="rows_per_page"
              name="rows_per_page"
              onChange={this.handleChange}
              value={this.state.rows_per_page}
            >
              <option>10</option>
              <option>15</option>
              <option>20</option>
              <option>30</option>
              <option>40</option>
              <option>50</option>
              <option>100</option>
            </select>
            <label className="table-footer-label">
              {this.state.page} of {this.state.page_count}
            </label>
            <button
              onClick={this.pageDown}
              className="btn btn-secondary pagination-nav "
            >{`<`}</button>
            <button
              onClick={this.pageUp}
              className="btn btn-secondary pagination-nav"
            >{`>`}</button>
          </div>
        </div>
      </div>
    );
  }
}

GenericTable.propTypes = {
  data: PropTypes.array,
};
export default GenericTable;
