import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import Search from "../search/search";
import {
  benficiariesSearch,
  emptyCommonBeneficiary,
  createCommonBeneficiary,
} from "../../api/beneficiaries.api";
import Pagination from "../pagination/pagination";
import BeneficiaryEditForm from "./beneficiary-edit-form/beneficiary-edit-form";
import BeneficiaryForm from "./beneficiary-form/beneficiary-form";
import BeneficiaryView from "./beneficiary-view/beneficiary-view";
import FormLoader from "../form-loader/form-loader";
import { RequestResponseModal } from "../form-cofirmation-modal/form-confirmation-modal";
import Loader from "../loader/loader";

const searchOptions = [
  { name: "Name", value: "trade_reference_number" },
  { name: "Bank", value: "account_number" },
  { name: "Currency", value: "value_date" },
  { name: "Account Number", value: "status__name" },
  { name: "Authorised Dealer", value: "allowance_type" },
  { name: "Beneficiary", value: "exchange_rate" },
  { name: "Last Updated", value: "exchange_rate" },
];

class BeneficiariesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beneficiary: emptyCommonBeneficiary,
      addBeneficiary: false,
      viewBeneficiary: false,
      typingTimeout: 0,
      searchFilter: "name",
      searchText: "",
      page: 1,
      rows_per_page: 10,
      sortArray: [],
      showLoader: false,
      showConfirmation: false,
      requestMessage: "",
      success: false,
      data: [],
      isLoading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.beneficiarySearch = this.beneficiarySearch.bind(this);

    this.resetPage = this.resetPage.bind(this);
    this.pageUp = this.pageUp.bind(this);
    this.pageDown = this.pageDown.bind(this);

    this.showAddBeneficiary = this.showAddBeneficiary.bind(this);
    this.closeAddBeneficiary = this.closeAddBeneficiary.bind(this);

    this.showViewBeneficiary = this.showViewBeneficiary.bind(this);
    this.closeViewBeneficiary = this.closeViewBeneficiary.bind(this);

    this.clearBeneficiary = this.clearBeneficiary.bind(this);

    this.confrimBeneficiary = this.confrimBeneficiary.bind(this);
  }

  async componentDidMount() {
    await this.beneficiarySearch();
  }

  async beneficiarySearch() {
    this.setState({ isLoading: true }, () => {
      benficiariesSearch(
        this.state.searchFilter,
        this.state.searchText,
        this.state.page,
        this.state.rows_per_page,
        this.state.sortArray
      ).then((result) => {
        if (result) {
          this.setState({
            isLoading: false,
            data: result.results,
            record_cound: result.count,
            page_count: result.total_pages,
            upper_threshold: result.position.first,
            lower_threshold: result.position.last,
          });
        } else {
          this.setState({ isLoading: false });
        }
      });
    });
  }

  clearBeneficiary() {
    this.setState({
      beneficiary: emptyCommonBeneficiary,
    });
  }

  showAddBeneficiary() {
    this.setState({
      addBeneficiary: true,
      viewBeneficiary: false,
    });
  }

  closeAddBeneficiary() {
    this.setState({
      addBeneficiary: false,
      beneficiary: emptyCommonBeneficiary,
    });
  }

  showViewBeneficiary(b) {
    this.setState({
      beneficiary: b,
      viewBeneficiary: true,
      addBeneficiary: false,
    });
  }

  closeViewBeneficiary() {
    this.setState({
      viewBeneficiary: false,
    });
  }

  handleChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  }

  resetPage() {
    this.setState({
      page: 1,
    });
  }

  pageUp() {
    this.setState(
      (st) => ({
        page: st.page != st.page_count ? st.page + 1 : st.page,
      }),
      () => {
        this.beneficiarySearch();
      }
    );
  }

  pageDown() {
    this.setState(
      (st) => ({
        page: st.page != 1 ? st.page - 1 : st.page,
      }),
      () => {
        this.beneficiarySearch();
      }
    );
  }

  confrimBeneficiary() {
    this.setState(
      {
        showLoader: true,
        viewBeneficiary: false,
      },
      async () => {
        await createCommonBeneficiary(this.state.beneficiary)
          .then(() => {
            this.setState({
              success: true,
              showLoader: false,
              showConfirmation: true,
              requestMessage: "Beneficiary Successfully Created",
            });
          })
          .catch((ex) => {
            this.setState({
              success: false,
              showLoader: false,
              showConfirmation: true,
              requestMessage: `${ex.message}. Please contact system admin.`,
            });
          });
      }
    );
  }

  updateBeneficiary() {
    this.setState({
      showLoader: true,
      viewBeneficiary: false,
    });
  }

  render() {
    return (
      <>
        <div className="trade-table">
          <Search
            searchCallback={this.beneficiarySearch}
            handleChange={this.handleChange}
            searchFilter={this.state.searchFilter}
            searchText={this.state.searchText}
            resetPage={() => this.setState({ page: 1 })}
            options={searchOptions}
          />
          <>
            {/* <div className="button-container">
              <Button variant="secondary" onClick={this.showAddBeneficiary}>
                Add Common Beneficiary
              </Button>
              <Button variant="warning">
                <BSIcons.BsArrowRepeat />
              </Button>
            </div> */}
          </>
          <Table hover>
            <thead>
              <tr className="header-row">
                <th style={{ width: "100px" }}>Name</th>
                <th style={{ width: "200px" }}>Bank</th>
                <th style={{ width: "50px" }}>Currency</th>
                <th style={{ width: "200px" }}>Account Number</th>
                <th style={{ width: "150px" }}>Authorised Dealer</th>
                <th style={{ width: "150px" }}>Beneficiary Name</th>
                <th style={{ width: "150px" }}>SWIFT / BIC</th>
                <th style={{ width: "100px" }}>Last Updated</th>
              </tr>
            </thead>
            <tbody>
              {this.state.isLoading ? (
                <tr>
                  <td colSpan={40}>
                    <Loader />
                  </td>
                </tr>
              ) : this.state.data.length > 0 ? (
                this.state.data.map((d) => (
                  <tr className="body-row" key={d.id}>
                    <td>{d.name}</td>
                    <td>{d.bank_name}</td>
                    <td>{d.currency}</td>
                    <td>{d.beneficiary_account_number}</td>
                    <td>{d.authorised_dealer}</td>
                    <td>{d.beneficiary_name}</td>
                    <td>{d.swift_or_bic}</td>
                    <td>
                      {d.last_updated.substring(0, d.last_updated.indexOf("T"))}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={40}>
                    {" "}
                    There are currently no common beneficiaries listed{" "}
                  </td>{" "}
                </tr>
              )}
            </tbody>
          </Table>

          <div className="row mt-2 table-footer">
            <div className="col-8 card flex-row">
              <span>Hot Keys:</span>
              <span>Shift + R (Refresh)</span>
            </div>
            <div className="col-4 flex-row card bg-w">
              <Pagination
                handleChange={this.handleChange}
                search={this.beneficiarySearch}
                rows_per_page={this.state.rows_per_page}
                resetPage={() => this.setState({ page: 1 })}
                page={this.state.page}
                page_count={this.state.page_count}
                pageUp={this.pageUp}
                pageDown={this.pageDown}
                upper_threshold={this.state.upper_threshold}
                lower_threshold={this.state.lower_threshold}
              />
            </div>
          </div>
        </div>

        {this.state.editBeneficiary ? <BeneficiaryEditForm /> : null}

        {this.state.addBeneficiary ? (
          <BeneficiaryForm
            isShown={this.state.addBeneficiary}
            close={this.closeAddBeneficiary}
            beneficiary={this.state.beneficiary}
            next={this.showViewBeneficiary}
          />
        ) : null}

        {this.state.viewBeneficiary ? (
          <BeneficiaryView
            isShown={this.state.viewBeneficiary}
            close={this.closeViewBeneficiary}
            beneficiary={this.state.beneficiary}
            next={this.confrimBeneficiary}
            back={this.showAddBeneficiary}
          />
        ) : null}

        <FormLoader
          isShown={this.state.showLoader}
          message="Saving Information"
          close={() => {
            this.setState({
              showLoader: false,
            });
          }}
        />

        <RequestResponseModal
          isShown={this.state.showConfirmation}
          message={this.state.requestMessage}
          success={this.state.success}
          close={() => {
            this.setState({
              showConfirmation: false,
            });
          }}
        />
      </>
    );
  }
}

export default BeneficiariesTable;
