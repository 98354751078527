import React, { Component } from "react";
import PropTypes from "prop-types";
import "./header.css";

class Header extends Component {
  render() {
    return (
      <div className="header">
        <h1 className="header-title">{this.props.title}</h1>
        <h4 className="header-subtitle">{this.props.subtitle}</h4>
      </div>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Header;
