import React from "react";
import "./trade-tracker-level.css";
import * as BsIcons from "react-icons/bs";
import PropTypes from "prop-types";

function TradeTrackerLevel(props) {
  const handleBar = () => {
    if (props.obj.is_done) {
      return "";
    } else {
      return "trbar";
    }
  };

  const handleBgColor = () => {
    let res = "";
    switch (props.levelColor) {
      case "orange":
        res = "tracker-br-orange";
        break;
      case "red":
        res = "tracker-br-red";
        break;
      case "yellow-green":
        res = "tracker-br-yellow-green";
        break;
      case "green":
        res = "tracker-br-green";
        break;
      default:
        res = "tr";
        break;
    }
    return res;
  };

  return props.obj.display_order === 31 ? (
    <div className={`tracker-nbr ${handleBar()}`}>
      {" "}
      {props.obj.is_here ? (
        <BsIcons.BsGeoAlt
          className={`tracker-ico ${handleBgColor()} ${props.levelColor}`}
        />
      ) : (
        <></>
      )}
    </div>
  ) : (
    <div className={`tracker-bar ${handleBar()} ${handleBgColor()}`}>
      {" "}
      {props.obj.is_here ? (
        <BsIcons.BsGeoAlt
          className={`tracker-ico ${handleBgColor()} ${props.levelColor} `}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

TradeTrackerLevel.propTypes = {
  obj: PropTypes.object,
  levelColor: PropTypes.string,
};

export default TradeTrackerLevel;
