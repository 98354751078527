import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import "./beneficiary-view.css";

class BeneficiaryView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Modal
          className="beneficiary-view-modal"
          animation={false}
          show={this.props.isShown}
          onHide={() => {
            this.props.close();
          }}
        >
          <Modal.Header closeButton>
            <div>
              <label className="pre-label">Beneficiary Name</label>
              <br />
              <h3>{this.props.beneficiary.name}</h3>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-6">
                <div className="row">
                  <div className="col-6">
                    <label className="form-label">Beneficiary Name:</label>
                  </div>
                  <div className="col-6">
                    <label className="view-label">
                      {this.props.beneficiary.beneficiary_name}
                    </label>
                  </div>
                  <div className="col-6">
                    <label className="form-label">Short Name:</label>
                  </div>
                  <div className="col-6">
                    <label className="view-label">
                      {this.props.beneficiary.short_name}
                    </label>
                  </div>
                  <div className="col-6">
                    <label className="form-label">Name of Bank:</label>
                  </div>
                  <div className="col-6">
                    <label className="view-label">
                      {this.props.beneficiary.bank_name}
                    </label>
                  </div>
                  <div className="col-6">
                    <label className="form-label">Bank Country:</label>
                  </div>
                  <div className="col-6">
                    <label className="view-label">
                      {this.props.beneficiary.bank_country}
                    </label>
                  </div>
                  <div className="col-6">
                    <label className="form-label">Bank Currency:</label>
                  </div>
                  <div className="col-6">
                    <label className="view-label">
                      {this.props.beneficiary.currency}
                    </label>
                  </div>
                  <div className="col-6">
                    <label className="form-label">Swift / BIC Code:</label>
                  </div>
                  <div className="col-6">
                    <label className="view-label">
                      {this.props.beneficiary.swift_or_bic}
                    </label>
                  </div>
                  <div className="col-6">
                    <label className="form-label">Bank Address:</label>
                  </div>
                  <div className="col-6">
                    <label className="view-label">
                      {this.props.beneficiary.bank_address}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-6">
                    <label className="form-label">Account Number / IBAN:</label>
                  </div>
                  <div className="col-6">
                    <label className="view-label">
                      {this.props.beneficiary.beneficiary_account_number}
                    </label>
                  </div>
                  <div className="col-6">
                    <label className="form-label">Crypto:</label>
                  </div>
                  <div className="col-6">
                    <label className="view-label">
                      {this.props.beneficiary.crypto}
                    </label>
                  </div>
                  <div className="col-6">
                    <label className="form-label">Authorised Dealer:</label>
                  </div>
                  <div className="col-6">
                    <label className="view-label">
                      {this.props.beneficiary.authorised_dealer}
                    </label>
                  </div>
                  <div className="col-6">
                    <label className="form-label">
                      Authorised Dealer Acc No:
                    </label>
                  </div>
                  <div className="col-6">
                    <label className="view-label">
                      {this.props.beneficiary.authorised_dealer_account_number}
                    </label>
                  </div>
                  <div className="col-6">
                    <label className="form-label">Beneficiary Country:</label>
                  </div>
                  <div className="col-6">
                    <label className="view-label">
                      {this.props.beneficiary.beneficiary_country}
                    </label>
                  </div>
                  <div className="col-6">
                    <label className="form-label">Beneficiary Address:</label>
                  </div>
                  <div className="col-6">
                    <label className="view-label">
                      {this.props.beneficiary.beneficiary_address}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.props.back();
              }}
            >
              Back
            </Button>
            <Button
              variant="success"
              onClick={() => {
                this.props.next();
              }}
            >
              Confirm New Beneficiary
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

BeneficiaryView.propTypes = {
  isShown: PropTypes.bool,
  close: PropTypes.func,
  beneficiary: PropTypes.object,
  next: PropTypes.func,
  back: PropTypes.func,
};

export default BeneficiaryView;
