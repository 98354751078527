import React, { Component } from "react";
import PropTypes from "prop-types";
import "./drag-and-drop.css";
import * as AiIcons from "react-icons/ai";
import { getDocumentTypes } from "../../lookups";

class DragAndDrop extends Component {
  state = {
    drag: false,
    fileType: this.props.fileType,
  };
  dropRef = React.createRef();
  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };
  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      console.log(e.dataTransfer);
      this.props.handleDrop(e.dataTransfer.files);
      this.dragCounter = 0;
    }
  };
  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }
  render() {
    return (
      <div className="row">
        <div className="col-12" ref={this.dropRef}>
          <div className="dragdrop-select">
            <label className="form-label">File Type</label>
            <select
              className="form-control max-27 mb-2"
              value={this.props.fileType}
              onChange={(e) => this.props.setFileType(e)}
            >
              {getDocumentTypes().map((d) => (
                <option key={d.id} value={d.id}>
                  {d.description}
                </option>
              ))}
            </select>
          </div>
          <div className="dragdrop-input">
            <AiIcons.AiOutlineCloudUpload size={28} />
            <span className="dragdrop-label">Drag and drop files here</span>
          </div>
        </div>
      </div>
    );
  }
}

DragAndDrop.propTypes = {
  handleDrop: PropTypes.func,
  children: PropTypes.node,
  fileType: PropTypes.number,
  setFileType: PropTypes.func,
};
export default DragAndDrop;
