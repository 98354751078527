import React, { useState, useEffect } from "react";

import TradeTrackerLevel from "./trade-tracker-level/trade-tracker-level";

import "./trade-tracker.css";

import PropTypes from "prop-types";

import { getStatusList } from "../../lookups";

function TradeTracker(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const data = getStatusList().map((element) => {
      if (element.display_order <= props.status.display_order) {
        element = { ...element, is_done: true };
      }
      if (element.display_order === props.status.display_order) {
        element = { ...element, is_done: true, is_here: true };
      }
      return element;
    });
    setData(data);
  }, []);

  return (
    <div className={"tracker-wrapper " + props.bgColor}>
      <div className="tracker-nb"></div>
      {data.map((t) => (
        <TradeTrackerLevel key={t.code} obj={t} levelColor={props.bgColor} />
      ))}
    </div>
  );
}

TradeTracker.propTypes = {
  status: PropTypes.object,
  bgColor: PropTypes.string,
  levelColor: PropTypes.string,
};

export default TradeTracker;
