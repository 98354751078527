import React, { useState, useEffect, useRef } from "react";
import ContactForm from "../contact-form/contact-form";
import Loader from "../UI/Loader/Loader";
import PropTypes from "prop-types";
import { BsTrash } from "react-icons/bs";
import {
  RequestResponseModal,
  FormComfirmationModal,
} from "../form-cofirmation-modal/form-confirmation-modal";
import "./email-listing.css";
import {
  getContactsByEntityID,
  createContact,
  updateContact,
  removeContact,
} from "../../api/clients.api";

const EmailListing = (props) => {
  const [emails, setEmails] = useState([]);
  const [showEmailForm, setEmailForm] = useState(false);
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState({});
  const [success, setSuccess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const unmounted = useRef(false);

  useEffect(() => {
    setIsLoading(true);
    getEmails();
    setIsLoading(false);

    return () => {
      unmounted.current = true;
    };
  }, []);

  const getEmails = async () => {
    try {
      const response = await getContactsByEntityID(props.entity);
      if (response) {
        const emails = response.filter((c) => c.contact_type === 4);
        if (!unmounted.current) setEmails(emails);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const addEmail = () => {
    setSelectedEmail({ contact_type: 4 });
    setEmailForm(true);
  };

  const editEmail = (index) => {
    setSelectedEmail(emails[index]);
    setEmailForm(true);
  };

  const deleteEmail = (index) => {
    setSelectedEmail(emails[index]);
    setDeleteConfirmation(true);
  };

  const addEmailSubmitHandler = async (email) => {
    setEmailForm(false);
    setRequestIsLoading(true);
    try {
      let emailObj = {
        entity: props.entity,
        contact_type: email.contact_type,
        contact: email.contact,
        main_contact: email.main_contact,
      };
      let response = await createContact(emailObj);
      if (response) {
        setResponseMessage("Email created successfully!");
        setSuccess(true);
        setRequestIsLoading(false);
        setConfirmationModal(true);
      }
    } catch (error) {
      setResponseMessage(`${error.message}. Please contact system admin.`);
      setSuccess(false);
      setRequestIsLoading(false);
      setConfirmationModal(true);
    }
  };

  const editEmailSubmitHandler = async (id, email) => {
    setEmailForm(false);
    setRequestIsLoading(true);
    try {
      let response = await updateContact(id, email);
      if (response) {
        setResponseMessage("Email updated successfully!");
        setSuccess(true);
        setRequestIsLoading(false);
        setConfirmationModal(true);
      }
    } catch (error) {
      setResponseMessage(`${error.message}. Please contact system admin.`);
      setSuccess(false);
      setRequestIsLoading(false);
      setConfirmationModal(true);
    }
  };

  const deleteEmailSubmitHandler = async () => {
    setDeleteConfirmation(false);
    setRequestIsLoading(true);
    try {
      let response = await removeContact(selectedEmail.id);
      if (response) {
        setResponseMessage("Contact removed successfully!");
        setSuccess(true);
        setRequestIsLoading(false);
        setConfirmationModal(true);
      }
    } catch (error) {
      setResponseMessage(`${error.message}. Please contact system admin.`);
      setSuccess(false);
      setRequestIsLoading(false);
      setConfirmationModal(true);
    }
  };

  return (
    <div className="card">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-10">
              <h4 className="card-title">Email Information</h4>
            </div>
            <div className="col-2">
              <div className="account-btn" onClick={addEmail}>
                +
              </div>
            </div>
          </div>

          <div className="row w-100">
            {emails.length > 0 ? (
              emails?.map((e, i) => (
                <React.Fragment key={i}>
                  <div className="col-11" onClick={() => editEmail(i)}>
                    <span className="listing-text">{e.contact} </span>
                  </div>
                  <div className="col-1" onClick={() => deleteEmail(i)}>
                    <BsTrash
                      style={{ cursor: "pointer", color: "red" }}
                      size={15}
                    />
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div className="col-12 mt-3" style={{ textAlign: "center" }}>
                <span className="listing-text ">No emails found</span>
              </div>
            )}
          </div>
          {showEmailForm ? (
            <ContactForm
              contact={selectedEmail}
              isShown={showEmailForm}
              entity={props.entity}
              close={() => setEmailForm(false)}
              addContact={addEmailSubmitHandler}
              editContact={editEmailSubmitHandler}
            />
          ) : null}

          {requestIsLoading ? (
            <RequestResponseModal
              isShown={requestIsLoading}
              loader={<Loader />}
              message={"Saving Information"}
              close={() => {
                setRequestIsLoading(false);
              }}
            />
          ) : null}

          {confirmationModal ? (
            <RequestResponseModal
              isShown={confirmationModal}
              success={success}
              message={responseMessage}
              close={() => {
                setConfirmationModal(false);
                getEmails();
              }}
            />
          ) : null}

          {deleteConfirmation ? (
            <FormComfirmationModal
              isShown={deleteConfirmation}
              message="Are you sure you want to delete email?"
              deleteCallback={deleteEmailSubmitHandler}
              close={() => setDeleteConfirmation(false)}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

EmailListing.propTypes = {
  entity: PropTypes.string,
  emails: PropTypes.array,
};

export default EmailListing;
