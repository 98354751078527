import React, { Component } from "react";
import TickerItem from "../ticker-item/ticker-item";
import PropTypes from "prop-types";
import "./ticker-group.css";

class TickerGroup extends Component {
  render() {
    return (
      <>
        {this.props.items.map((val) => (
          <TickerItem
            key={val.currency}
            flag={val.flag}
            pair={val.currency.replace(" ", "/")}
            rate={val.rate}
          />
        ))}
      </>
    );
  }
}

TickerGroup.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  items: PropTypes.array,
};

export default TickerGroup;
