import React, { useEffect, useState, useRef, useContext } from "react";
import "./applayout.css";
import axios from "axios";
import Sidebar from "../common/sidebar/sidebar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Dashboard from "../pages/dashboard/dashboard";
import Trades from "../pages/trade/trades/trades";
import AllClients from "../pages/client/all-clients/all-clients";
import EditClient from "../pages/client/edit-client/edit-client";
import Beneficiaries from "../pages/client/beneficiaries/beneficiaries";

import Page from "../common/page/page";
import Navbar from "../common/navbar/navbar";

import { tradeSearch } from "../api/trades.api";

import {
  callBopCodes,
  callStatusList,
  callCurencies,
  callCountries,
  callPartners,
  callDocumentTypes,
  callGetBanks,
  callGetBankBranches,
  callGetAccountTypes,
} from "../lookups";

import AuthContext from "../auth/auth-context";
import NotificationContext from "../store/notificationsState/notificationContext";
import NotificationPanel from "../components/notifications-panel/notification-panel";
import TradeProvider from "../store/tradeState/tradeProvider";

const AppLayout = () => {
  let container = useRef();
  let container2 = useRef();

  const [sidebarOpenClose, setSidebarOpenClose] = useState(false);
  const [currentTrades, setCurrentTrades] = useState([]);

  const authCtx = useContext(AuthContext);
  const notificationCtx = useContext(NotificationContext);

  useEffect(async () => {
    setInterceptors();
    document.addEventListener("mousedown", handleClickOutside);
    await getStartupData();
    let trades = await tradeSearch();
    setCurrentTrades(trades);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          const originalReq = err.config;
          // Prevent infinite loops
          if (
            err.response.status === 401 &&
            originalReq.url ===
              process.env.REACT_APP_SERVER_URL + "/auth/token/refresh/"
          ) {
            window.location.href = "/";
            return Promise.reject(err);
          }
          if (
            err.response?.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            originalReq.__isRetryRequest = true;
            let res = fetch(
              process.env.REACT_APP_SERVER_URL + "/auth/token/refresh/",
              {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                  "Content-Type": "application/json",
                },
                referrer: "no-referrer",
                body: JSON.stringify({
                  refresh: sessionStorage.getItem("refresh"),
                }),
              }
            )
              .then((res) => res.json())
              .then((res) => {
                const {
                  access,
                  access_expiration_time,
                  refresh,
                  refresh_expiration_time,
                } = res;
                authCtx.refresh(
                  access,
                  access_expiration_time,
                  refresh,
                  refresh_expiration_time
                );

                originalReq.headers["Authorization"] = "JWT " + res.access;

                return axios(originalReq);
              });

            resolve(res);
          }
          return reject(err);
        });
      }
    );
  };

  const getStartupData = () => {
    callBopCodes();
    callStatusList();
    callCurencies();
    callCountries();
    callPartners();
    callDocumentTypes();
    callGetBanks();
    callGetBankBranches();
    callGetAccountTypes();
  };

  const setBar = () => {
    setSidebarOpenClose(!sidebarOpenClose);
  };

  const handleClickOutside = (event) => {
    if (
      container.current &&
      !container.current.contains(event.target) &&
      container2.current &&
      !container2.current.contains(event.target)
    ) {
      setSidebarOpenClose(false);
    }
  };

  return (
    <div className="App">
      <Router>
        <Navbar toggle={setBar} outerRef={container} />
        <div className="flexDisplay">
          <Sidebar isSidebar={sidebarOpenClose} outerRef={container2} />
          <Page>
            <Switch>
              <Route
                exact
                path="/dashboard"
                render={() => (
                  <Dashboard trades={currentTrades ? currentTrades : []} />
                )}
              />
              <Route
                exact
                path="/trades/my-trades"
                render={() => (
                  <TradeProvider>
                    <Trades
                      title="Trades"
                      trades={
                        currentTrades
                          ? currentTrades.filter((t) => {
                              if (t.status?.name !== "Completed") {
                                return t;
                              }
                            })
                          : []
                      }
                      newTrades={true}
                      displayOnly={false}
                    />
                  </TradeProvider>
                )}
              />
              <Route
                exact
                path="/trades/cancelled-trades"
                component={() => (
                  <Trades
                    title="Cancelled List"
                    trades={
                      currentTrades
                        ? currentTrades.filter((t) => {
                            if (t.status?.name === "Cancelled") {
                              return t;
                            }
                          })
                        : []
                    }
                    newTrades={false}
                    displayOnly={true}
                  />
                )}
              />

              <Route
                exact
                path="/client-view/all-clients"
                render={() => <AllClients />}
              />

              <Route
                path="/client-view/client-edit/:id"
                render={(props) => <EditClient {...props} />}
              />

              <Route
                exact
                path="/client-view/common-beneficiaries"
                render={() => <Beneficiaries />}
              />
            </Switch>
            {notificationCtx.showNotifications && <NotificationPanel />}
          </Page>
        </div>
      </Router>
    </div>
  );
};

export default AppLayout;
