import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import TradeContainer from "../trade-table/trade/trade-container";
import { tradeSearch } from "../../api/trades.api";
import Loader from "../UI/Loader/Loader";
import { handleStatusStyle } from "../../common/helper";

function TradeListing({ entity }) {
  const [trades, setTrades] = useState([]);
  const [selectedTrade, setSelectedTrade] = useState({});
  const [showTradeForm, setTradeForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const unmounted = useRef(false);

  useEffect(() => {
    setIsLoading(true);
    getTrades();
    setIsLoading(false);
    return () => {
      unmounted.current = true;
    };
  }, []);

  const getTrades = async () => {
    try {
      const response = await tradeSearch("customer", entity, 1, 0, []);
      if (response && !unmounted.current) {
        setTrades(response.data.results);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const showTrade = (index) => {
    setSelectedTrade(trades[index]);
    setTradeForm(true);
  };

  // const addTrade = () => {
  //   setSelectedTrade({});
  //   setTradeForm(true);
  // };

  const cancelTrade = () => {};

  const retryTrade = () => {};

  const confirmTrade = () => {};

  const showForm = () => {};

  return (
    <div className="card">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-10">
              <h4 className="card-title">Trade History</h4>
            </div>
            <div className="col-2">
              {/* <div className="account-btn" onClick={addTrade}>
                +
              </div> */}
            </div>
          </div>
          <div className="flexCol w-100">
            <div className="row">
              <div className="col-2">
                <span className="account-listing-header">Trade No.</span>
              </div>
              <div className="col-2">
                <span className="account-listing-header">Amount</span>
              </div>
              <div className="col-2">
                <span className="account-listing-header">FX Amount</span>
              </div>
              <div className="col-1">
                <span className="account-listing-header">Type</span>
              </div>
              <div className="col-2">
                <span className="account-listing-header">Value Date</span>
              </div>
              <div className="col-3">
                <span className="account-listing-header">Status</span>
              </div>
            </div>
            {trades.length > 0 ? (
              trades.map((t, i) => (
                <div key={i} className="account-listing">
                  <div
                    onClick={() => {
                      showTrade(i);
                    }}
                    className="row"
                  >
                    <div className="col-2">
                      <span className="listing-text">
                        {t.trade_reference_number}
                      </span>
                    </div>
                    <div className="col-2">
                      <span className="listing-text">{`${t.trade_currency.symbol} ${t.trade_amount}`}</span>
                    </div>
                    <div className="col-2">
                      <span className="listing-text">{`${t.fx_currency.symbol} ${t.fx_amount}`}</span>
                    </div>
                    <div className="col-1">
                      <span
                        className="listing-text"
                        style={{ textTransform: "uppercase" }}
                      >
                        {t.allowance_type}
                      </span>
                    </div>
                    <div className="col-2">
                      <span className="listing-text">{t.value_date}</span>
                    </div>
                    <div className="col-3" style={{ margin: "auto" }}>
                      <span
                        className={`${
                          t.locked ? "trade_locked" : "statusColumn"
                        } ${handleStatusStyle(t.status?.name)} noselect`}
                        style={{ fontSize: "12px" }}
                      >
                        {t.status?.name}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="row">
                <div className="col-12 mt-3" style={{ textAlign: "center" }}>
                  <span className="listing-text ">No trades found</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {showTradeForm ? (
        <TradeContainer
          showForm={showTradeForm}
          closeForm={() => setTradeForm(false)}
          currentTrade={selectedTrade}
          callbackCancel={cancelTrade}
          callbackRetry={retryTrade}
          callbackConfirm={confirmTrade}
          callbackShowForm={showForm}
        />
      ) : null}
    </div>
  );
}

TradeListing.propTypes = {
  entity: PropTypes.string,
};

export default TradeListing;
