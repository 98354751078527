import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import "./profile.css";
import AuthContext from "../../auth/auth-context";

const Profile = () => {
  const [isOpen, setisOpen] = useState(false);
  const authCtx = useContext(AuthContext);

  const logoutHandler = () => {
    authCtx.logout();
  };

  return (
    <div className="profile-dropdown" onClick={() => setisOpen(!isOpen)}>
      {/* <img src={profilePicture} /> */}
      <span className="profile-title">{authCtx.username}</span>
      {isOpen ? (
        <div className="dropdown-content">
          <a onClick={logoutHandler} className="dropdown-link">
            Sign out
          </a>
        </div>
      ) : null}
    </div>
  );
};

Profile.propTypes = {
  history: PropTypes.object,
  profile: PropTypes.object,
};

export default Profile;
