import React from "react";
import "./server-timeout.css";

function ServerTimeout() {
  return (
    <span className="text-center">
      Server timeout. Unable to complete the trade at this time
    </span>
  );
}

export default ServerTimeout;
