import React, { Component } from "react";
import PropTypes from "prop-types";
import TickerCard from "../../components/ticker/ticker-card/ticker-card";
import "./page.css";

class Page extends Component {
  render() {
    return (
      <div id="page" className="default-page">
        <TickerCard />
        <div className="widgets-container">{this.props.children}</div>
      </div>
    );
  }
}

Page.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

export default Page;
