import React, { Component } from "react";
import PropTypes from "prop-types";
import "./search.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typingTimeout: 0,
    };
  }

  handleChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  }

  typingTimout = () => {
    const self = this;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      typingTimeout: setTimeout(function () {
        self.props.resetPage();
        self.props.searchCallback();
      }, 1000),
    });
  };

  render() {
    return (
      <div className="search-container">
        <InputGroup className="mb-3">
          <Form.Control
            className="col-3 gray-back"
            id="searchFilter"
            name="searchFilter"
            as="select"
            style={{ fontSize: "13px" }}
            value={this.props.searchFilter}
            onChange={async (event) => {
              await this.props.handleChange(event);
              this.props.searchCallback();
            }}
          >
            {this.props.options?.map((o, i) => (
              <option key={i} value={o.value}>
                {o.name}
              </option>
            ))}
          </Form.Control>
          <input
            type="text"
            name="searchText"
            style={{ fontSize: "13px" }}
            className="form-control search-input"
            placeholder="Search By..."
            value={this.props.searchText}
            onChange={async (evt) => {
              await this.props.handleChange(evt);
              this.typingTimout();
            }}
          />
        </InputGroup>
      </div>
    );
  }
}

Search.propTypes = {
  searchCallback: PropTypes.func,
  handleChange: PropTypes.func,
  resetPage: PropTypes.func,
  searchFilter: PropTypes.string,
  searchText: PropTypes.string,
  options: PropTypes.array,
};

export default Search;
