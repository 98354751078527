import React, { useEffect, useState } from "react";
import AccountListing from "../../../components/account-listing/account-listing";
import PersonalInformationForm from "../../../components/personal-information-form/personal-information-form";

import AddressListing from "../../../components/address-listing/address-listing";
import ContactListing from "../../../components/contact-listing/contact-listing";
import EmailListing from "../../../components/email-listing/email-listing";
import TradeListing from "../../../components/trade-listing/TradeListing";

import PropTypes from "prop-types";
//Component Imports
import Header from "../../../common/header/header";
import Loader from "../../../components/UI/Loader/Loader";
import { BiArrowBack } from "react-icons/bi";
import { clientSearch } from "../../../api/clients.api";

import "./edit-client.css";

const EditClient = (props) => {
  const [client, setClient] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    //Fetch The Client
    setIsLoading(true);
    await getClient(props.match.params.id);
    setIsLoading(false);
  }, []);

  const getClient = async (id) => {
    try {
      const response = await clientSearch("id", id, 1, 1, []);
      if (response) {
        setClient(response.data.results[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="row w-100">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="col-12 page-header-with-back">
              <span className="page-back">
                <BiArrowBack
                  className="back-icon"
                  onClick={() => props.history.goBack()}
                />
              </span>
              <Header title="Edit Client" />
            </div>
            <div className="col-7">
              <PersonalInformationForm id={client.id} client={client} />

              <AccountListing entity={client.entity} />

              <TradeListing entity={client.entity} />
              {/* <DocumentListing entity={client.entity} /> */}
            </div>
            <div className="col-5">
              <AddressListing entity={client.entity} />

              <ContactListing entity={client.entity} />

              <EmailListing entity={client.entity} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

EditClient.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default EditClient;
