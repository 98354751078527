import React from "react";
import PropTypes from "prop-types";

const FileUpload = ({ uploadDocuments, documents }) => {
  return (
    <div className="file-upload-section">
      <h5>Client Documents</h5>
      {documents.length > 0 ? (
        documents.map((f) => (
          <h5 className="form-label" key={f.name}>
            {f.name}
          </h5>
        ))
      ) : (
        <h5 className="form-label">No files yet</h5>
      )}

      <button
        className="btn btn-primary"
        onClick={(e) => {
          e.preventDefault();
          uploadDocuments();
        }}
      >
        Upload Documents
      </button>
    </div>
  );
};

export default FileUpload;

FileUpload.propTypes = {
  uploadDocuments: PropTypes.func,
  documents: PropTypes.array,
};
