import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

class SubNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subNav: false,
    };

    this.showSubnav = this.showSubnav.bind(this);
  }

  showSubnav() {
    this.setState({
      subNav: !this.state.subNav,
    });
  }

  render() {
    return (
      <li key={this.props.index} style={{ listStyle: "none" }}>
        <NavLink
          exact={this.props.item.subNav ? false : true}
          className={this.props.item.cName}
          activeClassName={
            this.props.item.path === "#"
              ? this.state.subNav
                ? "nav-link-active"
                : ""
              : "nav-link-active"
          }
          to={this.props.item.path}
          onClick={this.props.item.subNav && this.showSubnav}
        >
          <div className="text-wrapper">
            <span className="mb-1">{this.props.item.icon}</span>
            <span className="nav-link-title">{this.props.item.title}</span>
            <div className="arrow-icon">
              {this.props.item.subNav && this.state.subNav
                ? this.props.item.iconOpened
                : this.props.item.subNav
                ? this.props.item.iconClosed
                : null}
            </div>
          </div>
        </NavLink>
        <div className="subNav">
          {this.state.subNav && this.props.item.subNav
            ? this.props.item.subNav.map((nitem, index) => {
                return (
                  <NavLink
                    exact
                    className="subnav-link"
                    activeClassName="subnav-link-active"
                    to={nitem.path}
                    key={index}
                  >
                    <div className="text-wrapper">
                      {nitem.icon}
                      <span className="subnav-link-title">{nitem.title}</span>
                    </div>
                  </NavLink>
                );
              })
            : null}
        </div>
      </li>
    );
  }
}

SubNav.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
};

export default SubNav;
